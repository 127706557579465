import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api_url } from "../../Utils/api-urls";
import { toast } from "react-toastify";

export const getEventList = createAsyncThunk('event/getEventList', async ()=>{
    try{
        let response = await axios.get(api_url.event,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        const data = await response?.data?.data;

        return data;
    } catch(error){
    }
})

export const createEvent = createAsyncThunk('event/createEvent', async (formdata, {rejectWithValue})=>{
    
})

const eventSlice = createSlice({
    name: 'event',
    initialState: {
        items: [],
        form: {
            title: "",
            description: "",
            category: "",
            image: "",
            imageName: "",
            startTime: "",
            closeTime: "",
            resolutionTime: "",
            endTime: "",
            maxBetAmount: "",
            result_declared: false,
            status: '',
            payoutLimit: "",
            options: [
                {
                    title: "",
                    image: "",
                    initialOdds: '',
                    option1: "",
                    option2: "",
                    id: '', 
                    win: false,
                    currentOdds: '',
                    total_pool: ''
                },
                {
                    title: "",
                    image: "",
                    initialOdds: '',
                    option1: "",
                    option2: "",
                    id: '',
                    win: false,
                    currentOdds: '',
                    total_pool: ''
                }
            ],
            error: null, 
            submitStatus: 'idle'
        },
        status: 'edle',
        error: null
    },
    reducers: {
        updateForm: (state, action) =>{
            const {field, value} = action.payload;
            state.form[field] = value;
        },
        setForm: (state, action)=>{
            const data = action.payload;
            state.form = {
                ...state.form, 
                ...data,
                options: data?.options
            }
        },
        resetForm: (state)=>{
            state.form= {
                title: "",
                description: "",
                category: "",
                image: "",
                imageName: "",
                startTime: "",
                closeTime: "",
                resolutionTime: "",
                endTime: "",
                maxBetAmount: 0,
                status: '',
                payoutLimit: 0,
                init_odd_duration: "",
                result_declared: false,
                options: [
                    {
                        title: "",
                        image: "",
                        initialOdds: 0,
                        option1: "Yes",
                        option2: "No",
                        win: false,
                        currentOdds: '',
                        total_pool: ''
                    },
                    {
                        title: "",
                        image: "",
                        initialOdds: 0,
                        option1: "Yes",
                        option2: "No",
                        win: false,
                        currentOdds: '',
                        total_pool: ''
                    }
                ],
                error: null, 
                submitStatus: 'idle'
            };
        },
        addOption: (state)=>{
            state.form.options.push({
                title: "",
                image: "",
                initialOdds: 0,
                option1: "",
                option2: "",
                win: false,
            })
        },
        removeOption: (state, action)=>{
            if(state.form.options.length > 2){
                state.form.options.splice(action.payload.index, 1);
            }
        },
        updateOption: (state, action)=>{
            const {index, field, value} = action.payload;
            state.form.options[index][field] = value;
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getEventList.pending, (state)=>{
            state.status = "pending";
        })
        .addCase(getEventList.fulfilled, (state, action)=>{
            state.status = "success";
            state.items = action.payload;
        })
        .addCase(getEventList.rejected, (state, action)=>{
            state.status = "failed";
            state.error = action.error.message;
        })

        builder
        .addCase(createEvent.pending,(state)=>{
            state.form.submitStatus = "pending";
        })
        .addCase(createEvent.fulfilled,(state, action)=>{
            state.form.submitStatus = "success";
            state.form= {
                title: "",
                description: "",
                category: "",
                image: "",
                imageName: "",
                startTime: "",
                closeTime: "",
                resolutionTime: "",
                endTime: "",
                maxBetAmount: 0,
                status: '',
                payoutLimit: 0,
                result_declared: false,
                init_odd_duration: "",
                options: [
                    {
                        title: "",
                        image: "",
                        initialOdds: 0,
                        option1: "",
                        option2: "",
                        win: false,
                    },
                    {
                        title: "",
                        image: "",
                        initialOdds: 0,
                        option1: "",
                        option2: "",
                        win: false,
                    }
                ],
                error: null, 
                submitStatus: 'idle'
            };
        })
        .addCase(createEvent.rejected,(state, action)=>{
            state.form.submitStatus = "failed";
            state.form.error = action.payload || "Failed to create this event, try after sometime."
        })
    }
})

export const { setData, getData} = eventSlice;
export const { updateForm, resetForm, addOption, setForm, removeOption, updateOption} = eventSlice.actions;
export default eventSlice.reducer;