import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_url } from "../../Utils/api-urls";
import api from "../../Service/api";

export const getEventList = createAsyncThunk('event/getEventList', async ()=>{
    try{
        const response = await api.get(api_url.event);
        const data = await response?.data?.data;

        return {items: data, bet: response?.data?.total_bet_placed,  event: response?.data?.total_events};
    } catch(error){
    }
})

const eventSlice = createSlice({
    name: 'event',
    initialState: {
        items: [],
        form: {
            id: "",
            title: "",
            description: "",
            category: "",
            image: "",
            imageName: "",
            startTime: "",
            closeTime: "",
            resolutionTime: "",
            endTime: "",
            maxBetAmount: "",
            result_declared: false,
            status: '',
            payoutLimit: "",
            init_odd_duration: "",
            options: [
                {
                    title: "",
                    image: "",
                    initialOdds: '',
                    option1: "",
                    option2: "#000000",
                    id: '', 
                    win: false,
                    currentOdds: '',
                    total_pool: ''
                },
                {
                    title: "",
                    image: "",
                    initialOdds: '',
                    option1: "",
                    option2: "#000000",
                    id: '',
                    win: false,
                    currentOdds: '',
                    total_pool: ''
                }
            ],
            error: null, 
            submitStatus: 'idle'
        },
        total_bet: "",
        total_event: "",
        status: 'edle',
        error: null
    },
    reducers: {
        updateForm: (state, action) =>{
            const {field, value} = action.payload;
            state.form[field] = value;
        },
        setForm: (state, action)=>{
            const data = action.payload;
            state.form = {
                id: data?._id,
                title: data?.title,
                description: data?.description,
                category: data?.category,
                image: data?.image,
                startTime: data?.start_time,
                closeTime: data?.close_time,
                resolutionTime: data?.resolution_time,
                endTime: data?.end_time,
                maxBetAmount: data?.max_bet_amount,
                result_declared: data?.result_declared,
                status: data?.status,
                payoutLimit: data?.subCategory,
                init_odd_duration: data?.init_odd_duration_time,
                options: data?.options?.map((data)=>{
                    return {
                        title: data.title,
                        image: data?.image,
                        initialOdds: data?.initialOdds,
                        currentOdds: data?.currentOdds,
                        option1: data?.text, 
                        option2: data?.color,
                        id: data?._id,
                        win: data?.win,
                        total_pool: data?.total_pool
                    }
                })
            }
        },
        setEventInfo: (state, action) => {
            state.eventInfo = action.payload;
        },
        resetForm: (state)=>{
            state.form= {
            id: "",
                title: "",
                description: "",
                category: "",
                image: "",
                imageName: "",
                startTime: "",
                closeTime: "",
                resolutionTime: "",
                endTime: "",
                maxBetAmount: 0,
                status: '',
                payoutLimit: 0,
                init_odd_duration: "",
                result_declared: false,
                options: [
                    {
                        title: "",
                        image: "",
                        initialOdds: 0,
                        option1: "",
                        option2: "#000000",
                        win: false,
                        currentOdds: '',
                        total_pool: ''
                    },
                    {
                        title: "",
                        image: "",
                        initialOdds: 0,
                        option1: "",
                        option2: "#000000",
                        win: false,
                        currentOdds: '',
                        total_pool: ''
                    }
                ],
                error: null, 
                submitStatus: 'idle'
            };
        },
        addOption: (state)=>{
            state.form.options.push({
                title: "",
                image: "",
                initialOdds: 0,
                option1: "",
                option2: "#000000",
                win: false,
            })
        },
        removeOption: (state, action)=>{
            if(state.form.options.length > 2){
                state.form.options.splice(action.payload.index, 1);
            }
        },
        updateOption: (state, action)=>{
            const {index, field, value} = action.payload;
            state.form.options[index][field] = value;
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getEventList.pending, (state)=>{
            state.status = "pending";
        })
        .addCase(getEventList.fulfilled, (state, action)=>{
            state.status = "success";
            state.items = action.payload.items;
            state.total_bet = action.payload.bet;
            state.total_event = action.payload.event;
        })
        .addCase(getEventList.rejected, (state, action)=>{
            state.status = "failed";
            state.error = action.error.message;
        })
    }
})

export const { setData, getData} = eventSlice;
export const { updateForm, resetForm, setEventInfo, addOption, setForm, removeOption, updateOption} = eventSlice.actions;
export default eventSlice.reducer;