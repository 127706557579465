import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { api_url } from '../../Utils/api-urls'
import DataTable from 'react-data-table-component'
import ViewImg from '../../images/eye-view.png'
import EditImg from '../../images/pencil-edit.png'
import { Modal } from 'react-bootstrap'
import { CiEdit } from 'react-icons/ci'
import { FiPlus } from 'react-icons/fi'
import { CreateButton, Input1, Label, InputContainer as ImageInputContainer, ContainerButton, ImageContainer } from '../Event/style'
import { Input, InputContainer } from '../Auth/styled'
import { AddButton } from './style'
import { toast } from 'react-toastify'
import { MdDelete } from "react-icons/md";
import UploadImage from '../../images/uploadImg.png'
import PageLoader from '../../Hooks/pageLoader'
import { Oval } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from '../../Component/slices/cmsSlice'

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

export default function Category(){

    const dispatch = useDispatch();

    const categoryData = useSelector((state)=> state?.cms?.items);

    const [loader, setLoader] = useState(false);
    const [category, setCategory] = useState('');
    const [icon,setIcon] =useState([
        {
            image: ""
        },
        {
            image: ""
        }
    ]);

    const [categoryId, setCategoryId] = useState('');
    const [subcategory, setSubCategroy] = useState([          
        {
            option: ""
        }
    ]);

    const fileInputRef = useRef([]);

    const handleFileChange = (e, index) => {
        const formdata = new FormData();
        formdata.append("image", e?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res?.data?.data;

            const list = [...icon];
            list[index].image = value;

            setIcon(list);
        })
        .catch((err)=>{
        })
    };

    const handleButtonClick = (index) => {
        fileInputRef.current[index]?.click();
    };
    
    const handleSubmit = ()=> {
        const arr = [];
        subcategory.map((data)=>{
            arr.push(data.option);
        })

        if(category ){
            axios.post(api_url.category,{
                category : category,
                icon: icon[0].image,
                activeIcon: icon[1].image,   
                subCategory: arr
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{
                handleCloseCreate();
                dispatch(getCategories())
                toast.success(res?.data?.message ? res?.data?.message : "Category created successfully")           
                setIcon([
                    {
                        image: ""
                    },
                    {
                        image: ""
                    }
                ]);      
                setCategory("");
                setSubCategroy([
                    {
                        option: ''
                    }
                ]);
            })
            .catch((error)=>{
                toast.error(error?.response?.data?.error ? error?.response?.data?.error : "Some error occured");
            })
        }       
    }

    const handleEdit =()=>{
        setLoader(true);
        const arr = [];
        subcategory.map((data)=>{
            arr.push(data.option);
        })

        if(category){
            axios.put(api_url.specificCategory(categoryId),{
                category : category,
                subCategory: arr, 
                icon: icon[0].image,
                activeIcon: icon[1].image,   
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{
                handleCloseView();
                dispatch(getCategories())
                // getCategoryList();
                setLoader(false)
                toast.success(res?.data?.message ? res?.data?.message : "Category created successfully");
            })
            .catch((error)=>{
                toast.error(error?.response?.data?.error ? error?.response?.data?.error : "Some error occured");
                setLoader(false)
            })
        }       
    }

    const handleSubChange = (e, index)=>{
        const list = [...subcategory];
        list[index].option = e.target.value;
        setSubCategroy(list);
    }
    const handleAddSubcategory = ()=>{
        setSubCategroy([
            ...subcategory,    
            {
                option: ''
            }
        ])
    }
    const handleRemoveSubCategory = (index)=>{
        const list = [...subcategory]
        list.splice(index, 1);
        setSubCategroy(list);
    }
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const fillData=(data)=>{
        setCategory(data?.category);
        setCategoryId(data?._id)
        const array = data?.subCategory?.map((data)=>{
            return {
                option: data
            }
        })
        setIcon([
            {
                image: data?.icon
            },
            {
                image: data?.activeIcon
            }
        ]);
        
        setSubCategroy(array);
    }
    const handleShowView =(data)=>{
        setView(true);
        fillData(data);
    }
    const handleShowViewEdit =(data)=>{ 
        setView(true);
        setEdit(true); 
        fillData(data);
    }
    const handleCloseView =()=>{ 
        setView(false); 
        setEdit(false); 
    }

    const [create, setCreate] = useState(false);
    const handleShowCreate =()=>{ setCreate(true); }
    const handleCloseCreate =()=>{ setCreate(false); }

    const columns = [
        {
            name: "S.No",
            selector: (row, index)=><div>
                {index+1}
            </div>
        },
        {
            name: "Name", 
            selector: (row)=> <div>
                {row?.category}
            </div>
        },
        {
            name: "Icons",
            selector: (row)=> <div className='d-flex gap-2'>
                <ImageContainer title="icon">
                    <img alt="" src={row?.icon} height="30px" width="30px"/>
                </ImageContainer>

                <ImageContainer title="active icon">
                    <img alt="" src={row?.activeIcon} height="30px" width="30px"/>
                </ImageContainer>
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className='d-flex align-items-center gap-2'>
                <img alt="" title="view" src={ViewImg} onClick={()=>handleShowView(row)}/>
                <img alt="" title="edit" src={EditImg} onClick={()=>handleShowViewEdit(row)}/>
            </div>
        }
    ]

    const handleDeleteCategory=(id)=>{
        axios.delete(api_url.specificCategory(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{                    
            // getCategoryList()                                  
            toast.success(res?.data?.message ? res?.data?.message : "Category delete successfully")
        })
        .catch((err)=>{
        })
    }
      
    useEffect(()=>{
        dispatch(getCategories())
    },[])

    return(
        <>
            <div className='d-flex align-items-center justify-content-between '>
                <div className='header'>
                    <p className='title '>Category</p>       
                    <p className='sub-title'>Manage the categories for events</p>
                </div>
                {/* <button onClick={handleShowCreate} className='button-delete'> <FaPlus/> Add a category</button> */}
            </div>
                            
            { categoryData?.length > 0 
        ? <DataTable
                columns={columns}
                data={categoryData}
                customStyles={customStyles}
                pagination
            /> : <PageLoader/> }

            <Modal show={create}>
                <div className='d-flex mb-2 justify-content-between align-items-center'>
                    <div>
                        <p className='mb-0 fw-bold fs-5'>Create Category</p>
                    </div>
                    <p className='cross-button mb-0' onClick={()=> handleCloseCreate()}>x</p>
                </div>

                <div className='mb-2'>
                    <Label htmlFor='category'>Category</Label>
                    <InputContainer>
                        <Input
                            placeholder='enter category'
                            value={category}
                            onChange={(e)=>setCategory(e.target.value)}
                            name='category'
                            id='category'
                        />
                    </InputContainer>
                </div>
                {
                    icon.map((data, index)=>{
                        return<div className='mb-2'>
                            <Label>Image</Label>
                            <ImageInputContainer>
                                <input
                                    placeholder='Upload Image for Event'
                                    className='border-0 bg-transparent'
                                    name='image'
                                    id='image'
                                    disabled
                                    value={data.image}
                                />
                                <Input1
                                    className='d-none'
                                    type='file'
                                    name='image'
                                    id={`image-${index}`}
                                    ref={(el) => fileInputRef.current[index] = el} 
                                    onChange={(e)=>handleFileChange(e, index)}
                                />
                                <ContainerButton type='button' onClick={()=>handleButtonClick(index)} className="custom-button">Browse Image <img alt="" src={UploadImage}/> </ContainerButton>
                            </ImageInputContainer>
                        </div>
                    }) 
                }
                <div className='mb-1'>    
                    <div>                         
                        <Label htmlFor='subCategory'>Sub-category (optional)</Label>
                    </div>
                    {           
                        subcategory?.map((data, index)=>{
                            return <InputContainer className='mb-1' >
                                <Input
                                    placeholder='enter subcategory'
                                    name='subCategory'
                                    id='subCategory'
                                    value={data?.option}
                                    onChange={(e)=>handleSubChange(e, index)}
                                />  
                                {
                                    subcategory?.length > 1 && <MdDelete onClick={(e)=>handleRemoveSubCategory(index)}/>
                                }
                            </InputContainer>
                        })
                    }
                </div>
                <div className='d-flex'>
                    <AddButton onClick={handleAddSubcategory}><FiPlus/> add more sub-category</AddButton>
                </div>

                <CreateButton type='button' onClick={handleSubmit} className='mt-3'>
                    Create Category
                </CreateButton>
            </Modal>

            <Modal show={view}>
                <div className='d-flex mb-2 justify-content-between align-items-center'>
                    <div>
                        <p className='mb-0 fw-bold fs-5'>{edit ? <>Edit Category</> : <>View Category <CiEdit onClick={()=>setEdit(true)}/> </>}</p>
                    </div>
                    <p className='cross-button mb-0' onClick={()=> handleCloseView()}>x</p>
                </div>
                {
                    edit ? 
                    <>
                        <div className='mb-2'>
                            <Label htmlFor='category'>Category</Label>
                            <InputContainer>
                                <Input
                                    disabled
                                    placeholder='enter category'
                                    value={category}
                                    onChange={(e)=>setCategory(e.target.value)}
                                    name='category'
                                    id='category'
                                />
                            </InputContainer>
                        </div>
                        {
                            icon.map((data, index)=>{
                                return<div className='mb-2'>
                                    <Label htmlFor='image'>Image</Label>
                                    <InputContainer>
                                        <Input
                                            placeholder='Upload Image for Event'
                                            className='border-0 bg-transparent'
                                            name='image'
                                            id='image'
                                            disabled
                                            value={data.image}
                                        />
                                    </InputContainer>
                                </div>
                            }) 
                        }
                        <div className='mb-1'>    
                            <div>                         
                                <Label htmlFor='subCategory'>Sub-category (optional)</Label>
                            </div>
                            {           
                                subcategory?.map((data, index)=>{
                                    return <InputContainer className='mb-1' >
                                        <Input
                                            placeholder='enter subcategory'
                                            name='subCategory'
                                            id='subCategory'                   
                                            value={data?.option}
                                            onChange={(e)=>handleSubChange(e, index)}
                                        />  
                                        {
                                            subcategory?.length > 1 && <MdDelete onClick={(e)=>handleRemoveSubCategory(index)}/>
                                        }
                                    </InputContainer>
                                })
                            }
                        </div>
                        <div className='d-flex'>
                            <AddButton onClick={handleAddSubcategory}><FiPlus/> add more sub-category</AddButton>
                        </div>

                        <CreateButton disabled={loader} type='button' onClick={handleEdit} className='mt-3'>
                            {loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>}Edit Category
                        </CreateButton>
                    </> : 
                    <>  
                        <div className='mb-2'>
                            <Label htmlFor='category'>Category</Label>
                            <InputContainer className='px-2 py-1'>
                                {category}
                                {/* <Input
                                    placeholder='enter category'
                                    value={category}
                                    disabled={true}
                                    onChange={(e)=>setCategory(e.target.value)}
                                    name='category'
                                    id='category'
                                /> */}
                            </InputContainer>
                        </div>
                        { subcategory[0]?.option?.length > 0 && <div className='mb-1'>    
                            <div>                         
                                <Label htmlFor='subCategory'>Sub-category (optional)</Label>
                            </div>
                            {           
                                subcategory?.map((data, index)=>{
                                    return <InputContainer className='mb-1 px-2 py-1' >
                                        {/* <Input
                                            placeholder='enter subcategory'
                                            name='subCategory'
                                            id='subCategory'                   
                                            value={data?.option}
                                            disabled={true}
                                            onChange={(e)=>handleSubChange(e, index)}
                                        /> */}
                                        {data?.option}
                                    </InputContainer>
                                })
                            }
                        </div>}
                    </>
                }
            </Modal>
        </>
    );
}   