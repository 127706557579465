import React from 'react'
import { Chart, LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from "react-chartjs-2";
import { FaCircle } from "react-icons/fa";

import User from '../../images/user.png'
import Bets from '../../images/bets.png'
import Event from '../../images/event.png'
import Earning from '../../images/earning.png'
import Withdrawal from '../../images/withdrawal.png'
import PendingUser from '../../images/pending_user.png'
import Up from '../../images/Yes.png'
import Down from '../../images/No.png'
import YesGraph from '../../images/YesGraph.png'
import NoGraph from '../../images/NoGraph.png'

import { P, Num, Card, Button, Div1, Div2, Title, Select } from './style'
import { useSelector } from 'react-redux';

Chart.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const cardData = [
    {
        image: <img src={User} width="30px"/>,
        title: "Total Users",
        num: '57567',
        status : 'up',
        percent: '7.2'
    },
    {
        image: <img src={Bets} width="30px"/>,
        title: "Active Bets",
        num: '56445',
        status : 'down',
        percent: '7.2'
    },
    {
        image: <img src={Event} width="30px"/>,
        title: "Active Events",
        num: '57567',
        status : 'up',
        percent: '7.2'
    },
    {
        image: <img src={Earning} width="30px"/>,
        title: "Total Earning",
        num: '57567',
        status : 'up',
        percent: '7.2'
    },
    {                     
        image: <img src={Withdrawal} width="30px"/>,
        title: "Total Withdrawl",
        num: '57567',
        status : 'up',
        percent: '7.2'
    },
    {
        image: <img src={PendingUser} width="30px"/>,
        title: "Non Betting Users",
        num: '57567'
    },
];

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
const lineData = {
    labels: labels,
    datasets: [
    {
        label: "Revenue",
        backgroundColor: "rgba(28, 121, 71, 1)",
        borderColor: "rgba(28, 121, 71, 1)",
        data: [46,54,23,76,34,78,32,87,12,54,67,90, ],
        tension: 0.6,
    },
    ],
};
const lineOption = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top', // Position of the legend ('top', 'left', 'bottom', 'right')
            labels: {
                font: {
                    size: 14, // Font size of legend labels
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                color: "red", // Font color of legend labels
            },
        },
        tooltip: {
            enabled: true, // Enable tooltips
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            titleFont: { size: 16 },
            bodyFont: { size: 14 },
            bodyColor: "#fff", // Tooltip text color
            padding: 10,
        },
    },
    scales: {
        x: {
            grid: {
                display: false, // Hide gridlines for x-axis
            },
            ticks: {
                color: '#666', // X-axis tick color
                font: {
                    size: 12,
                },
            },
        },
        y: {
            grid: {
                color: 'rgba(200, 200, 200, 0.5)', // Custom gridline color
                lineWidth: 1,
                drawBorder: false, // Remove the border around the chart
            },
            ticks: {
                color: '#666', // Y-axis tick color
                font: {
                    size: 12,
                },
                stepSize: 20, // Force step size on the y-axis
            },
        },
    },
    elements: {
        line: {
            borderWidth: 3, // Line thickness
        },
        point: {
            radius: 0, // Point size
            hoverRadius: 0, // Point size on hover
        },
    },
    animation: {
        duration: 1000, // Animation duration in ms
        easing: 'easeOutBounce', // Animation effect
    },
};

const barData = {
    labels: labels,
    datasets: [
    {
        label: "Bets",
        backgroundColor: "rgba(176, 74, 16, 1)",
        borderRadius: 10,
        borderColor: "rgba(176, 74, 16, 1)",
        data: [46,54,23,76,34,78,32,87,12,54,67,90],
        tension: 0.6,
    },
    {
        label: "Events",
        backgroundColor: "rgba(12, 93, 152, 1)",
        borderRadius: 10,
        borderColor: "rgba(12, 93, 152, 1)",
        data: [23,35,57,23,65,87,65,38,62,84,14,74],
        tension: 0.6,
    },
    {
        label: "Users",
        backgroundColor: "rgba(215, 66, 66, 1)",
        borderColor: "rgba(215, 66, 66, 1)",
        borderRadius: 10,
        data: [12,23,34,45,56,67,78,89,8,75,25,32],
        tension: 0.6,
    },
    ],
};
const barOption = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top', // Position of the legend ('top', 'left', 'bottom', 'right')
            labels: {
                font: {
                    size: 14, // Font size of legend labels
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                color: "red", // Font color of legend labels
            },
        },
        tooltip: {
            enabled: true, // Enable tooltips
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            titleFont: { size: 16 },
            bodyFont: { size: 14 },
            bodyColor: "#fff", // Tooltip text color
            padding: 10,
        },
    },
    scales: {
        x: {
            grid: {
                display: false, // Hide gridlines for x-axis
            },
            ticks: {
                color: '#666', // X-axis tick color
                font: {
                    size: 12,
                },
            },
        },
        y: {
            ticks: {
                display: false,
                color: '#666', // Y-axis tick color
                font: {
                    size: 12,
                },
                stepSize: 20, // Force step size on the y-axis
            },
        },
    },
    elements: {
        line: {
            borderWidth: 3, // Line thickness
        },
        point: {
            radius: 5, // Point size
            hoverRadius: 8, // Point size on hover
        },
    },
    animation: {
        duration: 1000, // Animation duration in ms
        easing: 'easeOutBounce', // Animation effect
    },
};


export default function Dashboard(){
    const token = useSelector((state)=>state.auth.authToken);

    console.log('this is app token', token)
    return(
        <div className='px-3'>
            <div className='d-flex mt-2 flex-wrap justify-content-between align-items-center'>
                {
                    cardData?.slice(0,3).map((data, index)=>{
                        return <Card key={index} trend={data?.status === "up" ? true : false} className=' mt-2 p-3 card'>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <p className='mb-2'>{data?.image}</p>
                                    <P className="mb-2">{data?.title}</P>
                                    <Num className="mb-1">{data?.num}</Num>
                                </div>
                                <div className='pb-2'>
                                    {
                                        data?.status === "up" ? <img src={YesGraph}/> : <img src={NoGraph}/>
                                    }
                                </div>
                            </div>
                            <div className="d-flex gap-1 up-down align-items-center">
                                {
                                    data?.status === "up" ? 
                                    <img src={Up}/> : <img src={Down}/>
                                }
                                <span className='percent'>{data?.percent}%</span>
                                <span className="secondary">from last month</span>
                            </div>
                        </Card>
                    })
                }
            </div>
            <div className='d-flex mt-2 flex-wrap justify-content-between align-items-center'>
                {
                    cardData?.slice(3,6).map((data, index)=>{
                        return <Card key={index} trend={data?.status === "up" ? true : false} className=' mt-2 p-3 card'>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <p className='mb-2'>{data?.image}</p>
                                    <P className="mb-2">{data?.title}</P>
                                    <Num className="mb-1">{data?.num}</Num>
                                </div>
                                {data?.status && <div className='pb-2'>
                                    {
                                        data?.status === "up" ? <img src={YesGraph}/> : <img src={NoGraph}/>
                                    }
                                </div>}
                            </div>
                            <div className="d-flex gap-1 up-down align-items-center">
                                {
                                    data?.status === "up" ? 
                                    <img src={Up}/> : <img src={Down}/>
                                }
                                <span className='percent'>{data?.percent}%</span>
                                <span className='secondary'>from last month</span>
                            </div>
                        </Card>
                    })
                }
            </div>
            <div className="row m-0 mt-4 mb-4">
                <div className='col-md-7 ps-0'>
                    <Div1 className='card'>
                        <div className='d-flex pt-3 pb-2 justify-content-between align-items-center'>
                            <Title className='mb-0 ps-2'>Revenue</Title>
                            <div className='d-flex gap-2 align-items-center'>
                                <Button>
                                    <span className='heading'>Revenue: </span>
                                    <span className='value'>$34,565</span>
                                </Button>
                                <Select>
                                    <option>last week</option>
                                    <option>last month</option>
                                    <option>last year</option>
                                </Select>
                            </div>
                        </div>
                        <Line data={lineData} options={lineOption} />
                    </Div1>
                </div>
                <Div2 className='col-md-5 bg-white d-flex flex-column justify-content-around'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Title className='mb-0 ps-2'>Operational Overview</Title>
                        <Select>
                            <option value="last week">last week</option>
                            <option value="last month">last month</option>
                            <option value="last year">last year</option>
                        </Select>
                    </div>
                    <div className='d-flex justify-content-around align-items-center'>
                        <div className='d-flex align-items-center gap-1 chart-label'> <FaCircle size={12} color="rgba(215, 66, 66, 1)"/> Bets </div>
                        <div className='d-flex align-items-center gap-1 chart-label'> <FaCircle size={12} color="rgba(12, 93, 152, 1)"/> Events </div>
                        <div className='d-flex align-items-center gap-1 chart-label'> <FaCircle size={12} color="rgba(176, 74, 16, 1)"/> Users </div>
                    </div>
                    <Bar data={barData} options={barOption} />
                </Div2>
            </div>
        </div>
    );
}