import styled from "styled-components"
export const ReqTable = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 15%;
        max-width: 15%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 25% !important;
        max-width: 25% !important;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 13%;
        max-width: 13%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 12%;
        max-width: 12%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 13%;
        max-width: 13%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 12%;
        max-width: 12%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
`