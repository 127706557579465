import React from 'react'
import Request from './request';
import AffiliateList from './affilateUser';
import { Card1 } from '../UserManagement/styled';

export default function Affiliate(){

    return(
        <div className='p-3'>
            <Card1 className='mb-3'>
                <nav>
                    <div className="nav nav-tab-contaner nav-tabs" id="nav-tab" role="tablist">
                        <button  
                            className="nav-link active" 
                            id="nav-request-tab" 
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-request" 
                            type="button" role="tab" 
                            aria-controls="nav-request" 
                            aria-selected="true"
                        >
                            Affiliate Requests
                        </button>   
                        <button 
                            className="nav-link"
                            id="nav-partner-tab"   
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-partner" 
                            type="button" role="tab" 
                            aria-controls="nav-partner" 
                            aria-selected="false"
                        >
                            Affiliate Partners
                        </button>
                    </div>
                </nav>   
                <div className='tab-content mt-2' id='nav-tabContent'>
                    <div 
                        className="tab-pane fade show active"  
                        id="nav-request" 
                        role="tabpanel" 
                        aria-labelledby="nav-request-tab"
                    > <Request/> </div>
                    <div 
                        className="tab-pane fade show" 
                        id="nav-partner" 
                        role="tabpanel" 
                        aria-labelledby="nav-partner-tab"
                    > <AffiliateList/> </div>
                </div>
            </Card1>
        </div>
    );
}