import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";

import eventReducer from './Component/slices/eventSlice';
import UserReducer from './Component/slices/userSlice';
import cmsReducer from './Component/slices/cmsSlice';
import poolSlice from "./Component/slices/poolSlice";
import authSlice from "./Component/slices/authSlice";

import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
    event: eventReducer,
    users: UserReducer,
    cms: cmsReducer,
    pools: poolSlice,
    auth: authSlice
})

const persistConfig  = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
)  

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware)=> getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                "persist/PERSIST",
                "persist/REHYDRATE"
            ]
        }
    })
})

const persistor = persistStore(store);

export {store, persistor};

export default store;