import React, { useEffect, useState } from 'react'
import { Card } from './style';
import { Bets, Card2, Nav, Profile } from '../UserManagement/styled';
import { CiSearch } from 'react-icons/ci';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import DataTable from 'react-data-table-component';
import { customStyles } from '../UserManagement/SingleUser';
import { FaPlus } from 'react-icons/fa6';
import { Modal } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getEventPool, getPoolHistory } from '../../Component/slices/poolSlice';
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';

const value = [
    {
        txnHash: "#thdvd13432",
        title: "who won election, 2024?",
        bet_amount: "₱1800",
        total_pool: "100",
        pool_amount: "₱200",
        earning: "+ ₱500"
    },
    {
        txnHash: "#thdvd13433",
        title: "2024, best series?",
        bet_amount: "₱1235",
        total_pool: "87",
        pool_amount: "₱800",
        earning: "+ ₱1000"
    },
    {
        txnHash: "#thdvd13433",
        title: "2024, best series?",
        bet_amount: "₱135",
        total_pool: "230",
        pool_amount: "₱800",
        earning: "- ₱540"
    },
    {
        txnHash: "#thdvd13434",
        title: "will, harris win ?",
        bet_amount: "₱1700",
        total_pool: "125",
        pool_amount: "₱320",
        earning: "+ ₱1000"
    },
    {
        txnHash: "#thdvd13435",
        title: "can Mr. Least cross 10M?",
        bet_amount: "₱14653",
        total_pool: "100",
        pool_amount: "₱5300",
        earning: "+ ₱340"
    }
];

const column = [

    // {
    //     name: "Transaction ID",
    //     selector: (row) => <div>
    //         {row?.txnHash}
    //     </div>
    // },
    // {
    //     name: "Event Title",
    //     selector: (row) => <div>
    //         {row?.title}
    //     </div>
    // },
    {
        name: "Event",
        selector: (row) => <div>
            {row?.event}
        </div>
    },
    {
        name: "Pool Type",
        selector: (row) => <div>
            {row?.pool_type}
        </div>
    },
    {
        name: "Amount",
        selector: (row) => <div>
            {row?.amount}
        </div>
    },
    {
        name: "Transaction Type",
        selector: (row) => <div>
            {row?.pool_txn_type}
        </div>
    }
];

export default function Pools (){
    
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [addBalance, setAddBalance] = useState(false);
    const handleShowAdd = (data) =>{
        setAddBalance(true);
        formik.setValues({
            pool_type: data?.pool_type,
        });
    }
    const handleCloseAdd = () =>{
        setAddBalance(false);
    }

    const formik = useFormik({
        initialValues: {
            pool_type: "",
            amount: ""
        },
        validationSchema: Yup.object().shape({
            amount: Yup.number().required("Amount is required")
        }),
        onSubmit: (values) => {
            console.log(values);
            axios.patch(api_url.addPool, values ,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{
                console.log(res);
                handleCloseAdd();
                dispatch(getEventPool());
                toast.success(res?.data?.message ? res?.data?.message : "Balance add successfully");
            })
            .catch((error)=>{
                console.log(error);
            })            
        }
    })

    useEffect(()=>{
        dispatch(getEventPool());
        dispatch(getPoolHistory())
    },[])

    const eventPoolData = useSelector((state) => state?.pools?.eventPoolData) || [];
    const eventPoolHistory = useSelector((state) => state?.pools?.eventPoolHist);
    

    console.log('eventPoolHistory eventPoolHistory', eventPoolHistory)
    return(
        <div className='container-fluid px-0 py-3'>
            <div className='row m-0 d-flex flex-wrap justify-content-center justify-content-md-between'>
                { 
                    eventPoolData && eventPoolData?.map((data, index)=>{
                        return <div key={index} className="col-md-6 mb-3 col-10">
                            <Card>
                                <div>
                                    <p className='mb-3 title text-decoration-underline'>{data?.pool_type}</p>
                                    <p className='mb-2  text-secondary'>Balance: <span className='text-dark'>₱{data?.pool_balance}</span></p>
                                    <p className='mb-2 text-secondary'>Balance Used: <span className='text-dark'>₱{data?.balance_used}</span></p>
                                </div>
                                <button onClick={()=>handleShowAdd(data)} className='add-button'> <FaPlus/> Add Balance</button>
                            </Card>
                        </div>
                    })
                }
            </div>

            <Card2 className='p-2'>
                <Nav className='mt-4'>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-bet-tab" data-bs-toggle="tab" data-bs-target="#nav-bet" type="button" role="tab" aria-controls="nav-bet" aria-selected="true">Event Pools</button>
                            <button className="nav-link" id="nav-transaction-tab" data-bs-toggle="tab" data-bs-target="#nav-transaction" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Jackpot Pools</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-bet" role="tabpanel" aria-labelledby="nav-bet-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3 px-2'>
                                <div>
                                    <p className='nav-head mb-0'>Event Pools</p>
                                    <p className='nav-subhead'>Manage and track all event pools history</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Bets>
                                <DataTable
                                    columns={column}
                                    data={eventPoolHistory?.history}
                                    customStyles={customStyles}
                                />
                            </Bets>
                        </div>
                        <div className="tab-pane fade" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>Jackpot Pools</p>
                                    <p className='nav-subhead'>Manage and track all jackpot pools history</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Profile>
                                <DataTable
                                    // columns={column2}
                                    // data={txnData}
                                    customStyles={customStyles}
                                />
                            </Profile>
                        </div>
                    </div>
                </Nav> 
            </Card2>

            <Modal show={addBalance}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='modal-head'> 
                        <p className="heading mb-0">Add Balance</p>
                        <p className='sub-heading'>Add balance for event pools</p>
                    </div>
                    <p onClick={handleCloseAdd} className='cross-button'>x</p>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='inputcontainer'>
                        <label htmlFor='amount'>Amount</label>
                        <input
                            onChange={formik.handleChange}
                            value={formik.values.amount}
                            placeholder='Enter pool amount'
                            name='amount'
                            id='amount'
                        />
                    </div>
                    <button type='submit' disabled={loader} className='d-flex justify-content-center gap-1 align-items-center button'>
                        {loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>} Update
                    </button>
                </form>
            </Modal>
        </div>
    );
}