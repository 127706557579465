// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-header{
    padding: 10px;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebar-header-collapse{
    padding: 10px;
    height: 10vh;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.menu-collapsed{
    padding: 20px 8px 0px 8px;
}
.menu-not-collapsed{
    padding: 33px 10px 0px 20px;
}

.this-is-menuitem > a {
    justify-content: center;
    padding-left: 28px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Sidebar/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".sidebar-header{\r\n    padding: 10px;\r\n    height: 10vh;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.sidebar-header-collapse{\r\n    padding: 10px;\r\n    height: 10vh;\r\n    margin-top: 10px;\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    align-items: center;\r\n}\r\n.menu-collapsed{\r\n    padding: 20px 8px 0px 8px;\r\n}\r\n.menu-not-collapsed{\r\n    padding: 33px 10px 0px 20px;\r\n}\r\n\r\n.this-is-menuitem > a {\r\n    justify-content: center;\r\n    padding-left: 28px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
