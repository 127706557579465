import React, { useEffect, useRef, useState } from 'react'
import { FaChevronLeft } from "react-icons/fa6";
import { Card2, Heading, Subhead, Label, AddButton, CreateButton, PreviewButton, OptionInputContainer, Input, Input1, Textarea, Select, InputContainer, Error,ContainerButton, OptionCard, OptionLabel, CreateEventButton } from './style.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api_url } from '../../Utils/api-urls.jsx';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { updateForm, addOption, removeOption, updateOption, resetForm } from '../../Component/slices/eventSlice.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import api from '../../Service/api.js';
import { image } from '../../Utils/images.jsx';

let style ={
    color: "#A9A9A9",
    fontWeight: '400',
    fontSize: '14px'
};

export default function CreateEvent(){
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [loader, setLoader] = useState(false);
    const {id} = params;
    const dispatch = useDispatch();
    const form = useSelector((state)=>state.event.form);

    const handleInputChange = (event)=>{
        const {name, value} = event.target;
        dispatch(updateForm({field: name, value}))
    }

    const handleInputChange1 = (name, date)=>{
        const value = new Date(date).toISOString();
        dispatch(updateForm({field: name, value}))
    }

    const [categoryData, setCategoryData] = useState([]);
    const [createError, setCreateError] = useState([]);

    const getCategoryList = () => {
        axios.get(api_url.category,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setCategoryData(res?.data?.data);
        })
        .catch((err)=>{
        })
    }
    const handleFindSubCategory =(category)=>{
        const arr = categoryData?.filter((data)=>{
            if(data?.category === category) return data?.subCategory
        })

        return arr;
    }

    useEffect(()=>{
        getCategoryList()
    },[])

    const handleCreateAfterChecks = async (formdata) =>{
        setLoader(true);
        try{
            const res = await api.post(api_url.event, formdata);
            toast.success(res?.data?.data?.message);
            navigate('/event-management');
            setLoader(false);
        } catch(error){
            setLoader(false);
            setCreateError(error?.response?.data);
        }
    }

    const handleCreate= async()=>{
        const formdata = {
            title: form.title,
            description: form.description,
            category: form.category,
            image: form.image,
            start_time: form.startTime,
            close_time: form.closeTime,
            resolution_time: form.resolutionTime,
            end_time: form.endTime,
            max_bet_amount: form.maxBetAmount && Number(form.maxBetAmount),
            subCategory: form.payoutLimit,
            init_odd_duration: form.init_odd_duration,
            eventOptions: form.options.map((data)=>{
                return { 
                    title: data?.title,
                    image: data?.image,
                    initialOdds: data?.initialOdds && Number(data?.initialOdds),
                    text: data?.option1,
                    color: data?.option2
                }
            })
        }

        let sum = 0;
        form.options.map((data)=>{
            sum += Number(data?.initialOdds);
        });

        if(sum === 100) handleCreateAfterChecks(formdata);
        else toast.error('sum of odds are not 100');
    }

    const handleEditEvent=async()=>{
        const formdata = {
            title: form.title,
            description: form.description,
            category: form.category,
            image: form.image,
            start_time: form.startTime,
            close_time: form.closeTime,
            resolution_time: form.resolutionTime,
            end_time: form.endTime,
            max_bet_amount: Number(form.maxBetAmount),
            subCategory: form.payoutLimit
        }

        try{
            const res = await api.patch(api_url.getSingleEvent(id), formdata);
            dispatch(resetForm())
            navigate(-1)

            toast.success(res?.data?.message ? res?.data?.message : "Event Update Successfully")
        } catch(error){
            console.log(error);
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(location.pathname.includes("edit-event")){
            handleEditEvent();
        } else {
            handleCreate()
        }
    }

    const fileInputRef1 = useRef([]);

    const handleFileChange1 = (e, index) => {
        const formdata = new FormData();
        formdata.append("image", e?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res?.data?.data;
            dispatch(updateOption({ index: index, field: "image", value }));
        })
        .catch((err)=>{
        })
    };
    const handleButtonClick1 = (index) => {
        fileInputRef1.current[index]?.click();
    };

    const fileInputRef = useRef(null);
    const handleFileChange = (event) => {

        const formdata = new FormData();
        formdata.append("image", event?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res.data.data
            dispatch(updateForm({field: "image", value}))
        })
        .catch((err)=>{
        })
    };
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleAdd = ()=>{
        dispatch(addOption());
    }

    const handleRemove = (e, index)=>{
        e.preventDefault();
        dispatch(removeOption(index));
    }
    const handleOptionChange = (e, index)=>{
        const {name, value} = e.target;
        dispatch(updateOption({ index, field: name, value }));
    }
    
    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex gap-3'>
                        <p className='mb-0 mt-1' onClick={()=> {navigate(-1); dispatch(resetForm());}}>
                            <FaChevronLeft color="#D22B2B" size={17} />
                        </p>
                        {
                            location.pathname.includes("edit-template") ? <div>
                                <Heading className='mb-1'>Edit Template</Heading>
                                <Subhead>Edit template details and create new event</Subhead>
                            </div> : location.pathname.includes("edit-event") ? <div>
                                <Heading className='mb-1'>Edit Event</Heading>
                                <Subhead>Edit event details and update the event</Subhead>
                            </div> : <div>
                                <Heading className='mb-1'>Create New Event</Heading>
                                <Subhead>Fill in event details and create new event</Subhead>
                            </div>
                        }
                    </div>
                    {
                        location.pathname?.includes('create-event') && 
                        <CreateEventButton onClick={()=>navigate('/event-template')}> Choose from templates </CreateEventButton>
                    }
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='mt-3'>
                        <Label>Event Title</Label>
                        <Input
                            name="title"
                            id="title"
                            value={form?.title}
                            onChange={handleInputChange}
                            placeholder='Enter event title'
                        />
                        {createError?.title && <Error>{createError?.title}</Error>}
                    </div>
                    <div className='mt-3'>
                        <Label>Description</Label>
                        <Textarea
                            name="description"
                            id="description"
                            placeholder='Enter event description'
                            value={form?.description}
                            onChange={handleInputChange}
                        />
                        {createError?.description && <Error>{createError?.description}</Error>}

                    </div>
                    <div className='row m-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label>Category</Label>
                            <Select 
                                onChange={handleInputChange} 
                                value={form.category}
                                name='category'
                                id='category'
                            >
                                <option>Select Category</option>
                                {
                                    categoryData?.map((data)=>{
                                        return <option value={data?.category}>{data?.category}</option>
                                    })
                                }
                            </Select>
                            {createError?.category && <Error>{createError?.category}</Error>}
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label htmlFor='payoutLimit'>Sub-Category</Label>
                            <Select 
                                onChange={handleInputChange} 
                                value={form.payoutLimit}
                                name='payoutLimit'
                                id='payoutLimit'
                            >
                                <option>Select Sub-category</option>
                                {
                                    handleFindSubCategory(form?.category)[0]?.subCategory?.map((data)=>{
                                        return <option value={data}>{data}</option>
                                    })
                                }
                            </Select>
                            {createError?.category && <Error>{createError?.category}</Error>}
                        </div>
                    </div>
                    <div className='row m-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label htmlFor='maxBetAmount'>Maximum Bet Amount</Label>
                            <Input
                                placeholder='Enter maximum bet amount'
                                value={form.maxBetAmount}
                                onChange={(e)=>{
                                    if(Number(e.target.value) >= 0) handleInputChange(e)
                                }}
                                name='maxBetAmount'
                                id='maxBetAmount'
                            />
                            {createError?.max_bet_amount && <Error>{createError?.max_bet_amount}</Error>}
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label>Image</Label>
                            <InputContainer className='p-1'>
                                <input              
                                    placeholder='Upload Image for Event'
                                    className='border-0 bg-transparent'
                                    disabled
                                    value={form.image}
                                />
                                <Input1
                                    className='d-none'
                                    type='file'
                                    name='image'
                                    id='image'
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                               <ContainerButton type='button' onClick={handleButtonClick} className="custom-button">Browse Image <img alt="" src={image.event.UploadImage}/> </ContainerButton>
                            </InputContainer>
                            {createError?.image && <Error>{createError?.image}</Error>}
                        </div>
                    </div>
                    <div className='row m-0 mt-3'>
                        <div className='col-md-6 ps-0'>
                            <Label>Event Start Time</Label>
                            <div className="custom-date-picker">
                                <InputContainer className='p-1'>
                                    <DatePicker
                                        selected={form.startTime ? new Date(form.startTime) : ''}
                                        onChange={(date) => handleInputChange1('startTime', date)}
                                        showTimeSelect
                                        timeIntervals={5}
                                        minDate={"25-11-2024 12:10:00"}
                                        dateFormat="MM/dd/yyyy hh:mm"
                                        placeholderText="Choose Time (MM/dd/yyyy hh:mm)"
                                    />
                                    <ContainerButton type='button' className='p-1'><img alt="" src={image.event.StartTime}/></ContainerButton>           
                                </InputContainer>
                            </div>

                            {createError?.start_time && <Error>{createError?.start_time}</Error>}
                        </div>
                        <div className='col-md-6 pe-0'>
                            <Label>Betting Close Time</Label>
                            <div className="custom-date-picker">
                                <InputContainer className='p-1'>
                                    <DatePicker
                                        selected={form.closeTime ? new Date(form.closeTime) : ''}
                                        onChange={(date) => handleInputChange1('closeTime', date)}
                                        showTimeSelect
                                        timeIntervals={5}
                                        dateFormat="MM/dd/yyyy hh:mm"
                                        minDate={new Date(form.startTime)}
                                        placeholderText="Choose Time (MM/dd/yyyy hh:mm)"
                                    />
                                    <ContainerButton type='button' className='p-1'><img alt="" src={image.event.CloseTime}/></ContainerButton>           
                                </InputContainer>
                            </div>
                            {createError?.close_time && <Error>{createError?.close_time}</Error>}
                        </div>
                    </div>
                    <div className='row m-0 mb-4 mt-3'>
                        <div className='col-md-4 ps-0'>
                            <Label>Event End Time</Label>      
                            <div className="custom-date-picker">
                                <InputContainer className='p-1'>
                                    <DatePicker
                                        selected={form.endTime ? new Date(form.endTime) : ''}
                                        onChange={(date) => handleInputChange1('endTime', date)}
                                        showTimeSelect
                                        timeIntervals={5}
                                        minDate={new Date(form.closeTime)}
                                        dateFormat="MM/dd/yyyy hh:mm"
                                        placeholderText="Choose Time (MM/dd/yyyy hh:mm)"
                                    />
                                    <ContainerButton type="button" className='p-1'><img alt="" src={image.event.EndTime}/></ContainerButton>           
                                </InputContainer>
                            </div>
                            {createError?.end_time && <Error>{createError?.end_time}</Error>}
                        </div>
                        <div className='col-md-4 pe-0'>
                            <Label>Event Resolution Time</Label>
                            <div className="custom-date-picker">
                                <InputContainer className='p-1'>
                                    <DatePicker
                                        selected={form.resolutionTime ? new Date(form.resolutionTime) : ""}
                                        onChange={(date) => handleInputChange1('resolutionTime', date)}
                                        showTimeSelect
                                        minDate={new Date(form.endTime)}
                                        // minTime={new Date(form.endTime).toLocaleTimeString()}
                                        timeIntervals={5}
                                        dateFormat="MM/dd/yyyy hh:mm"
                                        placeholderText="Choose Time (MM/dd/yyyy hh:mm)"
                                    />
                                    <ContainerButton type='button' className='p-1'><img alt="" src={image.event.ResolutionTime}/></ContainerButton>           
                                </InputContainer>
                            </div>
                            {createError?.resolution_time && <Error>{createError?.resolution_time}</Error>}
                        </div>
                        <div className='col-md-4 pe-0'>
                            <Label htmlFor='init_odd_duration'>Odd Duration</Label>
                            <div className="custom-date-picker">
                                <InputContainer className='p-1'>
                                    <DatePicker
                                        onChange={(date) => handleInputChange1('init_odd_duration', date)}
                                        selected={form.init_odd_duration ? new Date(form.init_odd_duration) : ""}
                                        showTimeSelect
                                        minDate={new Date(form.startTime)}
                                        maxDate={new Date(form.endTime)}
                                        dateFormat="MM/dd/yyyy hh:mm"
                                        timeIntervals={5}
                                        placeholderText="Choose Time (MM/dd/yyyy hh:mm)"
                                    />
                                    <ContainerButton type='button' className='p-1'><img alt="" src={image.event.ResolutionTime}/></ContainerButton>           
                                </InputContainer>
                            </div>
                            {createError?.init_odd_duration && <Error>{createError?.init_odd_duration}</Error>}
                        </div>
                    </div>
                    {
                        form?.options?.map((data, index)=>{         
                            return  <OptionCard key={index}>
                                <div className='row m-0 align-items-end' >
                                    <div className='col-md-6 ps-0'>
                                        <OptionLabel>Option Name</OptionLabel>
                                        <Input
                                            name="title"
                                            id='title'
                                            value={data?.title}
                                            onChange={(e)=>handleOptionChange(e, index)}
                                            placeholder='Enter betting option name'
                                        />
                                        {createError?.eventOptions && createError?.eventOptions[index]?.title && <Error>{createError?.eventOptions[index]?.title}</Error>}
                                    </div>
                                    <div className='col-md-6 pe-0'>
                                        <span className=' d-flex justify-content-between align-items-center'>
                                            <OptionLabel htmlFor='image'>Option Image (optional)</OptionLabel>
                                            {
                                                form?.options?.length > 2 &&  <button className='bg-transparent border-0' onClick={(e)=>handleRemove(e, index)}><MdDelete/></button>
                                            }
                                        </span>
                                        <OptionInputContainer>
                                            <input
                                                placeholder='OptionImage.JPG'
                                                className='border-0 bg-transparent'
                                                style={{...style, width: '100%'}}
                                                disabled
                                                name='image'
                                                id='image'
                                                value={data?.image}
                                            />
                                            <Input1
                                                className='d-none'
                                                type='file'
                                                name='image'
                                                // id='image'
                                                id={`image-${index}`}
                                                ref={(el) => fileInputRef1.current[index] = el} 
                                                onChange={(e)=>handleFileChange1(e, index)}
                                            />
                                            <ContainerButton type='button' onClick={()=>handleButtonClick1(index)} className="custom-button">Browse <img alt="" src={image.event.UploadImage}/> </ContainerButton>
                                        </OptionInputContainer>
                                        {createError?.eventOptions && createError?.eventOptions[index]?.image && <Error>{createError?.eventOptions[index]?.image}</Error>}
                                    </div>  
                                </div>
                                <div className='row m-0 align-items-end mt-3'>
                                    
                                    <div className='col-md-4 ps-0'>
                                        <OptionLabel htmlFor='initialOdds'>Initial Odd (%)</OptionLabel>
                                        <Input
                                            name='initialOdds'
                                            id='initialOdds'
                                            type='number'
                                            value={data?.initialOdds}
                                            onChange={(e)=>{handleOptionChange(e, index)}}
                                            placeholder='Enter initial odd for this option'              
                                        />
                                        {createError?.eventOptions && createError?.eventOptions[index]?.initialOdds && <Error>{createError?.eventOptions[index]?.initialOdds}</Error>}
                                    </div>
                                    <div className='col-md-4'>
                                        <OptionLabel htmlFor='option1'>Text</OptionLabel>
                                        <Input
                                            name="option1"
                                            id='option1'
                                            value={data?.option1}
                                            onChange={(e)=>handleOptionChange(e, index)}
                                            placeholder='Enter text'
                                        />
                                        {createError?.eventOptions && createError?.eventOptions[index]?.text && <Error>{createError?.eventOptions[index]?.text}</Error>}
                                    </div>
                                    <div className='col-md-4 d-flex align-items-center gap-2 pe-0 pb-2'>
                                        <OptionLabel htmlFor='option2'> Select Color</OptionLabel>
                                        <Input
                                            style={{
                                                width: '26px',
                                                padding: '5px',
                                                borderRadius: '50px'
                                            }}
                                            name='option2'
                                            id='option2'
                                            type='color'
                                            value={data?.option2}
                                            onChange={(e)=>{handleOptionChange(e, index)}}
                                            placeholder='Select color'
                                        />
                                        {createError?.eventOptions && createError?.eventOptions[index]?.color && <Error>{createError?.eventOptions[index]?.color}</Error>}
                                    </div>  
                                </div>
                            </OptionCard>
                        })
                    }

                    <AddButton type='button' onClick={handleAdd}>
                        <FaPlus color={ form?.options?.length === 5 ? '#78727285' : '#787272'}/> Add More Options                                 
                    </AddButton>

                    <div className='row m-0 mt-4 d-flex justify-content-around align-items-center'>
                        {!location.pathname.includes("edit-event") && <div className='col-md-6 col-10'>
                            <PreviewButton onClick={()=>navigate('/event-preview')} type='button' >Event Preview</PreviewButton>
                        </div>}
                        <div className='col-md-6 col-10'>
                            <CreateButton type='submit' disabled={loader} >{loader ? <Oval height={22} strokeWidth={8} width={22} secondaryColor='grey' color="white" /> : `${location.pathname.includes("edit-event") ? "Edit" : "Create"} Event`}</CreateButton>
                        </div>
                    </div>
                </form>
            </Card2>
        </div>
    );
}