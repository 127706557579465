import styled from "styled-components";

export const Card = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;

    .title{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 19.5px;
    }

    .total{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
    }

    .active-line{
        font-size: 13px;
        font-weight: 600;
        color: #A9A9A9;
    }
    .primary{
        color: #037847;
    }
    .danger{ 
        color: #D22B2B;
    }

    .nav-tab-contaner{
        border: none !important;
        background: #d3d3d34a;
        width: fit-content;
        border-radius: 50px;
    }
    
    .nav-link{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        transition: 0.5s;
    }
        
    .nav-link.active{
        border-radius: 50px;    
        transition: 0.5s;
        margin-bottom: 1px;
        font-weight: 600;
        background: #D22B2B;
        color: white;
    }

    .export-dropdown-menu{
        border: 1px solid #A9A9A98C !important;
        min-width: 90px !important;
    }

    .export-dropdown-menu > li{
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
            background: rgba(245, 245, 245, 1);
            cursor: pointer;
        }
    }

    .dropdown-button{
        border: 1px solid rgba(169, 169, 169, 0.55);
        border-radius: 8px;
        color: #666666;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 11px;
        background: white;
        display: flex;
        align-items: center;
        gap: 8px;
    }
`

export const WithdrawalDiv = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 15%;
        max-width: 15%; 
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
`

export const DesclineButton = styled.button`
    font-size: 10px;
    font-weight: 600;    
    color: white;
    padding: 6px 12px; 
    border: none;
    border-radius: 6px;
    background: ${props => props.background};
`

export const Pay = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 10%;
        max-width: 10%; 
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 30%;
        max-width: 30%;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
`
export const Option = styled.div`
    border: 1px solid rgba(169, 169, 169, 0.35);
    margin: 10px 0px;
    background: rgb(169 169 169 / 8%);
    padding: 8px;
    border-radius: 8px;
`