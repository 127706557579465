import React from 'react'
import Up from '../../images/Yes.png'
import Down from '../../images/No.png'
import YesGraph from '../../images/YesGraph.png'
import NoGraph from '../../images/NoGraph.png'
import { Button, Card, Div1, Num, P, Select, Title } from '../Home/style'
import { Chart, LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, ArcElement, plugins } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2'
import { FiDownloadCloud } from 'react-icons/fi'
import { LuDownload } from 'react-icons/lu'
import './style.css'
import DataTable from 'react-data-table-component'
import { ViewButton } from '../Event/style'
import PageLoader from '../../Hooks/pageLoader'

Chart.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, ArcElement);

const customStyles = {
    headRow: {
      style: {
        fontSize: '14px',
        fontWeight: '500',
        color: "rgba(51, 51, 51, 0.5)",
        // border: "1px solid red",
        border: "1px solid rgba(234, 236, 240, 1)",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: 'rgba(102, 102, 102, 1)',
        fontSize: '14px',
        fontWeight: "400",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingRight: '0px',
        paddingLeft: '10px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
        padding: '0px 0px 0px 10px',
      },
    }
}

export default function Report(){
    const data = [
        {
            name: "Daily Active Users",
            amount: "567567",
            percent: "7.2",
            status: "up"
        },
        {
            name: "Weekly User Balances",
            amount: "561567",
            percent: "5.2",
            status: "down"
        },
        {
            name: "Monthly Active Users",
            amount: "561267",
            percent: "5.2",
            status: "down"
        }
    ]

    const reportData = [
        {
            id: "#Mike3874",
            name: "Mike Singh",
            email: "mike@gmail.com",
            last_active: "12/08/2024",
            deposit: "₱2,500",
            churn: "1 Month",
        },
        {
            id: "#Mike3874",
            name: "Mike Singh",
            email: "mike@gmail.com",
            last_active: "12/08/2024",
            deposit: "₱2,500",
            churn: "1 Month",
        },
        {
            id: "#Mike3874",
            name: "Mike Singh",
            mobile: "+91 9439452234",
            last_active: "12/08/2024",
            deposit: "₱2,500",
            churn: "1 Month",
        },
        {
            id: "#Mike3874",
            name: "Mike Singh",
            email: "mike@gmail.com",
            last_active: "12/08/2024",
            deposit: "₱2,500",
            churn: "1 Month",
        },
        {
            id: "#Mike3874",
            name: "Mike Singh",
            mobile: "+91 9439452234",
            last_active: "12/08/2024",
            deposit: "₱2,500",
            churn: "1 Month",
        },
        {
            id: "#Mike3874",
            name: "Mike Singh",
            email: "mike@gmail.com",
            last_active: "12/08/2024",
            deposit: "₱2,500",
            churn: "1 Month",
        }
    ]
    const columns = [
        {
            name: "User ID",
            selector: (row)=> <div>
                {row?.id}
            </div>
        },
        {
            name: "User Name",
            selector: (row)=> <div>
                {row?.name}
            </div>
        },
        {
            name: "Email/Mobile",
            selector: (row)=> <div>
                {row?.email ? row?.email : row?.mobile}
            </div>
        },
        {
            name: "Last Active",
            selector: (row)=> <div>
                {row?.last_active}
            </div>
        },
        {
            name: "Total Deposit",
            selector: (row)=> <div>
                {row?.deposit}
            </div>
        },
        {
            name: "Churn Period",
            selector: (row)=> <div>
                {row?.churn}
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton>View Profile</ViewButton>
            </div>
        }
    ]

    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
         
    const lineData = {
        labels: labels,
        datasets: [
            {
                label: "User Average Time",
                backgroundColor: "#D16F98",
                borderColor: "#D16F98",
                data: [22,65,23,81,23,76,23,13,75,87, 43,76],
                tension: 0.6,
                fill: true,
            },
            {
                label: "Bets Average Number",
                backgroundColor: "#FDA370",
                borderColor: "#FDA370",
                data: [43,32,32,66,34,87,23,87,34,43, 34,43], 
                tension: 0.6,
                fill: true,
            }
        ],
    };
    const lineOption = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {  
            legend: {
                display: false,
                position: 'top',
                labels: {
                    font: {
                        size: 14,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                    color: "red",
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                titleFont: { size: 16 },
                bodyFont: { size: 14 },
                bodyColor: "#fff",
                padding: 10,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#666',
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.5)',                                                                                
                    lineWidth: 1,
                    drawBorder: false,
                },
                ticks: {
                    color: '#666',
                    font: {
                        size: 12,
                    },
                    stepSize: 20,
                },
            },
        },
        elements: {
            line: {
                borderWidth: 3,
            },
            point: {
                radius: 3,
                hoverRadius: 5
            },
        },
        animation: {
            duration: 1000,
            easing: 'easeOutBounce',
        },
    };

    const barData = {
        labels: labels,
        datasets: [
        {
            label: "Bets",
            backgroundColor: "#4C71E21F",
            borderRadius: 10,
            borderColor: "#4C71E21F",
            data: [46,54,23,76,34,78,32,87,12,54,67,90],
            tension: 0.6,
            hoverBackgroundColor: "#4169E1"
        }
        ],
    };        
    const barOption = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    font: {
                        size: 14,
                    },
                    color: "red",
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: "#4169E1",
                titleFont: { size: 16 },
                bodyFont: { size: 14 },
                bodyColor: "#ffffff",
                padding: 5,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide gridlines for x-axis
                },
                ticks: {
                    color: '#666666', // X-axis tick color
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                ticks: {
                    display: false,
                    color: '#666666', // Y-axis tick color
                    font: {
                        size: 12,
                    },
                    stepSize: 20, // Force step size on the y-axis
                },
            },
        },
        elements: {
            line: {
                borderWidth: 50,
            },
            point: {
                radius: 5,
                hoverRadius: 8, 
            },
        },
        animation: {
            duration: 1000, 
            easing: 'easeOutBounce',
        }
    };

    return(
        <p className='p-3'>
            <div className='d-flex mt-2 flex-wrap justify-content-between align-items-center'>
                {
                    data.map((data, index)=>{
                        return <Card key={index} trend={data?.status === "up" ? true : false} className='p-3 card'>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <P className="mb-2">{data?.name}</P>
                                    <Num className="mb-1">{data?.amount}</Num>
                                </div>
                                <div className='pb-2'>
                                    {
                                        data?.status === "up" ? <img src={YesGraph} width="110px"/> : <img src={NoGraph} width="110px"/>
                                    }
                                </div>
                            </div>
                            <div className="d-flex gap-1 up-down align-items-center">
                                {
                                    data?.status === "up" ? 
                                    <img src={Up}/> : <img src={Down}/>
                                }
                                <span className='percent'>{data?.percent}%</span>
                                <span className="secondary">from last month</span>
                            </div>
                        </Card>
                    })
                }
            </div>

            <div className='row m-0 mt-4'>
                <div className='col-md-12 p-0'>
                    <Div1 className='card'>
                        <div className='d-flex pt-3 pb-2 justify-content-between align-items-center'>
                            <Title className='mb-0 ps-2'>User Engagement</Title>
                            <div className='d-flex gap-2 align-items-center'>
                                <Button>
                                    <span className='heading'>Revenue: </span>
                                    <span className='value'>$34,565</span>
                                </Button>
                                <Select>
                                    <option>last week</option>
                                    <option>last month</option>
                                    <option>last year</option>
                                </Select>   
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li 
                                        // onClick={handleDownloadinCSV} 
                                        className="dropdown-item">CSV <LuDownload/> </li>
                                        <li 
                                        // onClick={handleDownloadinEXL} 
                                        className="dropdown-item">EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='pb-2' style={{ height: '400px'}}>
                            <Line data={lineData} options={lineOption} />
                        </div>
                    </Div1>
                </div>
            </div>

            <div className='row m-0 mt-4'>
                <div className='col-md-12 p-0'>
                    <Div1 className='card'>
                        <div className='d-flex pt-3 pb-2 justify-content-between align-items-center'>
                            <Title className='mb-0 ps-2'>Platform Performance</Title>
                            <div className='d-flex gap-2 align-items-center'>        
                                <Select>
                                    <option>sports</option>
                                    <option>election</option>
                                    <option>entertainment</option>
                                </Select>
                                <Select>
                                    <option>last week</option>
                                    <option>last month</option>
                                    <option>last year</option>
                                </Select>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li 
                                        // onClick={handleDownloadinCSV} 
                                        className="dropdown-item">CSV <LuDownload/> </li>
                                        <li 
                                        // onClick={handleDownloadinEXL} 
                                        className="dropdown-item">EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='pb-2' style={{ height: '300px'}}>
                            <Bar height={100} data={barData} options={barOption} />
                        </div>
                    </Div1>
                </div>
            </div>
                             
            <div className='row m-0 mt-4'>
                <div className='col-md-12'>            
                    <Div1 className='card'>
                        <div className='d-flex pt-3 pb-2 justify-content-between align-items-center'>
                            <div>
                                <Title className='mb-0'>User Churn Report</Title>
                                <small className='text-secondary'>Track users who stop using the platform</small>
                            </div>
                            <div className='d-flex gap-2 align-items-center'>
                                <Select>
                                    <option>last week</option>
                                    <option>last month</option>
                                    <option>last year</option>
                                </Select>   
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li 
                                        // onClick={handleDownloadinCSV} 
                                        className="dropdown-item">CSV <LuDownload/> </li>
                                        <li 
                                        // onClick={handleDownloadinEXL} 
                                        className="dropdown-item">EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                                        
                        { reportData?.length > 0 ? <DataTable
                            columns={columns}
                            data={reportData}
                            customStyles={customStyles}
                        /> : <PageLoader/> }
                    </Div1>
                </div>
            </div>
        </p>
    );
}