import React from 'react'
import DataTable from 'react-data-table-component';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import { DesclineButton, WithdrawalDiv } from './style';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

const data = [
    {
        id: "#UX67890",
        name: "maheep singh",
        email: "maheep@yopmail.com",
        mobile: "9324532121",
        amount: '₱1,451',
        withdrawal: "₱2,432",
    },
    {
        id: "#UX67890",
        name: "maheep singh",
        email: "maheep@yopmail.com",
        mobile: "9324532121",
        amount: '₱1,451',
        withdrawal: "₱2,432",
    },
    {
        id: "#UX67890",
        name: "maheep singh",
        email: "maheep@yopmail.com",
        mobile: "9324532121",
        amount: '₱1,451',
        withdrawal: "₱2,432",
    },
    {
        id: "#UX67890",
        name: "maheep singh",
        email: "maheep@yopmail.com",
        mobile: "9324532121",
        amount: '₱1,451',
        withdrawal: "₱2,432",
    }
]              
const columns = [
    {         
        name: "User Name",
        selector: (row)=> <div>
            {row?.name}
        </div>
    },
    {
        name: "Email",
        selector: (row)=> <div>
            {row?.email}
        </div>
    },
    {
        name: "Mobile Number",
        selector: (row)=> <div>
            {row?.mobile}
 6       </div>
    },
    {
        name: "Current Balance", 
        selector: (row)=> <div>
            {row?.amount}
        </div>
    },
    {
        name: "Withdrawal Req",
        selector: (row)=> <div>
            {row?.withdrawal}
        </div>
    },
    {
        name: "Action",
        selector: (row)=> <div className='d-flex gap-2'>
            <DesclineButton background="#1C7947" >Approve</DesclineButton>
            <DesclineButton background="#D22B2B" >Decline</DesclineButton>
        </div>
    }
] 

export default function Withdrawal(){
    return(
        <>
            <div className='d-flex mt-4 mb-2 justify-content-between align-items-center'>
                <div>
                    <p className='mb-0 fw-bold fs-5'>Manage User Withdrawal Requests</p>
                    <small className=' text-secondary'>Adjust a user's withdrawal requests</small>
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <div className="dropdown ">
                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                        <FiDownloadCloud size={16} color='#333333'/> Export
                        </button>
                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <li 
                            // onClick={handleDownloadinCSV} 
                            className="dropdown-item">CSV <LuDownload/> </li>
                            <li 
                            // onClick={handleDownloadinEXL} 
                            className="dropdown-item">EXCEL <LuDownload/></li>
                        </ul>
                    </div>
                </div>
            </div>
            <WithdrawalDiv>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                />
            </WithdrawalDiv>
        </>
    );
}