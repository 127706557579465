import React, { useState } from 'react';
import './index.css';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Logo from '../../images/new_logo.png';
import CloseToggle from '../../images/sidebarOpen.png';

import DashboardActive from '../../images/RedIcons/dashboard.png'
import EventActive from '../../images/RedIcons/event.png'
import UserActive from '../../images/RedIcons/user.png'
import ReportActive from '../../images/RedIcons/report.png'
import SettingActive from '../../images/RedIcons/settings.png'
import OddsActive from '../../images/RedIcons/odds.png'
import FinanceActive from '../../images/RedIcons/finance.png'
import CmsActive from '../../images/RedIcons/cms.png'
import CouponActive from '../../images/RedIcons/coupon.png'
import FeePayoutActive from '../../images/RedIcons/Fee&Payout.png'

import Finance from '../../images/BlackIcons/finance.png'
import Odds from '../../images/BlackIcons/odds.png'
import Dashboard from '../../images/BlackIcons/dashboard.png'
import Event from '../../images/BlackIcons/event.png'
import User from '../../images/BlackIcons/user.png'
import Report from '../../images/BlackIcons/reports.png'
import Setting from '../../images/BlackIcons/setting.png'
import Logout from '../../images/BlackIcons/logout.png'
import Cms from '../../images/BlackIcons/cms.png'
import Coupon from '../../images/BlackIcons/coupon.png'
import FeePayout from '../../images/BlackIcons/Fee&Payout.png'

import { IoMdWallet } from "react-icons/io";

import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const data =[
    {
        icon: <img alt="" src={Dashboard} width="21px"/>,
        activeIcon: <img alt="" src={DashboardActive} width="21px"/>,
        name: 'Dashboard',
        link: 'dashboard',
        active: true
    },
    {
        icon: <img alt="" src={Cms} width="17px"/>,
        activeIcon: <img alt="" src={CmsActive} width="17px"/>,
        name: "CMS",
        link: "content-management",
        active: true,
    },
    {
        icon: <img alt="" src={Event} width="23px"/>,
        activeIcon: <img alt="" src={EventActive} width="23px"/>,
        name: 'Events',
        link: 'event-management',
        active: false
    },
    {
        icon: <img alt="" src={User} width="23px"/>,
        activeIcon: <img alt="" src={UserActive} width="23px"/>,
        name: 'Users',
        link: 'user-management',
        active: false
    },
    {
        icon: <img alt="" src={Odds} width="17px"/>,
        activeIcon: <img alt="" src={OddsActive} width="17px"/>,
        name: 'Odds',
        link: 'odds-management',
        active: false
    },
    {
        icon: <IoMdWallet size={20} color='#333333'/>,
        activeIcon: <IoMdWallet size={20} color='rgb(210, 43, 43)'/>,
        name: 'Liquidity & Pool',
        link: 'pool-management',
        active: false
    },
    {
        icon: <img alt="" src={FeePayout} width="19px"/>,
        activeIcon: <img alt="" src={FeePayoutActive} width="19px"/>,
        name: "Fees & Payouts",
        link: "payout-management",
        active: false
    },
    {
        icon: <img alt="" src={Coupon} width="17px"/>,
        activeIcon: <img alt="" src={CouponActive} width="17px"/>,
        name: 'Coupons',
        link: 'coupon-management',
        active: false
    },
    {                       
        icon: <img alt="" src={Finance} width="21px"/>,
        activeIcon: <img alt="" src={FinanceActive} width="21px"/>,
        name: 'Finance',
        link: 'finance-management',
        active: false
    },
    {
        icon: <img alt="" src={Report} width="23px"/>,
        activeIcon: <img alt="" src={ReportActive} width="23px"/>,
        name: 'Report & Analysis',
        link: 'report-management',
        active: false
    },
    {
        icon: <img alt="" src={Setting} width="21px"/>,
        activeIcon: <img alt="" src={SettingActive} width="21px"/>,
        name: 'Settings',
        link: 'settings',
        active: false
    }
];

export default function SideNavbar(){
    const[isCollapse, setIsCollapse] = useState(false);
    const location = useLocation();
    
    const handleLogout =()=>{
        localStorage.clear();
        toast.success('Logout successfully')
    }

    return(
        <Sidebar
            breakPoint='lg'
            className='sidebar-main'
            backgroundColor='white'
            collapsed={isCollapse}
            toggled={false}
        >
            <div className={isCollapse ? "sidebar-header-collapse" : "sidebar-header"}>
                { !isCollapse && <img alt="" src={Logo} width="130px"/>}
                { isCollapse && <img alt="" src={Logo} width="60px"/>}
                <button onClick={()=>setIsCollapse(!isCollapse)} className='border-0 bg-transparent'>
                    <img alt="" src={CloseToggle} width="20px"/>
                </button>
            </div>
            <Menu
            menuItemStyles={{
                button: ({ level, active }) => {
                    if (level === 0 || level === 1)
                        return {
                            color: active ? 'rgba(210, 43, 43, 1)' : 'black',
                            backgroundColor: active && 'rgba(210, 43, 43, 0.15)',
                            fontWeight: active ? 600 : 500,
                            fontSize: "13px",
                            borderRadius: '12px',
                            ":hover": {
                                backgroundColor: active ? "rgba(210, 43, 43, 0.15)" : 'transparent'
                            }
                        };
                    },
                }}
            className={isCollapse ? 'menu-collapsed' : "menu-not-collapsed"}
            >
                {
                    data?.map((menuData, index)=>{
                        return <MenuItem
                            key={index}
                            className='this-is-menuitem'
                            component={<Link className={!isCollapse &&"ps-0"} to={menuData?.link}/>}
                            active={location.pathname.includes(menuData?.link)}
                            icon={location.pathname.includes(menuData?.link) ? menuData?.activeIcon : menuData?.icon}
                        >
                            {menuData?.name}
                        </MenuItem>
                    })
                }
                <MenuItem
                    className='this-is-menuitem'
                    icon={<img alt='' src={Logout} width="19px"/>}
                    onClick={()=>handleLogout()}
                    component={<Link to="login" className={!isCollapse && "ps-0"}/>}
                >
                    Logout
                </MenuItem>
            </Menu>
        </Sidebar>
    );
}