import React, { useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import { FaPlus } from 'react-icons/fa';
import { Active, CreateButton, Input, Label, Textarea, ViewButton } from '../Event/style';
import DataTable from 'react-data-table-component';
import { customStyles } from '../UserManagement/SingleUser';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeForm, getReqList, resetForm } from '../../Component/slices/affiliateSlice';
import { Select } from '../Home/style';
import moment from 'moment';
import api from '../../Service/api';
import { api_url } from '../../Utils/api-urls';
import { ReqTable } from './styled';
import { toast } from 'react-toastify';

export default function Request(){
    const dispatch = useDispatch();

    const req_list_data = useSelector((state)=> state?.affiliate?.items);
    const form = useSelector((state)=> state?.affiliate?.form);

    const [status, setStatus] = useState("ALL");
    const [searchInput, setSearchInput] = useState('');
    const [singleData, setSingleData] = useState();
    const [updateForm, setUpdateForm] = useState({
        status: "",
        description: ""
    });

    const [modalOpen, setModalOpen] = useState({
        update: false,
        create: false
    });
    const handleUpdate = (data) => {
        setModalOpen({
            update: true,
            create: false
        });
        setSingleData(data);
        setUpdateForm({
            ...updateForm,
            status: data?.status
        })
    }
    const handleCreate = () => {
        setModalOpen({
            create: true,
            update: false
        })
    }
    const handleClose = () =>{
        setModalOpen({
            update: false,
            create: false
        })
        setSingleData();
    }

    const handleUpdateStatus = async ()=>{
        try{
            let res = await api.patch(api_url.update_list(updateForm?.status, singleData?._id, updateForm?.description));
            handleClose();
            toast.success(res?.data?.data?.msg ? res?.data?.data?.msg : "Status update successfully");
            dispatch(getReqList(status));
        } catch(error){
            handleClose();
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some error occured");
            dispatch(getReqList(status));
        }
    }

    const columns = [
        {
            name: "Username",
            selector: (row)=> <div>
                {row?.userName}
            </div>
        },
        {
            name: "E-Mail",
            selector: (row)=> <div>
                {row?.email}
            </div>
        },
        {
            name: "Mobile Number",
            selector: (row)=> <div>
                {row?.mobile}
            </div>
        },
        {
            name: "Registered By",
            selector: (row)=> <div>
                {row?.isRegisteredUser ? "Self" : "Admin"}
            </div>
        },
        {
            name: "Created Date",
            selector: (row)=> <div>
                {moment(row?.createdAt).format("DD-MM-YYYY HH:MM")}
            </div>
        },
        {
            name: "Status",
            selector: (row)=> <div>
                <Active color={row?.status === "APPROVE" ?  "#1C7947" : row?.status === "PENDING" ?  "#E4B528" : "#D22B2B"}>
                    {row?.status}
                </Active>
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton onClick={()=>handleUpdate(row)}>View</ViewButton>
            </div>
        }
    ]

    const handleChange = (e) =>{
        const { name, value } = e.target;
        dispatch(changeForm({name, value}));
    }

    const handleCreateAffiliate = async (e) => {
        e.preventDefault();

        try{
            let res = await api.post(api_url.create_req, form);
            dispatch(resetForm());
            handleClose();
        } catch(error){
        }
    }
    useEffect(()=>{
        dispatch(getReqList(status));
    },[status])

    return(
        <>
            <div className='table-header mt-0 mb-3'>
                <div>
                    <p className='title mb-0'>Affiliate Requests</p>
                    <small className='subtitle'>Manage all requests to became affiliate partners</small>
                </div>
                <div className='filter-container'>
                    <div className='input-container'>
                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                        <input 
                            value={searchInput}
                            onChange={(e)=>{ setSearchInput(e.target.value) }}
                            placeholder='Search..'
                        />
                    </div>
                    <Select value={status} onChange={(e) => { setStatus(e.target.value)}}>
                        <option value="ALL">All</option>
                        <option value="PENDING">Pending</option>
                        <option value="REJECT">Rejected</option>
                        <option value="APPROVE">Approved</option>
                    </Select>
                    <div className="dropdown">
                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                        <FiDownloadCloud size={16} color='#333333'/> Export
                        </button>
                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <li className="dropdown-item">CSV <LuDownload/> </li>
                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                        </ul>
                    </div>
                    <button onClick={handleCreate} className='add-button' >
                        <FaPlus color='white' strokeWidth={2}/>
                        Add New Affiliate Partner
                    </button>
                </div>
            </div>
            <ReqTable>
                <DataTable
                    columns={columns}
                    data={req_list_data?.history} 
                    paginationPerPage={10}
                    pagination
                    customStyles={customStyles}
                />
            </ReqTable>
            <Modal show={modalOpen?.update} >
                <div className='d-flex justify-content-between'>
                    <div className='modal-head'>
                        <p className="heading mb-0">View Affiliate Request</p>
                        <p className='sub-heading'>manually update the status</p>
                    </div>
                    <p onClick={handleClose} className='cross-button'>x</p>
                </div>
                <div>
                    <div>
                        <Label>Name : </Label>
                        <Input
                            value={singleData?.userName}
                            disabled
                        />
                    </div>
                    <div>
                        <Label>Email : </Label>
                        <Input
                            value={singleData?.email}
                            disabled
                        />
                    </div>
                    <div>
                        <Label>Mobile Number : </Label>
                        <Input
                            value={singleData?.mobile}
                            disabled
                        />
                    </div>
                    <div>
                        <Label>Date Of Birth : </Label>
                        <Input
                            value={singleData?.dateOfBirth}
                            disabled
                        />
                    </div>
                    <div>
                        <Label>Province : </Label>
                        <Input
                            value={singleData?.province}
                            disabled
                        />
                    </div>
                    <div>
                        <Label>City : </Label>
                        <Input
                            value={singleData?.city}
                            disabled
                        />
                    </div>
                    <div>
                        <Label>Requested By : </Label>
                        <Input
                            value={singleData?.isRegisteredUser ? "Self" : "Admin"}
                            disabled
                        />
                    </div>
                    <div className='d-flex flex-column'>
                        <Label>Status : </Label>
                        <Select disabled={singleData?.status === "APPROVE"} value={updateForm?.status} 
                            onChange={(e)=> {
                                setUpdateForm({
                                    ...updateForm,
                                    status: e.target.value
                                })
                            }} 
                        >
                            <option value="PENDING">Pending</option>
                            <option value="APPROVE">Approve</option>                                        
                            <option value="REJECT">Reject   </option>                                        
                        </Select>
                    </div>
                    {
                        updateForm?.status === "REJECT" && <div>
                            <Label>Reason</Label>
                            <Textarea
                                placeholder='enter reason for not accepting the request'
                                value={updateForm?.description}
                                onChange={(e)=>{
                                    setUpdateForm({
                                        ...updateForm,
                                        description: e.target.value
                                    })
                                }}
                            />
                        </div>
                    }
                    <CreateButton className='mt-2' onClick={handleUpdateStatus}>Submit</CreateButton>
                </div>
            </Modal>

            <Modal show={modalOpen?.create} >
                <div className='d-flex justify-content-between'>
                    <div className='modal-head'>
                        <p className="heading mb-0">Affiliate Partner</p>
                        <p className='sub-heading'>manually create a new affiliate partner</p>
                    </div>
                    <p onClick={handleClose} className='cross-button'>x</p>
                </div>
                <form onSubmit={handleCreateAffiliate}>
                    <div>
                        <Label>Name</Label>
                        <Input
                            name='username'
                            placeholder='enter you name'
                            value={form.username}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Label>Email</Label>
                        <Input
                            name='email'
                            placeholder='enter email id'
                            value={form.email}
                            onChange={handleChange}
                            />
                    </div>
                    <div>
                        <Label>Password</Label>
                        <Input
                            name='password'
                            placeholder='enter password'
                            value={form.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Label>Mobile Number</Label>
                        <Input
                            name='mobile'
                            placeholder='enter mobile number'
                            value={form.mobile}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Label>Date of birth</Label>
                        <Input
                            name='dateOfBirth'
                            type='date'
                            placeholder='enter date of birth'
                            value={form.dateOfBirth}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Label>Province</Label>
                        <Input
                            name='province'
                            placeholder='enter province'
                            value={form.province}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Label>City</Label>
                        <Input
                            name='city'
                            placeholder='enter the city'
                            value={form.city}
                            onChange={handleChange}
                        />
                    </div>
                    <CreateButton className='mt-2'>Create Affiliate</CreateButton>
                </form>
            </Modal>
        </>
    );
}