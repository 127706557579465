import styled from "styled-components";

export const Card = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 15px;

    .add-button{
        background: #D22B2B;
        border: none;
        color: white;
        border-radius: 10px;
        padding: 5px 8px;
        font-size: 12px;
    }

    .add-button-1{
        background: #D22B2B;
        border: none;
        color: white;
        border-radius: 10px;
        // padding: 5px 8px;
        font-size: 12px;
    }

    .title{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 19.5px;
    }

    .active-line{
        font-size: 14px;
        font-weight: 600;
        color: #A9A9A9;
    }
`

export const Bets = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 30%;
        max-width: 30%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
`

export const Profile = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 30%;
        max-width: 30%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
`