import React from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import NotFound from '../../Pages/Error/NotFount';
import PublicRoute from './PublicRoute';

import Login from '../../Pages/Auth/Login';
import Forgot from '../../Pages/Auth/ForgotPass/index'

import UserManagement from '../../Pages/UserManagement';
import Report from '../../Pages/Report';
import Event from '../../Pages/Event';
import Setting from '../../Pages/setting';
import Dashboard from '../../Pages/Home';
import Odds from '../../Pages/Odds';
import Finance from '../../Pages/Finance';
 
//event
import CreateEvent from '../../Pages/Event/createEvent';
import ViewEvent from '../../Pages/Event/ViewEvent';
import ViewOptionUser from '../../Pages/Event/ViewOptionsUser';
import Preview from '../../Pages/Event/Preview'
  
//user
import SingleUser from '../../Pages/UserManagement/SingleUser';
import Template from '../../Pages/Event/template';
import SingleEventOds from '../../Pages/Odds/singleEventOds';
import ContentMgmt from '../../Pages/CMS';
import Coupen from '../../Pages/Coupen';
import CalcManagement from '../../Pages/CalcManagement';
import Pools from '../../Pages/Pools';
import ComboDetail from '../../Pages/UserManagement/comboPanalo';
import NormalBet from '../../Pages/UserManagement/normalBetDetail';
import Affiliate from '../../Pages/Affiliate/Index';
import Payborit from '../../Pages/Payments/payChannel';
    
export default function Routers(){
    const protectedRoute = [
        {
            path: 'dashboard',
            element: <Dashboard/>
        },
        {
            path: 'settings',
            element: <Setting/>
        },
        {
            path: 'event-management',
            element: <Event/>
        },
        {
            path: 'event-details/:id',
            element: <ViewEvent/>
        },
        {
            path: 'template-details/:id',
            element: <ViewEvent/>
        },
        {
            path: 'option-details/:id/:optionid',
            element: <ViewOptionUser/>
        },
        {
            path: 'report-management',
            element: <Report/>
        },
        {
            path: 'odds-management',
            element: <Odds/>
        },
        {
            path: "odds-details/:id",
            element: <SingleEventOds/>
        },
        {
            path: 'finance-management',
            element: <Finance/>
        },
        {
            path: 'create-event',
            element: <CreateEvent/>
        },
        {       
            path: 'edit-event/:id',
            element: <CreateEvent/>
        },
        {       
            path: 'edit-template/:id',
            element: <CreateEvent/>
        },
        {
            path: 'user-management',
            element: <UserManagement/>
        },
        {
            path: 'user-details/:id',
            element: <SingleUser/>
        },
        {
            path: "combo-panalo-details/:id",
            element: <ComboDetail/>
        },
        {
            path: "single-event-details/:id",
            element: <NormalBet/>
        },
        {
            path: 'event-preview',
            element: <Preview/>
        },
        {
            path: "event-template",
            element: <Template/>
        },
        {
            path: "content-management",
            element: <ContentMgmt/>
        },
        {
            path: "coupon-management",
            element: <Coupen/>
        },
        {
            path: "payout-management",
            element: <CalcManagement/>
        },
        {
            path: "pool-management",
            element: <Pools/>
        },
        {
            path: "affiliate-management",
            element: <Affiliate/>
        },
        {
            path: "payment-management/payborit",
            element: <Payborit/>
        }
    ];

    const token = localStorage.getItem('jwt');
    
    return(
        <Routes>
            <Route path='/' element={<Navigate to ={token ? "/dashboard" : "/login"}/>}/>
            <Route path='' element={<PublicRoute/>}>
                <Route path="login" element={<Login/>}/>
                <Route path="forgot-password" element={<Forgot/>}/>
            </Route>
            <Route path="" element={<Layout/>}>
                <Route path="" element={<ProtectedRoute/>}>
                    {
                        protectedRoute?.map((data, index)=>{
                            return <Route key={index} path={data?.path} element={data?.element}/>
                        })
                    }
                </Route>
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}