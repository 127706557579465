import React, { useEffect } from 'react'
import { Card2, Heading, Subhead, SelectStatus, EditButton, ViewOption, EditButton1, ImageContainer, Label, InputContainer, ContainerButton, Input1, ViewButton } from './style';
import { FaChevronLeft, FaCircle } from 'react-icons/fa6';
import { PiPencilLine } from "react-icons/pi";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { VscEye } from 'react-icons/vsc';
import { api_url } from '../../Utils/api-urls';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../../Component/slices/eventSlice';
import api from '../../Service/api';
import { image } from '../../Utils/images';

const customStyles = {
    headRow: {
      style: {
        fontSize: '14px',
        fontWeight: '500',
        color: "rgba(51, 51, 51, 0.5)",
        // border: "1px solid red",
        border: "1px solid rgba(234, 236, 240, 1)",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: 'rgba(102, 102, 102, 1)',
        fontSize: '14px',
        fontWeight: "400",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingRight: '0px',
        paddingLeft: '10px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
        padding: '0px 0px 0px 10px',
      },
    }
}

export default function ViewEvent(){
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const params = useParams();
    const {id} = params;

    const eventData = useSelector((state)=>state.event.form);

    const getEventDetails = async () => {
        try{
            const response = await api.get(api_url.getSingleEvent(id));
            const eventData = await response?.data?.data;
            dispatch(setForm(eventData));

        } catch(error){
            console.log(error)
        }
    }
    
    const getTemplateDetails = async () => {
        try{
            const response = await api.get(api_url.singleTemplate(id));
            const eventData = await response?.data?.data;
            dispatch(setForm(eventData));

        } catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        if(location.pathname.includes("template-details")){
            getTemplateDetails()
        } else {
            getEventDetails();
        }
    },[])

    const handleEdit=(id)=>{
        if(location.pathname.includes("template-details")) navigate(`/edit-template/${id}`);
        else navigate(`/edit-event/${id}`);
    }

    const handleChangeStatus = async (e)=>{
        const value = e.target.value;
        try {
            let resp = await api.patch(api_url.updateStatus(id), { status: value });

            if(resp){
                getEventDetails();
            }

        } catch(error){
            console.log(error);
        }
    }

    const columns = [
        {
            name: 'Options',
            selector: (row)=> <div className='d-flex gap-2 align-items-center'>
                <ImageContainer>
                    <img alt="" src={row?.image} height="30px" width="30px"/>
                </ImageContainer>
                <p className='mb-0'>{row?.title}</p>
            </div>
        },
        {
            name: "Odds",
            selector: (row)=> <div>
                {row?.initialOdds}%
            </div>  
        },
        {
            name: 'Cuurent Odd',
            selector: (row)=> <div>
                {row?.currentOdds ? row?.currentOdds : row?.initialOdds}%
            </div>
        },
        {
            name: "Total Amount",
            selector: (row)=> <div>
                ₱{row?.total_pool ? row?.total_pool : 0}
            </div>
        },
        {
            name: "Result",
            selector: (row)=> <div>
                {eventData?.result_declared ? row?.win ? <div className='text-success fw-bold'>Winner</div> : <div className='text-danger fw-bold'>Loser</div> : 'not declared'}
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className='d-flex align-items-center gap-1'>

                <ViewButton 
                    onClick={()=>{
                        navigate(`/option-details/${id}/${row?.id}`);
                    }}
                > 
                    <VscEye size={16} color='white'/> View Detail
                </ViewButton>
            </div>
        }
    ]

    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex gap-3'>
                        <p className='mb-0 mt-1' 
                            onClick={()=>{ 
                                navigate(-1);
                            }}
                        >
                            <FaChevronLeft color="#D22B2B" size={17} />
                        </p>
                        { location.pathname?.includes('template-management') ? <div>
                            <Heading className='mb-1'>Template Detail</Heading>
                            <Subhead>Detailed Overview of template</Subhead>
                        </div> : 
                        <div>
                            <Heading className='mb-1'>Event Detail</Heading>
                            <Subhead>Detailed Overview of Event</Subhead>
                        </div>}
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                        <EditButton onClick={()=>handleEdit(id)}> <PiPencilLine/> Edit</EditButton>
                    </div>
                </div>
                <div className='row m-0 mt-3'>
                    <div className='col-md-5'>
                        <img alt="" src={eventData?.image} width="100%" height="150px" />
                    </div>
                    <div className='col-md-7 d-flex flex-column justify-content-between'>
                        <p className='mb-0 event-title'>{eventData?.title} {eventData?.result_declared && <span className='text-success'>(Result Declared)</span>}</p>
                        <div className="row m-0 d-flex justify-content-between align-items-center">
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels'>Max Bet : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {eventData?.maxBetAmount} </EditButton1>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels'>Category : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {eventData?.category} </EditButton1>
                            </div>  
                        </div>
                        <div className="row m-0 d-flex justify-content-between align-items-center">
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels'>Sub-category : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <EditButton1 disabled> {eventData?.payoutLimit} </EditButton1>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <label className='view-labels' style={{width: "72px"}}>Status : </label>
                            </div>
                            <div className='col-md-3 d-flex gap-3 align-items-center'>
                                <SelectStatus value={eventData?.status} onChange={handleChangeStatus} >
                                    <option value="ACTIVE"><FaCircle size={8} color='#037847'/> Active</option>
                                    <option value="INCOMING"><FaCircle size={8} color='#E4B528'/> Upcoming</option>
                                    <option value="CLOSE"><FaCircle size={8} color='#780303'/> Close</option>
                                    <option value="FREEZE"><FaCircle size={8} color='#033278'/> Freeze</option>
                                </SelectStatus>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 mt-5'>
                    <div className='col-md-3'>
                        <Label>Event Start Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img alt="" src={image.event.StartTime} /></ContainerButton>
                            <Input1
                                disabled={true}
                                className='bg-transparent border-0'
                                placeholder={new Date(eventData?.startTime).toLocaleString()}
                                />
                        </InputContainer>
                    </div>
                    <div className='col-md-3'>
                        <Label>Event End Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img alt="" src={image.event.EndTime} /></ContainerButton>
                            <Input1
                                className='bg-transparent border-0'
                                disabled={true}
                                placeholder={new Date(eventData?.endTime).toLocaleString()}
                            />
                        </InputContainer>
                    </div>
                    <div className='col-md-3'>
                        <Label>Betting Close Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img alt="" src={image.event.CloseTime} /></ContainerButton>
                            <Input1
                                className='bg-transparent border-0'
                                disabled={true}
                                placeholder={new Date(eventData?.closeTime).toLocaleString()}
                            />
                        </InputContainer>
                    </div>
                    <div className='col-md-3'>
                        <Label>Event Resolution Time</Label>
                        <InputContainer className='gap-2'>
                            <ContainerButton className='p-1'> <img alt="" src={image.event.ResolutionTime} /></ContainerButton>
                            <Input1
                                className='bg-transparent border-0'
                                disabled={true}
                                placeholder={new Date(eventData?.resolutionTime).toLocaleString()}
                            />
                        </InputContainer>
                    </div>
                </div>
                <ViewOption className='mt-5'>
                    <DataTable
                        columns={columns}
                        data={eventData?.options}
                        customStyles={customStyles}
                    />
                </ViewOption>
                <div className='mt-4'>
                    <Heading className='mb-2'>Description</Heading>
                    <Subhead>{eventData?.description}</Subhead>
                </div>
            </Card2>
        </div>
    );
}