import React from 'react'
import { Card1 } from './style';
import Payout from './Payout';
import Tiered from './Tiered';
import FeeMgmt from './FeeMgmt';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        background:'#fcfcfd',
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

export default function CalcManagement(){

    return (
        <div className='p-3'>
            <Card1 className='px-0'>
                <nav className='px-2'>
                    <div className="nav nav-tab-contaner nav-tabs" id="nav-tab" role="tablist">
                        <button  
                            className="nav-link active" 
                            id="nav-withdrawal-tab" 
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-withdrawal" 
                            type="button" role="tab" 
                            aria-controls="nav-withdrawal" 
                            aria-selected="true"
                        >
                            Payout management
                        </button>   
                        <button 
                            className="nav-link"
                            id="nav-transaction-tab"   
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-transaction" 
                            type="button" role="tab" 
                            aria-controls="nav-transaction" 
                            aria-selected="false"
                        >
                            Tiered-shift Cap
                        </button>
                        <button 
                            className="nav-link"
                            id="nav-fee-tab"   
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-fee" 
                            type="button" role="tab" 
                            aria-controls="nav-fee" 
                            aria-selected="false"
                        >
                            Fee Management
                        </button>
                    </div>
                </nav>
                <div className='tab-content' id='nav-tabContent'>
                    <div className="tab-pane fade show active" id="nav-withdrawal" role="tabpanel" aria-labelledby="nav-withdrawal-tab" > 
                        <Payout customStyles={customStyles}/>
                    </div>
                    <div className="tab-pane fade show" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                        <Tiered customStyles={customStyles}/>
                    </div>
                    <div className="tab-pane fade show" id="nav-fee" role="tabpanel" aria-labelledby="nav-fee-tab">
                        <FeeMgmt customStyles={customStyles}/>
                    </div>
                </div>
            </Card1>
        </div>
    );
}