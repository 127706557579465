import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllAffilates } from '../../Component/slices/affiliateSlice';
import DataTable from 'react-data-table-component';
import moment from 'moment/moment';
import { customStyles } from '../UserManagement/SingleUser';
import { Active } from '../Event/style';
import { useNavigate } from 'react-router-dom';
import { image } from '../../Utils/images';
import { FaCircle } from 'react-icons/fa';

export default function AffiliateList (){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const affliate_list = useSelector((state)=> state?.affiliate?.affilateList);

    useEffect(()=>{
        dispatch(getAllAffilates(page))
    },[])

    // const columns = [
    //     {
    //         name: "Date",
    //         selector: (row) => <div>
    //             { moment(row?.createdAt)?.format("DD-MM-YYYY") }
    //         </div>
    //     },
    //     {
    //         name: "Username",
    //         selector: (row)=> <div>
    //             {row?.username}
    //         </div>
    //     },
    //     {
    //         name: "Total Bet",
    //         selector: (row)=> <div>
    //             ₱{row?.total_bet_amount}
    //         </div>
    //     },
    //     {
    //         name: "First Deposite",
    //         selector: (row) => <div>
    //             ₱{row?.first_deposit_amount}
    //         </div>
    //     },
    //     {
    //         name: "NGR",
    //         selector: (row)=> <div>
    //             ₱{row?.NGR}
    //         </div>
    //     },
    //     {
    //         name: "GGR",
    //         selector: (row)=> <div>
    //             ₱{row?.GCR}
    //         </div>
    //     },
    //     {
    //         name: "Payout",
    //         selector: (row)=> <div>
    //             ₱{row?.total_payout}
    //         </div>
    //     }
    // ];
    
    const columns = [
        {
            name: 'User Name',
            selector: (row)=> <div>
                {row?.username}
            </div>
        },
        {
            name: 'Email',
            selector: (row)=> <div>
                {row?.email}
            </div>
        },
        {
            name: "Mobile",
            selector: (row)=> <div>
                {row?.mobile ? row?.mobile : "N/A"}
            </div>
        },
        {
            name: "Total Bets",
            selector: (row)=> <div>
                {row?.total_pool ? row?.total_pool : '0'}
            </div>,
        },
        {
            name: "Last Active",
            selector: (row)=> <div>
                {new Date(row?.updatedAt).toDateString()}
            </div>,
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {                                
                    row?.isSuspended ? <Active color="#E4B528" background= "#E4B5281A"><FaCircle size={7} color='#E4B528'/>Inactive</Active> : 
                    <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Active</Active>
                }
            </div>
        },  
        {
            name: "Action",
            selector: (row)=> <div className=' d-flex gap-3'>
                <img src={image.event.ViewImg} title='view' onClick={()=>navigate(`/user-details/${row?._id}`,{state: {auth0UserId: row?.auth0UserId }})}/>
            </div>
        }
    ]


    return(
        <>        
            <div className='table-header my-3'>
                <div>
                    <p className='title mb-0'>Affiliate Partners</p>
                    <small className='subtitle'>track all the affiliate partners activities</small>
                </div>
            </div>
            <DataTable
                data={affliate_list?.result}
                columns={columns}
                pagination
                customStyles={customStyles}
           />
        </>
    );
}