import styled from "styled-components";

export const P = styled.p`
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
`
export const Num = styled.p`
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: #333333;
    letter-spacing: 0.016em;
`
export const Card = styled.div`
    border: none;
    border-radius: 16px;
    background: white;
    width: 32%;

    .up-down{
        font-size: 13.5px;
        font-weight: 500;
        line-height: 17.07px;
        // letter-spacing: 0.016em;
        text-align: left;
    }

    .percent{
        color: ${props => props.trend ? "#1C7947" : "#D22B2B"};
        font-weight: 600;
    }
    .secondary{
        color: #A9A9A9;
    }
`
export const Button = styled.button`
    border: none;
    background: rgba(228, 181, 40, 0.15);
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 7px;
    justify-content: center;

    .heading{
        font-size: 13px;
        font-weight: 600;
        color: #333333;
    }
    .value{
        font-size: 13px;
        font-weight: 400;
        color: #666666;
    }
`
export const Title = styled.p`
    font-size: 18px;
    color: #202224;
    font-weight: 600;
    line-height: 20px;
`
export const Div1 = styled.div`
    border: none;
    padding: 0px 10px;
    border-radius: 20px;
`
export const Div2 = styled.div`
    border: none;
    padding: 0px 10px;
    border-radius: 20px;

    .chart-label{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
`
export const Select = styled.select`
    border: none;
    padding: 5px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 400;
    background: rgba(245, 245, 245, 1);
    outline: none;
`