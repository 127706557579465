
const environment = {
    development: {
        API_URL: process.env.REACT_APP_DEV_URL,
    },
    staging: {
        API_URL: process.env.REACT_APP_STAGE_URL
    },
    production: {
        API_URL: process.env.REACT_APP_PROD_URL
    }
}

const endpoint = environment[process.env.REACT_APP_API_ENVIRONMENT];
const betting= process.env.REACT_APP_BETTING_ENGINE;
const payment= process.env.REACT_APP_PAYMENT;

export const api_url = {
    login: `${endpoint?.API_URL}/signin/`,
    forgot: `${endpoint?.API_URL}/forgot-password/`,
   
    //event management
    event: `${endpoint?.API_URL}/event/`,
    getSingleEvent: (id)=>`${endpoint?.API_URL}/event/${id}/`,
    updateStatus: (id) => `${endpoint?.API_URL}/event/status/${id}/`,
    imageChanger: `${endpoint?.API_URL}/upload/image/`,
    template: `${endpoint?.API_URL}/template/`,
    singleTemplate: (id)=> `${endpoint?.API_URL}/template/${id}/`,
    optionBetList: (id) => `${endpoint?.API_URL}/bet/get-option-users/${id}/`,
    resultDeclare: `${betting}/event_result_declare/`,
    
    // fee and payout
    getCalcRate: `${process.env.REACT_APP_BETTING_ENGINE}/get_payout_calc_odd/`,
    updateCalcRate: `${process.env.REACT_APP_BETTING_ENGINE}/update_payout_calc_odd/`,
    getTieredShiftCap: `${process.env.REACT_APP_BETTING_ENGINE}/get_tiered_shift_cap/`,
    updateTieredShiftCap: `${process.env.REACT_APP_BETTING_ENGINE}/update_tiered_shift_cap/`,
    getFee: `${endpoint.API_URL}/fee/`,
    
    // user management
    users: `${endpoint?.API_URL}/users/user/`,  
    singleUsers: (id)=> `${endpoint?.API_URL}/users/user/${id}/`,
    suspendUser: (id)=> `${endpoint?.API_URL}/users/suspend/${id}/`,    
    unSuspendUser: (id)=> `${endpoint?.API_URL}/users/unsuspend/${id}/`,
    getPreRegister: `${endpoint?.API_URL}/users/pre-register/`,
    
    // odds management
    setOds: `${endpoint?.API_URL}/odds/set-odd/`,         
    getOptionHistory: (id)=> `${endpoint?.API_URL}/odds/odd/option/${id}/`,
    
    // cms
    uploadBanner: `${endpoint.API_URL}/banner/save-banner/`,
    getBanner: `${endpoint.API_URL}/banner/get-banner/`,
    category: `${endpoint.API_URL}/category/`,
    specificCategory: (id)=> `${endpoint.API_URL}/category/${id}/`,

    //pools&liquidity
    getPool: `${endpoint.API_URL}/pool/get/`,
    addPool: `${endpoint.API_URL}/pool/add-amount/`,
    pool_history: `${payment}pool_history/`,

    // coupon management
    createCoupen: `${endpoint.API_URL}/coupon/create/`,
    getCoupen: `${endpoint.API_URL}/coupon/get-all/`,
    disableCoupen: (id) => `${endpoint.API_URL}/coupon/disable/${id}/`,

    // settings
    changePassword: `${endpoint.API_URL}/change-password/`,

}