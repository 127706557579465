import styled from "styled-components";

export const Card = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    padding: 12px 15px;

    .heading{
        font-size: 24px;
        font-weight: 600;
        color: #333333;
    }

    .nav-tabs{
        flex-direction: column;
        gap: 10px;
        border: none;
    }
    
    .nav-link{
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: start;
        gap: 8px;
    }
    .nav-link.active{
        border: none;
        background: #D22B2B1A;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: start;
        border-radius: 8px;
        gap: 8px;
        color: #D22B2B;
    }
    
    .add-button{
        background: rgba(210, 43, 43, 1);
        border-radius: 8px;
        padding: 7px 11px;
        border: none;
        color: white;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
    }
        
    .header{
        padding: 0px 15px 15px 15px;
    }
        
    .title{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 4px;
    }
    
    .sub-title{
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        margin-bottom: 4px;
    }

    .button-preview{
        border: 2px solid #D22B2B;
        color: #D22B2B;
        background: white;
        border-radius: 8px;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 600;
    }

    .button-delete{
        border: 2px solid #D22B2B;
        color: white;
        background: #D22B2B;
        border-radius: 8px;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 600;
    }
`
export const AddButton = styled.button`
    border: 1px solid #bbb3b3e0;
    border-radius: 10px;
    padding: 1px 10px;
    background: #f3f1f185;
    display: flex;
    align-items: center;
`

export const BannerShower = styled.div`
    border: 1px solid black;
    margin-top: 10px;
    border-radius: 5px;
    overflow: auto;
`