import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api_url } from '../../Utils/api-urls';
import { Modal } from 'react-bootstrap';
import { Card3, ImageContainer, YesButton } from '../Event/style';
import { FaChevronLeft } from 'react-icons/fa';
import { customStyles } from './SingleUser';
import { CircularProgressbar } from 'react-circular-progressbar';
import DataTable from 'react-data-table-component';
import { IoClose } from 'react-icons/io5';
import PageLoader from '../../Hooks/pageLoader';
import { BetSummary, ParletCard } from './styled';
import moment from 'moment';
import { image } from '../../Utils/images';

export default function ComboDetail(){
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const {id} = params;
    const authId = location.state;

    const [parleyBetInfo, setParleyBetInfo] = useState();
    const [loader, setLoader] = useState(false);

    const handleGetParleyDetails = () =>{
        setLoader(true);
        axios.get(api_url.singleUserParleyInfo(id, authId?.authId),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setLoader(false);
            setParleyBetInfo(res?.data?.data[0]);
        })
        .catch((err)=>{
            setLoader(false);
            console.log(err);
        })
    }

    useEffect(()=>{
        handleGetParleyDetails();
    },[])

    const columns = [
        {
            name: "Outcome",
            selector: (row)=><div className='d-flex gap-2 align-items-center'>
                <ImageContainer>
                    <img src={row.image} height="30px" width="30px"/>
                </ImageContainer>
                <p className='mb-0'>{row?.title}</p>
            </div>
        },
        {
            name: "Total Pools",
            selector: (row)=><div>
                <p className='mb-0'>{row?.total_pool}</p>
            </div>
        },
    
        {
            name: "Current Odd",
            selector: (row)=>{
                const val = Math.round(Number(row.currentOdds));
                return <div style={{ width: 33, height: 33 }}>
                    <CircularProgressbar 
                        backgroundPadding={0}
                        text={`${val}%`} 
                        value={val}
                        styles={{
                            text: {
                                fill: `${val > 50 ? "#1C7947" : "#D22B2B"}`,
                                fontSize: '35px',
                                fontWeight: "500"
                            },
                            trail: {
                                stroke: `${val > 50 ? "#1C79471F" : "#D22B2B1F"}`,
                            },
                            path: {
                                stroke: `${val > 50 ? "#1C7947" : "#D22B2B"}`,
                            }
                        }}
                    /> 
                </div>
            }
        },
        {
            name: "Action",
            selector: (row)=><div className='d-flex gap-1'>
                <YesButton style={{width: '100%'}} background={row?.color}>{row?.text}</YesButton>
            </div>  
        },
    ];

    if(loader){
        return <div className='mt-5'>
            <PageLoader/>
        </div>
    }
    return(
        <div className='p-3'>
            <div className='row m-0'>
                <div className='col-md-12 ps-0'>
                    <Card3>
                        <div className='d-flex align-items-center gap-2 mb-4'>
                            <p className='mb-0' onClick={()=>{ navigate(-1) }}>
                                <FaChevronLeft color="#D22B2B" size={17} />
                            </p>
                            <p className='mb-0' style={{fontSize: '17px', fontWeight: '600', color: '#333333'}}>Combo-Panalo Details</p>
                        </div>
                        {
                            parleyBetInfo &&  parleyBetInfo?.parley_events?.map((data)=>{
                                return <ParletCard>
                                    <div className='d-flex mb-4 align-items-center'>
                                        <img src={data?.event_detail?.image} alt="" width='84px' height="70px"/>
                                        <div className='d-flex ps-2 flex-column justify-content-between w-100'>
                                            <p className='event-title mb-1'>{data?.event_detail?.title}</p>
                                            <div className='d-flex justify-content-between align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <img src={image.event.Time}/>
                                                    <p className=' mb-0 event-time'>{new Date(data?.event_detail?.start_time).toLocaleString()}</p>
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <img src={image.event.Comment}/> <span className='event-time'>0</span><img src={image.event.Star}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={[data?.option_detail]}
                                        customStyles={customStyles}
                                    />
                                </ParletCard>
                            })
                        }
                        <BetSummary className='row m-0 mt-3'>
                            <p className='fw-bold'>Bet Summary</p>
                            <div className='col-md-4 d-flex flex-column gap-3'>
                                <div className='flex-detail'>
                                    <p className='label label-color'>Average Odds : </p>
                                    <p className='mb-0 odd-box'>{parleyBetInfo?.odds}%</p>
                                </div>
                                <div className='flex-detail'>
                                    <p className='label label-color'>Bet Amount : </p>
                                    <p className='label'>₱{parleyBetInfo?.amount}</p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex flex-column gap-3'>
                                <div className='flex-detail'>
                                    <p className='label label-color'>Bet on : </p>
                                    <p className='label'>{moment(new Date()).format("DD-MM-YYYY")}</p>
                                </div>
                                <div className='flex-detail'>
                                    <p className='label label-color'>Potential Payout : </p>
                                    <p className='label'>₱{parleyBetInfo?.potential_payout}</p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex flex-column gap-3'>
                                <div className='flex-detail'>
                                    <p className='label label-color'>Total Events : </p>
                                    <p className='label'>{parleyBetInfo?.parley_events?.length}</p>
                                </div>
                                <div className='flex-detail'>
                                    <p className='label label-color'>Status : </p>
                                    <p className='label' style={{color: parleyBetInfo?.status === 'active' ? "#4169E1" : parleyBetInfo?.status === "won" ? "#1C7947" : "#D22B2B"}}>{parleyBetInfo?.status}</p>
                                </div>
                            </div>
                        </BetSummary>
                    </Card3>
                </div>
            </div>
            <Modal className='edit-action-modal' show={false}>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='mb-1 modal-title'>Edit Action label</p>
                        <p className='modal-subtitle'>You edit & customize the labels for each option."</p>
                    </div>
                    <div className='modal-close'><IoClose size={20} color='#D22B2B' /></div>
                </div>
            </Modal>
        </div>
    );
}