import React, { useEffect, useRef, useState } from 'react'
import payment from '../../Service/payment';
import { api_url } from '../../Utils/api-urls';
import DataTable from 'react-data-table-component';
import PageLoader from '../../Hooks/pageLoader';
import { ContainerButton, CreateButton, ImageContainer, Input, Input1, InputContainer, Label, OptionInputContainer } from '../Event/style';
import { image } from '../../Utils/images';
import { Card, Option, Pay } from '../Finance/style';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

let style = {
    color: "#A9A9A9",
    fontWeight: '400',
    fontSize: '14px'
};

export default function Payborit() {
    const [payChannel, setPayChannel] = useState();
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);

    const handleUpdateChannel = async () =>{
        setLoader1(true);
        try{
            let res = await payment.post(api_url.updatePayChannel, modalInfo);
            setLoader1(false);
            handleGEtPayChannel();
            handleClose()
        } catch(error){
            setLoader1(false);
        }

    }
    const [modalInfo, setModalInfo] = useState();
    const [modalOpen, setModalOpen] = useState({
        view: false,
        edit: false
    })
    const handleShowView = (data) => {
        setModalInfo(data);
        setModalOpen({
            edit: false,
            view: true
        });
    }
    const handleEdit = (data) => {
        setModalInfo(data);
        setModalOpen({
            view: false,
            edit: true
        });
    }
    const handleClose = () => {
        setModalOpen({
            view: false,
            edit: false
        });
    }

    const handleChange = (e, itemIndex = null) => {
        const { name, value } = e.target;
      
        setModalInfo((prevModalInfo) => {
          if (itemIndex !== null) {
            return {
              ...prevModalInfo,
              sub_categories: prevModalInfo.sub_categories.map((item, index) =>
                index === itemIndex ? { ...item, [name]: value } : item
              ),
            };
          }
          return {
            ...prevModalInfo,
            [name]: value,
          };
        });
    };

    const fileInputRef1 = useRef([]);
    const handleFileChange1 = async (e, itemIndex) => {
        const formdata = new FormData();
        formdata.append("image", e?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res.data.data
            setModalInfo((prevModalInfo) => ({
                ...prevModalInfo,
                sub_categories: prevModalInfo.sub_categories.map((item, index) =>
                  index === itemIndex ? { ...item, img_URL: value } : item
                )
            }));
        })
        .catch((err)=>{
        })

    };
    const handleButtonClick1 = (index) => {
        fileInputRef1.current[index]?.click();
    };

    const fileInputRef = useRef(null);
    const handleFileChange = async (event) => {
        const {name} = event.target;

        const formdata = new FormData();
        formdata.append("image", event?.target?.files[0]);

        axios.post(api_url?.imageChanger, formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data",
            }
        })
        .then((res)=>{
            const value = res.data.data
            setModalInfo((prevModalInfo) => ({
                ...prevModalInfo,
                [name]: value,
            }));
        })
        .catch((err)=>{
        })
    };
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleGEtPayChannel = async () => {
        setLoader(true);
        try {
            let res = await payment.get(api_url.getPayChannel);

            setLoader(false);
            let data = res?.data?.data;
            let arr = [];
            for (var key in data){
                let row = data[key];
                let obj = {
                    previous_index: row?.index,
                    next_index: row?.index,
                    subcatogories: row?.sub_categories,
                    status: row?.status,
                    imgURL: row?.img_URL,
                    name: row?.name
                }
                arr.push(obj);
            }

            setPayChannel(arr);
        } catch (error) {
            setLoader(false);
        }
    }
    
    useEffect(() => {
        handleGEtPayChannel();
    }, [])

    const columns = [
        {
            name: "Index",
            selector: (row) => <div>
                {row?.next_index}
            </div>
        },
        {
            name: "Channel Name",
            selector: (row) => <div className='d-flex gap-2 align-items-center'>
                <ImageContainer>
                    <img alt="" src={row?.imgURL} height="30px" width="30px" />
                </ImageContainer>
                <p className='mb-0'>{row?.name}</p>
            </div>
        },
        {
            name: "Status",
            selector: (row) => <div style={{ fontWeight: 'bold', color: row?.status ? "#1C7947" : "#D22B2B" }} >
                {row?.status ? "active" : "in-active"}
            </div>
        },
        {
            name: "Sub-channels",
            selector: (row) => <div>
                {row?.subcatogories?.length}
            </div>
        },
        {
            name: "Action",
            selector: (row) => <div className='d-flex gap-1'>
                <img src={image?.event?.ViewImg} onClick={() => handleShowView(row)} alt="no-img" />
                <img src={image?.event?.EditImg} onClick={() => handleEdit(row)} alt="no-img" />
            </div>
        }
    ];

    return <div className='p-3'>
        <div className='row mb-3 m-0'>
            <Card>
                <div className='d-flex mx-2 justify-content-between align-items-center'>
                    <div>
                        <p className='mb-0 fw-bold fs-5'>Payment Channel</p>
                        <small className=' text-secondary'>Payment channels for payborit</small>
                    </div>
                </div>
                <Pay>
                    <DataTable
                        columns={columns}
                        data={payChannel}
                        progressComponent={<PageLoader />}
                        progressPending={loader}
                    />
                </Pay>
            </Card>
        </div>
        <Modal show={modalOpen?.view || modalOpen?.edit}>
            <div className='d-flex justify-content-between'>
                <div className='modal-head'>
                    <p className="heading mb-0">{modalOpen?.view ? "View Channel" : "Edit Channel"}</p>
                    <p className='sub-heading'>manually update the channel</p>
                </div>
                <p onClick={handleClose} className='cross-button'>x</p>
            </div>
            <div>
                <div className='mb-1'>
                    <Label>Channel Name</Label>
                    <Input
                        value={modalInfo?.name}
                        name="name"
                        id='name'
                        onChange={handleChange}
                        disabled={!modalOpen?.edit}
                    />
                </div>
                <div className='mb-1'>
                    <Label>Image</Label>
                    <InputContainer className='p-1'>
                        <input
                            placeholder='Upload Image for Event'
                            className='border-0 bg-transparent'
                            disabled={!modalOpen?.edit}
                            value={modalInfo?.imgURL}
                        />
                        <Input1
                            className='d-none'
                            type='file'
                            name='imgURL'
                            id='imgURL'
                            disabled={!modalOpen?.edit}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <ContainerButton type='button' onClick={handleButtonClick} className="custom-button">Browse Image <img alt="" src={image.event.UploadImage} /> </ContainerButton>
                    </InputContainer>
                </div>
                <div className='d-flex gap-1 my-2'>
                    <Label>Status</Label>
                    <input
                        checked={modalInfo?.status}
                        name="status"
                        id='status'
                        type='checkbox'
                        onChange={handleChange}
                        disabled={!modalOpen?.edit}
                    />
                </div>
                <div className='mb-1'>
                    <Label>Previous Index</Label>
                    <Input
                        value={modalInfo?.previous_index}
                        name="previous_index"
                        id='previous_index'
                        onChange={handleChange}
                        disabled={!modalOpen?.edit}
                    />
                </div>
                <div className='mb-1'>
                    <Label>Next Index</Label>
                    <Input
                        value={modalInfo?.next_index}
                        name="next_index"
                        id='next_index'
                        onChange={handleChange}
                        disabled={!modalOpen?.edit}
                    />
                </div>
                <Label className='mt-2'>Sub-channels</Label>
                {
                    modalInfo?.subcatogories?.map((data, index) => {
                        return <Option>
                            <div className='mb-1'>
                                <Label>Name</Label>
                                <Input
                                    value={data?.category}
                                    name="category"
                                    id='category'
                                    onChange={(e)=>handleChange(e, index)}
                                    disabled={!modalOpen?.edit}
                                />
                            </div>
                            <div>
                                <Label>Image</Label>
                                <OptionInputContainer>
                                    <input
                                        placeholder='OptionImage.JPG'
                                        className='border-0 bg-transparent'
                                        style={{ ...style, width: '100%' }}
                                        name='img_URL'
                                        id='img_URL'
                                        disabled={!modalOpen?.edit}
                                        value={data?.img_URL}
                                    />
                                    <Input1
                                        className='d-none'
                                        type='file'
                                        name='img_URL'
                                        // id='img_URL'
                                        id={`img_URL-${index}`}
                                        disabled={!modalOpen?.edit}
                                        ref={(el) => fileInputRef1.current[index] = el}
                                        onChange={(e) => handleFileChange1(e, index)}
                                    />
                                    <ContainerButton type='button' onClick={() => handleButtonClick1(index)} className="custom-button">Browse <img alt="" src={image.event.UploadImage} /> </ContainerButton>
                                </OptionInputContainer>
                            </div>
                        </Option>
                    })
                }
            </div>
            {modalOpen?.edit && <CreateButton disabled={loader1} onClick={handleUpdateChannel}>Update</CreateButton>}
        </Modal>
    </div>
}