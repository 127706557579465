// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content{
    padding: 20px;
}
.heading{
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}
.sub-heading{
    font-size: 16px;
    font-weight: 400;
    color: #A9A9A9;
}
.inputcontainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.inputcontainer > label {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
}
.inputcontainer > input {
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 7px 11px;
    outline: none;
    font-size: 14px;
}
.button{
    background: #D22B2B;
    color: white;
    width: 100%;
    padding: 6px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
}
.cross-button{
    background: #D22B2B1A;
    border: none;
    height: 25px;
    width: 24px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: #D22B2B;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Odds/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".modal-content{\n    padding: 20px;\n}\n.heading{\n    font-size: 20px;\n    font-weight: 600;\n    color: #333333;\n}\n.sub-heading{\n    font-size: 16px;\n    font-weight: 400;\n    color: #A9A9A9;\n}\n.inputcontainer{\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 15px;\n}\n.inputcontainer > label {\n    font-size: 14px;\n    font-weight: 500;\n    color: #666666;\n}\n.inputcontainer > input {\n    border: 1px solid #A9A9A98C;\n    border-radius: 8px;\n    padding: 7px 11px;\n    outline: none;\n    font-size: 14px;\n}\n.button{\n    background: #D22B2B;\n    color: white;\n    width: 100%;\n    padding: 6px;\n    border: none;\n    border-radius: 8px;\n    font-size: 14px;\n    font-weight: 600;\n}\n.cross-button{\n    background: #D22B2B1A;\n    border: none;\n    height: 25px;\n    width: 24px;\n    display: flex;\n    border-radius: 50px;\n    justify-content: center;\n    align-items: center;\n    color: #D22B2B;\n    font-size: 13px;\n    font-weight: 600;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
