import React from 'react'
import { Card } from '../CMS/style';
import ChangePassword from './password';

export default function Setting(){

    return(
        <p className='p-3'>
            <Card className='row m-0'>
                <p className='heading'>Setting</p>
                <div className='col-md-3' style={{borderRight: "2px solid #F5F5F5"}}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button 
                                className="nav-link active" 
                                id="nav-change-password-tab" 
                                data-bs-toggle="tab" 
                                data-bs-target="#nav-change-password" 
                                type="button" role="tab"
                                aria-controls="nav-change-password"
                                aria-selected="true"
                            > Change Password </button>
                            {/* <button
                                className="nav-link"
                                id="nav-transaction-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-transaction"
                                type="button" role="tab" 
                                aria-controls="nav-transaction" 
                                aria-selected="false"
                            >
                                Categories
                            </button> */}
                        </div>
                    </nav>
                </div>
                <div className='col-md-9'>
                    <div className="tab-content" id="nav-tabContent">
                        <div 
                            className="tab-pane fade show active"  
                            id="nav-change-password" 
                            role="tabpanel" 
                            aria-labelledby="nav-change-password-tab"
                        > <ChangePassword/> </div>
                        {/* <div 
                            className="tab-pane fade show" 
                            id="nav-transaction" 
                            role="tabpanel" 
                            aria-labelledby="nav-transaction-tab"
                        > Category </div> */}
                    </div>
                </div>
            </Card>
        </p>
    );
}