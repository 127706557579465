import styled from "styled-components";

export const Card1 = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;

    .table-header, .filter-container{
        display: flex;
        align-items: center;
        margin-top: 15px;
        justify-content: space-between;
    }
    
    .title{
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #333333;
    }

    .subtitle{
        font-size: 14px;
        font-weight: 400;
        // line-height: 28px;
        color: #666666;
    }
    .filter-container{
        gap: 10px;
    }
    
    .input-container{
        background: rgba(245, 245, 245, 1);
        border-radius: 50px;
        padding: 6px 10px;
        width: 180px;
        display: flex;
        align-items: center;
    }

    .input-container > input{
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        ::placeholder{
            color: #666666;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .dropdown-button{
        border: 1px solid rgba(169, 169, 169, 0.55);
        border-radius: 8px;
        color: #666666;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 11px;
        background: white;
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .add-button{
        background: rgba(210, 43, 43, 1);
        border-radius: 8px;
        padding: 7px 11px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .nav-tab-contaner{
        border: none !important;
        background: #d3d3d34a;
        width: fit-content;
        border-radius: 50px;
    }
    
    .nav-link{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        transition: 0.5s;
        }
        
    .nav-link.active{
        font-weight: 600;
        border-radius: 50px;    
        transition: 0.5s;
        margin-bottom: 1px;
        background: #D22B2B;
        color: white;
    }

    .rdt_TableCol, .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 13%;
        max-width: 13%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 22% !important;
        max-width: 22% !important;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .export-dropdown-menu{
        border: 1px solid #A9A9A98C !important;
        min-width: 90px !important;
    }

    .export-dropdown-menu > li{
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
            background: rgba(245, 245, 245, 1);
            cursor: pointer;
        }
    }
    
    .filter-dropdown-menu{
        inset: 0px auto auto -145px !important;
        width: 225px;
    }

    .event-time{
        color: #A9A9A9;
        font-size: 14px;
        font-weight: 500;
    }

    .event-title{
        color: #333333;
        font-size: 20px;
        font-weight: 600;
    }
`
export const Card2 = styled.div`
    background: white;
    border-radius: 12px;
    padding: 32px;
    border: none;

    .heading{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
    }
    .sub-head{
        font-size: 16px;
        font-weight: 500;
        color: #666666;
    }
        
    .react-datepicker-wrapper{
        width: 100%;
    }

    .view-labels{
        color: #666666;
        font-size: 14px;
        font-weight: 500;
    }

    .add-button{
        background: rgba(210, 43, 43, 1);
        border-radius: 8px;
        padding: 7px 11px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .add-button-1{
        background: rgba(210, 43, 43, 1);
        border-radius: 8px;
        padding: 3px 7px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .green-button{
        background: #1C7947;
        border-radius: 8px;
        padding: 7px 11px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .total-card{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 33%;
        border: 2px solid #A9A9A94D;
        padding: 20px;
        border-radius: 16px;
    }
`
export const Nav = styled.div`
    .nav-link{
        width: 25%;
        border: none !important;
        color: #A9A9A9;
        font-size: 16px ;
        font-weight: 600;
        padding: 5px;
        border-bottom: 2px solid #A9A9A9 !important;
        transition: 0.8s;
    }

    .nav-link.active{
        border-bottom: 2px solid #D22B2B !important;
        color: #D22B2B;
        font-size: 16px ;
        font-weight: 600;
    }

    .input-container{
        background: rgba(245, 245, 245, 1);
        border-radius: 50px;
        padding: 6px 10px;
        width: 180px;
        display: flex;
        align-items: center;
    }

    .input-container > input{
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        ::placeholder{
            color: #666666;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .export-dropdown-menu{
        border: 1px solid #A9A9A98C !important;
        min-width: 90px !important;
    }

    .export-dropdown-menu > li{
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
            background: rgba(245, 245, 245, 1);
            cursor: pointer;
        }
    }

    .dropdown-button{
        border: 1px solid rgba(169, 169, 169, 0.55);
        border-radius: 8px;
        color: #666666;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 11px;
        background: white;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .nav-subhead{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .nav-head{
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        line-height: 28px
    } 

    .tab-pane.fade{
        transition: 0.4s;
    }
`
export const History = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
    }
    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 13%;
        max-width: 13%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 11% !important;
        max-width: 11% !important;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 9%;
        max-width: 9%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 11%;
        max-width: 11%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 12%;
        max-width: 12%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 14%;
        max-width: 14%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 6%;
        max-width: 6%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="8"], .rdt_TableCol[data-column-id="8"]{
        min-width: 6%;
        max-width: 6%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="9"], .rdt_TableCol[data-column-id="9"]{
        min-width: 6%;
        max-width: 6%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="10"], .rdt_TableCol[data-column-id="10"]{
        min-width: 6%;
        max-width: 6%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="11"], .rdt_TableCol[data-column-id="11"]{
        min-width: 6%;
        max-width: 6%;
        padding: 0px !important;
    }
`
export const Profile = styled.div`

`
export const Bets = styled.div`

`
export const ParletCard = styled.div`
    margin-bottom: 10px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 8px;

    .rdt_Table, .rdt_TableHeadRow, .rdt_TableRow{
        background: transparent !important;
    }
`
export const BetSummary = styled.div`
    border-radius: 8px;
    background: #1C79471A;
    padding: 10px 0px;

    .label{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
    }
    .label-color{
        color: #666666;
    }
    .flex-detail{
        display: flex;
        gap: 5px;
        align-items: center;
    }
    .odd-box{
        background: #4169E1;
        border-radius: 4px;
        padding: 3px 11px;
        color: white;
        font-weight: 600;
    }
`   