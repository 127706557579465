import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom';
import TotalUser from '../../images/totalUser.png'
import ActiveUser from '../../images/activeUser.png'
import InactiveUser from '../../images/inactiveUser.png'
import BannedUser from '../../images/bannedUser.png'
import ViewImg from '../../images/eye-view.png'
import SuspendImg from '../../images/suspend_user.png'
import UnsuspendImg from '../../images/un_suspend.png'
import * as XLSX from 'xlsx';   
import Delete from '../../images/bannedUser.png'
import { Card, Active, ConsentHeading,  Subhead, CancelButton, DeleteButton1 } from '../Event/style';
import { Card1 } from './styled';
import { CiSearch } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";
import { FiDownloadCloud } from "react-icons/fi";
import { FaCircle, FaPlus } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { useSelector, useDispatch } from 'react-redux';
import { getUsersDetails } from '../../Component/slices/userSlice';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Button, Heading, Input, InputContainer, Label, SubHeading } from '../Auth/styled';
import './style.css'
import { Oval } from 'react-loader-spinner';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { api_url } from '../../Utils/api-urls';
import axios from 'axios';
import PreRegister from './PreRegister';
import PageLoader from '../../Hooks/pageLoader';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        background:'#fcfcfd',
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}
const filterData = {
    categoryData: [
        {
            value: "all",
            label: "All"
        },
        {
            value: "sports",
            label: "Sports"
        },
        {
            value: "politics",
            label: "Politics"
        },
        {
            value: "entertainment",
            label: "Entertainment"
        },
        {
            value: "weather",
            label: "Weather"
        }
    ],
    statusData: [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Closes",
            value: "closes"
        },
        {
            label: "Freeze",
            value: "freeze"
        }
    ]
}

export default function UserManagement(){
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const [loader, setLoader] = useState(false);

    const [showAdd, setShowAdd] = useState(false);
    const handleShowAdd = ()=>{
        setShowAdd(true);
    }
    const handleCloseAdd = ()=>{
        setShowAdd(false);
    }

    const usersData = useSelector((state)=> state?.users?.items)

    useEffect(()=>{
        dispatch(getUsersDetails())
    },[])


    const [deleteConsent, setDeleteConsent] = useState({
        state: false,
        id: '',
        isSuspend: false
    })
    const handleDelete = (id, isSuspend)=>{
        setDeleteConsent({
            state: true,
            id,
            isSuspend
        })
    }
    const handleDeleteCancel = ()=>{
        setDeleteConsent({
            state: false,
            id: "",
            isSuspend: false
        })
    }

    const handleSuspend = (id)=>{
        axios.post(api_url.suspendUser(id), {}, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then((res)=>{
            dispatch(getUsersDetails())
            handleDeleteCancel();
            toast.success(res?.data?.message ? res?.data?.message : 'User is successfully suspended!!')
        })
        .catch((err)=>{
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "some error occured")
        })
    }
    
    const handleUnSuspend = (id)=>{
        axios.post(api_url.unSuspendUser(id), {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            toast.success(res?.data?.message ? res?.data?.message : 'User is successfully un-suspended!!')
            dispatch(getUsersDetails())
            handleDeleteCancel();
        })
        .catch((err)=>{
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "some error occured")
        })
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            username: "",
            password: ""
        },
        validationSchema: yup.object().shape({
            email: yup.string().required('E-mail is required'),
            username: yup.string().required('Username is required'),
            password: yup.string().required('Password is required')
        }),
        onSubmit: (values)=>{
            toast.success("User add successfully");
            handleCloseAdd()
            formik.setValues({
                email: "",
                username: "",
                password: ""
            })
        }
    })
    
    const columns = [
        {
            name: 'User Name',
            selector: (row)=> <div>
                {row?.username}
            </div>
        },
        {
            name: 'Email',
            selector: (row)=> <div>
                {row?.email}
            </div>
        },
        {
            name: "Mobile",
            selector: (row)=> <div>
                {row?.mobile ? row?.mobile : "N/A"}
            </div>
        },
        {
            name: "Total Bets",
            selector: (row)=> <div>
                {row?.total_pool ? row?.total_pool : '0'}
            </div>,
        },
        {
            name: "Last Active",
            selector: (row)=> <div>
                {new Date(row?.updatedAt).toDateString()}
            </div>,
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {                                
                    row?.isSuspended ? <Active color="#E4B528" background= "#E4B5281A"><FaCircle size={7} color='#E4B528'/>Inactive</Active> : 
                    <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Active</Active>
                }
            </div>
        },  
        {
            name: "Action",
            selector: (row)=> <div className=' d-flex gap-3'>
                <img src={ViewImg} title='view' onClick={()=>navigate(`/user-details/${row?._id}`) }/>
                {
                    row?.isSuspended ? <img title='unsuspend user' src={UnsuspendImg} onClick={()=>handleDelete(row?._id, row?.isSuspended)}/> :
                    <img title='suspend user' src={SuspendImg} onClick={()=>handleDelete(row?._id, row?.isSuspended)}/>
                }
            </div>
        }
    ]

    const handleDownloadinEXL = ()=>{

        const data = usersData.map((data)=>{
            return {
                username: data?.username,
                email: data?.email,
                mobile: data?.mobile,
                total_bets: data?.total_pool ? data?.total_pool  : 0,
                last_active: new Date(data?.updatedAt)?.toLocaleString(),
                status: data?.isEmailVerified ? "E-mail verified" : "Not verified"
            }
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Data.xlsx");
    }
    const handleDownloadinCSV = ()=>{
        const data = usersData.map((data)=>{
            return {
                username: data?.username,
                email: data?.email,
                mobile: data?.mobile,
                total_bets: data?.total_pool ? data?.total_pool  : 0,
                last_active: new Date(data?.updatedAt)?.toLocaleString(),
                status: data?.isEmailVerified ? "E-mail verified" : "Not verified"
            }
        })

        const worksheet = XLSX.utils.json_to_sheet(data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }                                                    
    const searchfilter = usersData?.length > 0 ? usersData?.filter((data)=>{
        return data?.username?.toLowerCase().includes(searchInput?.toLowerCase()) || data?.email?.toLowerCase().includes(searchInput?.toLowerCase()) ||
            new Date(data?.updatedAt).toLocaleString().toLowerCase().includes(searchInput?.toLowerCase())
    }) : [];

    const data = [
        {
            image: <img src={TotalUser} width="50px"/>,
            title: "Total Users",
            total: usersData?.length ? usersData?.length : 0
        },
        {
            image: <img src={ActiveUser} width="40px"/>,
            title: "Active Users",
            total: "0"
        },
        {
            image: <img src={InactiveUser} width="45px"/>,
            title: "Inactive Users",
            total: "0"
        },
        {
            image: <img src={BannedUser} width="40px"/>,
            title: "Banned Users",
            total: "0"
        }
    ];

    return(
        <div className='container-fluid px-0 py-3'>
            <div className='row m-0 d-flex flex-wrap justify-content-center justify-content-md-between'>
                { 
                    data?.map((data, index)=>{
                        return <div key={index} className="col-md-3 mb-3 col-10">
                            <Card>  
                                <div>
                                    <p className='mb-0 title'>{data?.title}</p>
                                    <p className='total mb-2'>{data?.total}</p>
                                </div>
                                <div>{data?.image}</div>
                            </Card>
                        </div>
                    })
                }
            </div>

            <div className='row m-0'>
                <div className='col-12'>
                    <Card1 className='px-0'>
                        <nav>
                            <div className="nav nav-tab-contaner nav-tabs" id="nav-tab" role="tablist">
                                <button  
                                    className="nav-link active" 
                                    id="nav-withdrawal-tab" 
                                    data-bs-toggle="tab" 
                                    data-bs-target="#nav-withdrawal" 
                                    type="button" role="tab" 
                                    aria-controls="nav-withdrawal" 
                                    aria-selected="true"
                                >
                                    User Management
                                </button>   
                                <button 
                                    className="nav-link"
                                    id="nav-transaction-tab"   
                                    data-bs-toggle="tab" 
                                    data-bs-target="#nav-transaction" 
                                    type="button" role="tab" 
                                    aria-controls="nav-transaction" 
                                    aria-selected="false"
                                >
                                    Pre-Registered Users
                                </button>
                            </div>
                        </nav>
                        <div className='tab-content' id='nav-tabContent'>
                            <div className="tab-pane fade show active" id="nav-withdrawal" role="tabpanel" aria-labelledby="nav-withdrawal-tab" > 
                                <div className='table-header mb-3 px-3'>
                                    <div>
                                        <p className='title mb-0'>Users</p>
                                        <small className='subtitle'>Manage and track all users</small>
                                    </div>
                                    <div className='filter-container'>
                                        <div className='input-container'>
                                            <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                            <input 
                                                value={searchInput}
                                                onChange={(e)=>{ setSearchInput(e.target.value) }}
                                                placeholder='Search..'
                                            />
                                        </div>
                                        <div className="dropdown">
                                            <button className="dropdown-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <IoFilter size={16} color='#333333'/> Filter
                                            </button>
                                            <ul className="dropdown-menu filter-dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                                <div className='d-flex justify-content-between align-items-center mb-2'>
                                                    <p className='mb-0'>Dropdown</p>
                                                    <p className='mb-0 border border-rounded'>X</p>
                                                </div>
                                                <div>
                                                    {
                                                        filterData?.categoryData?.map((data, index)=>{
                                                            return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                                <input type='checkbox' />
                                                                <p className='mb-0 text-secondary'>{data?.label}</p>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <p className='mb-0'>Status</p>
                                                <div>
                                                    {
                                                        filterData?.statusData?.map((data, index)=>{
                                                            return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                                <input type='radio'  name={data?.value} id={data?.value} />
                                                                <p className='mb-0 text-secondary'>{data?.label}</p>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <button className='add-button mt-2'> Apply Filter </button>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FiDownloadCloud size={16} color='#333333'/> Export
                                            </button>
                                            <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                                <li onClick={handleDownloadinCSV} className="dropdown-item">CSV <LuDownload/> </li>
                                                <li onClick={handleDownloadinEXL} className="dropdown-item">EXCEL <LuDownload/></li>
                                            </ul>
                                        </div>
                                        <button className='add-button' onClick={handleShowAdd} >
                                            <FaPlus color='white' strokeWidth={2}/>
                                            Add New User
                                        </button>
                                    </div>
                                </div>
                                { searchfilter?.length > 0 ? <DataTable
                                    columns={columns}
                                    data={searchfilter}
                                    paginationPerPage={10}
                                    pagination
                                    customStyles={customStyles}
                                /> : <PageLoader/> }
                            </div>
                            <div className="tab-pane fade show" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                                <PreRegister/>
                            </div>
                        </div>
                        
                    </Card1>
                </div>
            </div>

            <Modal show={showAdd}>
                <div>
                    <div className='d-flex justify-content-between'>
                        <div style={{width: "95%"}} className='text-center'>
                            <Heading>Add User</Heading>
                            <SubHeading>Fill the details and add user manually</SubHeading>
                        </div>
                        <div onClick={handleCloseAdd} className='cross-button'>X</div>
                    </div>
                    <form onSubmit={formik.handleSubmit} className=' mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='email'>Email Or Mobile Number</Label>
                            <InputContainer>
                                <Input
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    id='email'
                                    name='email'
                                    placeholder='Enter email or mobile number'
                                />
                            </InputContainer>
                            {formik.touched.email && <small style={{fontSize: "10px"}} className='text-danger'>{formik.errors.email}</small>}
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='username'>Username</Label>
                            <InputContainer>
                                <Input
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    id='username'
                                    name='username'
                                    placeholder='Enter a user name'
                                />
                            </InputContainer>
                            {formik.errors.username && <small style={{fontSize: "10px"}} className='text-danger'>{formik.errors.username}</small>}
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='password'>Password</Label>
                            <InputContainer>
                                <Input
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    id='password'
                                    name='password'
                                    placeholder='Enter password'
                                />
                            </InputContainer>
                            {formik.errors.password && <small style={{fontSize: "10px"}} className='text-danger'>{formik.errors.password}</small>}
                        </div>
                        <Button disabled={loader} type='submit'>{loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>}Add User</Button>
                    </form>
                </div>
            </Modal>

            <Modal show={deleteConsent?.state}>
                <div className='text-center'>
                    <img src={Delete} width="30px"/>
                    <ConsentHeading className='mt-3 mb-0'>Are you sure you want to {deleteConsent?.isSuspend ? "un-suspend" : "suspend"} this user?</ConsentHeading>
                    <Subhead>You can {deleteConsent?.isSuspend ? "suspend" : "un-suspend"} this user.</Subhead>
                    <div className='d-flex align-items-center gap-2 justify-content-center'>     
                        <CancelButton onClick={handleDeleteCancel}>Cancel</CancelButton>
                        <DeleteButton1 
                        onClick={()=>{
                            if(deleteConsent?.isSuspend) handleUnSuspend(deleteConsent?.id)
                            else handleSuspend(deleteConsent?.id)
                        }}>{deleteConsent?.isSuspend ? "un-suspend" : "suspend"}</DeleteButton1>
                    </div> 
                </div>
            </Modal>
        </div>
    );
}