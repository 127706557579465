import React from 'react'
import DataTable from 'react-data-table-component';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import PageLoader from '../../Hooks/pageLoader';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

const data = [
    {
        transaction: "#TX67890",
        name: "maheep singh",
        date: '12/08/2024',
        type: 'deposite',
        amount: '₱1,000',
        balance: "₱2,500",
        status: "complete"
    },
    {
        transaction: "#TX67890",
        name: "maheep singh",
        date: '12/08/2024',
        type: 'deposite',
        amount: '₱1,000',
        balance: "₱2,500",
        status: "pending"
    },
    {
        transaction: "#TX67890",
        name: "maheep singh",
        date: '12/08/2024',
        type: 'withdrawal',
        amount: '₱1,000',
        balance: "₱2,500",
        status: "complete"
    },
    {
        transaction: "#TX67890",
        name: "maheep singh",
        date: '12/08/2024',
        type: 'withdrawal',
        amount: '₱1,000',
        balance: "₱2,500",
        status: "pending"
    }
]

const columns = [
    {
        name: "Transaction ID",
        selector: (row)=> <div>
            {row?.transaction}
        </div>
    },
    {
        name: "Username",
        selector: (row)=> <div>
            {row?.name}
        </div>
    },
    {
        name: "Date",
        selector: (row)=> <div>
            {row?.date}
        </div>
    },
    {
        name: "Type", 
        selector: (row)=> <div>
            {row?.type}
        </div>
    },
    {
        name: "Amount",
        selector: (row)=> <div>
            {row?.amount}
        </div>
    },
    {
        name: "Balance",
        selector: (row)=> <div>
            {row?.amount}
        </div>
    },
    {
        name: "Status",
        selector: (row)=> <div style={{ color: row?.status === "complete" ? "#037847" : "#E4B528"} }>
            {row?.status}
        </div>
    }
]
export default function Transaction(){
    return(
        <>
            <div className='d-flex mt-4 mb-2 justify-content-between align-items-center'>
                <div>
                    <p className='mb-0 fw-bold fs-5'>Transaction History</p>
                    <small className=' text-secondary'>Transaction History & Audit Logs</small>
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <div className="dropdown">
                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                        <FiDownloadCloud size={16} color='#333333'/> Export
                        </button>
                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <li 
                            // onClick={handleDownloadinCSV} 
                            className="dropdown-item">CSV <LuDownload/> </li>
                            <li 
                            // onClick={handleDownloadinEXL} 
                            className="dropdown-item">EXCEL <LuDownload/></li>
                        </ul>
                    </div>
                </div>
            </div>
            {data?.length > 0 ? <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
            /> : <PageLoader/> }
        </>
    );
}