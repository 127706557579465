// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-dropdown-menu{
    border: 1px solid #A9A9A98C !important;
    min-width: 90px !important;
}

.export-dropdown-menu > li{
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.export-dropdown-menu > li:hover{
    background: rgba(245, 245, 245, 1);
    cursor: pointer;
}

.dropdown-button{
    border: 1px solid rgba(169, 169, 169, 0.55);
    border-radius: 8px;
    color: #666666;
    font-size: 13px;
    font-weight: 600;
    padding: 7px 11px;
    background: white;
    display: flex;
    align-items: center;
    gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Report/style.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,2CAA2C;IAC3C,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".export-dropdown-menu{\n    border: 1px solid #A9A9A98C !important;\n    min-width: 90px !important;\n}\n\n.export-dropdown-menu > li{\n    font-size: 12px;\n    font-weight: 500;\n    padding: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.export-dropdown-menu > li:hover{\n    background: rgba(245, 245, 245, 1);\n    cursor: pointer;\n}\n\n.dropdown-button{\n    border: 1px solid rgba(169, 169, 169, 0.55);\n    border-radius: 8px;\n    color: #666666;\n    font-size: 13px;\n    font-weight: 600;\n    padding: 7px 11px;\n    background: white;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
