import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api_url } from '../../Utils/api-urls';
import { ViewButton } from '../Event/style';
import DataTable from 'react-data-table-component';
import { Tired } from './style';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../Odds/style.css'
import { Oval } from 'react-loader-spinner';
import PageLoader from '../../Hooks/pageLoader';
import { CiSearch } from 'react-icons/ci'

export default function Payout({customStyles}){

    const [searchInput, setSearchInput] = useState('');
    const [edit, setEdit] = useState(false);
    const handleShowUpdate = (data)=>{
        setEdit(true);
        formik.setValues({
            odds: data.odds,
            probability: data.probability
        });
    }                    
    const handleCloseUpdate = ()=>{
        setEdit(false);
    }

    const [loader, setLoader] = useState(false);
    const formik = useFormik({
        initialValues: {
            odds: "",
            probability: ""
        },
        validationSchema: Yup.object().shape({
            odds: Yup.string().required("Odd is required"),
            probability: Yup.string().required("Probability is required")
        }),
        onSubmit: (values) => {
            setLoader(true);
            axios.patch(api_url.updateCalcRate, {
                odds: Number(values.odds),
                probability: values.probability
            },
            {
                headers: {                         
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{ 
                setLoader(false);
                handleCloseUpdate();
                handleGetPayout();
            })
            .catch((err)=>{ 
                setLoader(false);
            })
        }
    })

    const [payoutList, setPayoutList] = useState()
    const handleGetPayout = ()=>{
        axios.get(api_url.getCalcRate,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setPayoutList(res?.data?.data);
        })
        .catch((err)=>{
        })
    }

    const columns = [
        {
            name: "S.NO",
            selector: (row, index)=> <div>
                {index+1}
            </div>
        },
        {
            name: "Probability",
            selector: (row)=> <div>
                {row?.probability}
            </div>
        },
        {
            name: "Odds",
            selector: (row)=> <div>
                {row?.odds} %
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton onClick={()=>handleShowUpdate(row)}>
                    Update
                </ViewButton>
            </div>
        }
    ];

    const filteredData = payoutList?.filter((data)=>{
        if(searchInput) return Number(searchInput) === data?.probability;
        return data;
    })

    useEffect(()=>{
        handleGetPayout();
    },[])
    return(
        <>
            <div className='table-header mb-3 px-3'>
                <div>
                    <p className='title mb-0'>Payout Management</p>
                    <small className='subtitle'>Manage and track all the payout calculation</small>
                </div>
                <div className='filter-container'>
                    <div className='input-container'>
                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                        <input 
                            value={searchInput}
                            onChange={(e)=>{
                                setSearchInput(e.target.value)
                            }}
                            placeholder='search...'
                        />
                    </div>
                </div>
            </div>
            <Tired>
                { payoutList?.length > 0 ? <DataTable
                    data={filteredData}
                    columns={columns}
                    pagination
                    customStyles={customStyles}
                />: <PageLoader/>}
            </Tired>

            <Modal show={edit}>
                <div className='d-flex justify-content-between'>
                    <div className='modal-head'>
                        <p className="heading mb-0">Update Payout </p>
                        <p className='sub-heading'>update the Payout calculation parameters</p>
                    </div>
                    <p onClick={handleCloseUpdate} className='cross-button'>x</p>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='inputcontainer'>
                        <label htmlFor='odds'>Odds (%)</label>
                        <input 
                            placeholder='Enter odds'
                            value={formik.values.odds}
                            onChange={formik.handleChange}
                            name='odds'
                            id='odds'
                        />
                    </div>
                    <div className='inputcontainer'>
                        <label htmlFor='probability'>Probability</label>
                        <input        
                            placeholder='Enter probability'
                            value={formik.values.probability}
                            onChange={formik.handleChange}
                            name='probability'
                            id='probability'
                        />
                    </div>
                    <button type='submit' disabled={loader} className='d-flex justify-content-center gap-1 align-items-center button'>{loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>} Update</button>
                </form>
            </Modal>
        </>
    );
}