import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api_url } from '../../Utils/api-urls';
import { Card2, Nav, Bets, Profile, History } from './styled';
import { FaChevronLeft } from 'react-icons/fa';
import { MdBlock } from "react-icons/md";
import { toast } from 'react-toastify';
import { CiSearch } from 'react-icons/ci';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import { Active, CancelButton, ConsentHeading, DeleteButton1, Subhead, ViewButton } from '../Event/style';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { Select } from '../Home/style';
import moment from 'moment';
import { image } from '../../Utils/images';
import api from '../../Service/api';

const betdata = [
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "won"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "lost"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "ongoing"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "won"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "lost"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "won"
    },
    {
        title: "Jake Paul vs. Mike Tyson: Who will win?",
        betplace: "12/08/2024",
        odds: "22%",
        bet: "₱1,000",
        potential: "₱2,500",
        status: "lost"
    }

]
const txnData = [
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "deposite",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "Withdrawal",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "pending"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "Withdrawal",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "deposite",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    },
    {
        hash: "#TX67890TX67890TX67890TX67890",
        date: "12/08/2024",
        txn_type: "deposite",
        amount: "₱1,000",
        balance: "₱2,500",
        status: "complete"
    }
]
export const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}
export default function SingleUser(){

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const {id} = params;
    const authId = location?.state;

    console.log(authId, 'authId is from location')
    const [userData, setUserData] = useState();
    const [selectParleyStatus, setSelectParleyStatus] = useState('all')
    const [payleyData, setParleyData] = useState()
    const [selectNormalStatus, setSelectNormalStatus] = useState('all')
    const [normalBetData, setNormalBetData] = useState()
    const [referedData, setReferedData] = useState();
    const column1 = [
        {
            name: "Event Title",
            selector: (row)=><div>
                {row?.title}
            </div>
        },
        {
            name: "Categories",
            selector: (row)=><div>
                {row.category}
            </div>
        },
        {
            name: "Total Pool",
            selector: (row)=><div>
                ₱{row.total_pool}
            </div>
        },
        {
            name: "Amount",
            selector: (row)=><div>
                ₱{row?.bets[0]?.amount}
            </div>
        },
        {
            name: "Potential Payout",
            selector: (row)=><div>
                ₱{row?.bets[0]?.potential_payout}
            </div>
        },
        {
            name: "Status",
            selector: (row)=><div>
                <Active
                    color={row?.bets[0]?.status === "won" ? "#037847" : row?.bets[0]?.status === "lost" ? "#D22B2B" : "#4169E1"}
                    background={row?.bets[0]?.status === "ongoing" && "#4169E11A"}
                >
                    {row?.bets[0]?.status === "won" ? "Won" : row?.bets[0]?.status === "lost" ? "Lost" : "Ongoing"}
                </Active>
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton onClick={()=> navigate(`/single-event-details/${row?.bets[0]?._id}`, {state: { authId: authId?.auth0UserId }})}>View</ViewButton>
            </div>
        }
    ]
    const column2 =[
        {
            name: "Transaction ID",
            selector: (row)=><div>
                {row?.hash}
            </div>
        },
        {
            name: "Date",
            selector: (row)=><div>
                {row?.date}
            </div>
        },
        {
            name: "Type",
            selector: (row)=><div>
                {row?.txn_type}
            </div>
        },
        {
            name: "Amount",
            selector: (row)=><div>
                {row?.amount}
            </div>
        },
        {
            name: "Balance",
            selector: (row)=><div>
                {row?.balance}
            </div>
        },
        {
            name: "Status",
            selector: (row)=><div>
                <Active color={row?.status === "complete" ? "#037847" : "#E4B528"} >
                    {row?.status}
                </Active>
            </div>
        }
    ]
    const column3 =[
        {
            name: "Total Events",
            selector: (row)=><div>
                {row?.total_events}
            </div>
        },
        {
            name: "Average Odd",
            selector: (row)=><div>
                {row?.odds}%
            </div>
        },
        {
            name: "Amount",
            selector: (row)=><div>
                ₱{row?.amount}
            </div>
        },
        {
            name: "Payout (if win)",
            selector: (row)=><div>
                ₱{row?.potential_payout}
            </div>
        },
        {
            name: "Date",
            selector: (row)=><div>
                {moment(new Date()).format("DD-MM-YYYY")}
            </div>
        },
        {
            name: "Result Declare",
            selector: (row)=> <div>
                <Active color={row?.events_declared ? "#037847" : "#E4B528"} >
                    {row?.events_declared ? "Declared": "Not Declared"}
                </Active>
            </div>
        },
        {
            name: "Status",
            selector: (row)=><div>
                <Active color={row?.status === "won" ? "#037847" : "#E4B528"} >
                    {row?.status}
                </Active>
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton onClick={()=> navigate(`/combo-panalo-details/${row?._id}`, {state: { authId: authId?.auth0UserId }})}>View</ViewButton>
            </div>
        }
    ]
    const column4 =[
        {
            name: "Refered User",
            selector: (row)=><div>
                {row?.referredUserName}
            </div>
        },
        {
            name: "Signup Date",
            selector: (row)=><div>
                {moment(row?.signUpDate).format('DD-MM-YYYY')}
            </div>
        },
        {
            name: "FTD Date",
            selector: (row)=><div>
                ₱{row?.firstDepositAmount}
            </div>
        },
        {
            name: "FTD Amount",
            selector: (row)=><div>
                {moment(row?.firstDepositAmount).format("DD-MM-YYYY")}
            </div>
        },
        {
            name: "Total Deposits",
            selector: (row)=><div>
                ₱{row?.totalDepositAmount}
            </div>
        },
        {
            name: "Total Withdrawals",
            selector: (row)=><div>
                ₱{row?.totalWithdrawalAmount}
            </div>
        },
        {
            name: "Bets",
            selector: (row)=><div>
                ₱{row?.totalBetAmount}
            </div>
        },
        {
            name: "Win",
            selector: (row)=><div>
                ₱{row?.totalWinAmount}
            </div>
        },
        {
            name: "Bonus",
            selector: (row)=><div>
                {row?.bonus}
            </div>
        },
        {
            name: "NGR",
            selector: (row)=><div>
                {row?.NGR}
            </div>
        },
        {
            name: "GGR",
            selector: (row)=> <div>
                {row?.GGR}
            </div>
        }
    ]
    
    const [deleteConsent, setDeleteConsent] = useState({
        state: false,
        id: '',
        isSuspend: false
    })
    const handleDelete = (id, isSuspend)=>{
        setDeleteConsent({
            state: true,
            id,
            isSuspend
        })
    }
    const handleDeleteCancel = ()=>{
        setDeleteConsent({
            state: false,
            id: "",
            isSuspend: false
        })
    }

    const handleSuspend = (id)=>{
        axios.post(api_url.suspendUser(id),{},{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            handleFetchThisUser(id);
            handleDeleteCancel();
            toast.success(res?.data?.message ? res?.data?.message : "user suspended successfully.")
        })
        .catch((error)=>{
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "some error occured");
        })
    }
    const handleUnSuspend = (id)=>{
        axios.post(api_url.unSuspendUser(id),{},{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            toast.success(res?.data?.message ? res?.data?.message : 'User is successfully un-suspended!!')
            handleFetchThisUser(id);
            handleDeleteCancel();
        })
        .catch((error)=>{
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "some error occured")
        })
    }

    const handleFetchThisUser=(id)=>{
        axios.get(api_url.singleUsers(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            setUserData(res?.data?.data);
            handleGetParleyHistory(selectParleyStatus, authId?.auth0UserId);
            if(res?.data?.data?.isAffiliate){
                handleGetAffiliate(id);
            } else{
                handleGetRefered(id);
            }
            handleGetNormalHistory(selectNormalStatus, authId?.auth0UserId);
            handleGetTxnHistory(id);
        })
        .catch((err)=>{
        })
    }
    
    const handleGetTxnHistory = (id)=>{
        axios.get(api_url.singleUserTxn(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
        })
        .catch((error)=>{
        })
    }
        
    const handleChangeParleyStatus = (e)=>{
        setSelectParleyStatus(e.target.value);
        handleGetParleyHistory(e.target.value, authId?.auth0UserId);
    }
    const handleGetParleyHistory = (status, id)=>{
        axios.get(api_url.singleUserParley(status, id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setParleyData(res?.data?.data);
            console.log(res);
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const handleChangeNormalStatus = (e)=>{
        setSelectNormalStatus(e.target.value);
        handleGetNormalHistory(e.target.value, authId?.auth0UserId);
    }
    const handleGetNormalHistory = (status, id)=>{
        const encodeId = encodeURIComponent(id);
        axios.get(api_url.singleUserNormalBet(status, encodeId),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            console.log(res, 'this is res from normal ');
            setNormalBetData(res?.data?.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    const handleGetAffiliate = async (id) => {
        try{
            let response = await api.get(api_url.getUserAffiliated(id));
            setReferedData(response?.data?.data);
        } catch(error){
            console.log(error);
        }
    }
    const handleGetRefered = async (id) => {
        try{
            let response = await api.get(api_url.getUserRefered(id));
            setReferedData(response?.data?.data);
        } catch(error){
            console.log(error);
        }
    }

    useEffect(()=>{
        handleFetchThisUser(id)
    },[])

    const data = [
        {
            name: "Total Bets",
            num: userData?.total_bet,
            image: <img alt="" width="55px" src={image.user.TotalBetImg} />
        },
        {
            name: "Total Won",
            num: userData?.total_earn,
            image: <img alt="" width="55px" src={image.user.TotalWonImg} />
        },
        {
            name: "Total Loss",
            num: userData?.total_lose,
            image: <img alt="" width="55px" src={image.user.TotalLoseImg} />
        }
    ];

    return(
        <div className='p-3'>
            <Card2>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center gap-3'>
                        <FaChevronLeft color="#D22B2B" size={17}
                            onClick={()=>{
                                navigate(-1);
                            }}
                        />
                        <img alt="" src={userData?.image ? userData?.image : image.user.Sample} width="80px"/>
                        <div>
                            <p className='heading mb-1'>{userData?.username}</p>
                            <p className='sub-head mb-0'>Email: {userData?.email}</p>
                            <p className='sub-head mb-0'>Joined On: {new Date(userData?.createdAt).toLocaleDateString()}</p>
                        </div>
                    </div>
                    {
                        userData?.isSuspended ? 
                        <button className='green-button' onClick={()=>handleDelete(id, userData?.isSuspended)}><img alt="" src={image.user.Unsuspend} width="17px"/> Un-suspend</button> :
                        <button className='add-button' onClick={()=>handleDelete(id, userData?.isSuspended)}><MdBlock size={18}/> Suspend</button>
                    }
                </div>
                <div className='d-flex gap-3 mt-4'>
                    {
                        data?.map((data)=>{
                            return <div className='total-card'>
                                <div>
                                    <p className='mb-0 sub-head'>{data?.name}</p>
                                    <p className='mb-0 heading'>{data?.num}</p>
                                </div>
                                {data?.image}
                            </div>
                        })
                    }
                </div>
                <Nav className='mt-4'>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-bet-tab" data-bs-toggle="tab" data-bs-target="#nav-bet" type="button" role="tab" aria-controls="nav-bet" aria-selected="true">All Bets</button>
                            <button class="nav-link" id="nav-transaction-tab" data-bs-toggle="tab" data-bs-target="#nav-transaction" type="button" role="tab" aria-controls="nav-transaction" aria-selected="false">Transaction</button>
                            <button class="nav-link" id="nav-combo-tab" data-bs-toggle="tab" data-bs-target="#nav-combo" type="button" role="tab" aria-controls="nav-combo" aria-selected="false">Combo-Panalo</button>
                            <button class="nav-link" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab" aria-controls="nav-history" aria-selected="false">{userData?.isAffiliate ? "Affiliate History" : "Referal History"}</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-bet" role="tabpanel" aria-labelledby="nav-bet-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>Bets</p>
                                    <p className='nav-subhead'>Manage and track all user’s bet</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <Select value={selectNormalStatus} onChange={handleChangeNormalStatus}>
                                        <option value="all">All</option>
                                        <option value="active">Active</option>
                                        <option value="won">Won</option>
                                        <option value="lost">Lost</option>
                                    </Select>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input 
                                            // value={searchInput}
                                            // onChange={(e)=>{
                                            //     setSearchInput(e.target.value)
                                            // }}
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Bets>
                                <DataTable
                                    columns={column1}
                                    data={normalBetData}
                                    customStyles={customStyles}
                                    pagination
                                />
                            </Bets>
                        </div>
                        <div class="tab-pane fade" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>Transaction</p>
                                    <p className='nav-subhead'>Manage and track all user’s transaction</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Profile>
                                <DataTable
                                    columns={column2}
                                    data={txnData}
                                    customStyles={customStyles}
                                    pagination
                                />
                            </Profile>
                        </div>
                        <div class="tab-pane fade" id="nav-combo" role="tabpanel" aria-labelledby="nav-combo-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>Combo Panalo</p>
                                    <p className='nav-subhead'>Manage and track all user’s combo-panalo bettings</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <Select value={selectParleyStatus} onChange={handleChangeParleyStatus}>
                                        <option value="all">All</option>
                                        <option value="active">Active</option>
                                        <option value="won">Won</option>
                                        <option value="lost">Lost</option>
                                    </Select>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Profile>
                                <DataTable
                                    columns={column3}
                                    data={payleyData}
                                    customStyles={customStyles}
                                    pagination
                                />
                            </Profile>
                        </div>
                        <div class="tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div>
                                    <p className='nav-head mb-0'>{userData?.isAffiliate ? "Affiliate History" : "Refferal History"}</p>
                                    {/* <p className='nav-subhead'>Manage and track use</p> */}
                                </div>
                                {/* <div className='d-flex gap-2'>
                                    <div className='input-container'>
                                        <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                        <input
                                            placeholder='search..'
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiDownloadCloud size={16} color='#333333'/> Export
                                        </button>
                                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                            <li className="dropdown-item">CSV <LuDownload/> </li>
                                            <li className="dropdown-item">EXCEL <LuDownload/></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            <History>
                                <DataTable
                                    columns={column4}
                                    data={referedData}
                                    customStyles={customStyles}
                                    pagination
                                />
                            </History>
                        </div>
                    </div>
                </Nav>
            </Card2>

            <Modal show={deleteConsent?.state}>
                <div className='text-center'>
                    <img alt="" src={image.event.Delete} width="30px"/>
                    <ConsentHeading className='mt-3 mb-0'>Are you sure you want to {deleteConsent?.isSuspend ? "un-suspend" : "suspend"} this user?</ConsentHeading>
                    <Subhead>You can {deleteConsent?.isSuspend ? "suspend" : "un-suspend"} this user.</Subhead>
                    <div className='d-flex align-items-center gap-2 justify-content-center'>     
                        <CancelButton onClick={handleDeleteCancel}>Cancel</CancelButton>
                        <DeleteButton1 
                        onClick={()=>{
                            if(deleteConsent?.isSuspend) handleUnSuspend(deleteConsent?.id)
                            else handleSuspend(deleteConsent?.id)
                        }}>{deleteConsent?.isSuspend ? "un-suspend" : "suspend"}</DeleteButton1>
                    </div> 
                </div>
            </Modal>
        </div>
    );
}