import styled from "styled-components";

export const Card1 = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;

    .table-header, .filter-container{
        display: flex;
        align-items: center;
        margin-top: 15px;
        justify-content: space-between;
    }
    
    .title{
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #333333;
    }

    .subtitle{
        font-size: 14px;
        font-weight: 400;
        // line-height: 28px;
        color: #666666;
    }
    .filter-container{
        gap: 10px;
    }
    
    .input-container{
        background: rgba(245, 245, 245, 1);
        border-radius: 50px;
        padding: 6px 10px;
        width: 180px;
        display: flex;
        align-items: center;
    }

    .input-container > input{
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        ::placeholder{
            color: #666666;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .dropdown-button{
        border: 1px solid rgba(169, 169, 169, 0.55);
        border-radius: 8px;
        color: #666666;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 11px;
        background: white;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .add-button{
        background: rgba(210, 43, 43, 1);
        border-radius: 8px;
        padding: 7px 11px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .nav-tab-contaner{
        border: none !important;
        background: #d3d3d34a;
        width: fit-content;
        border-radius: 50px;
    }
    
    .nav-link{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        transition: 0.5s;
        }
        
    .nav-link.active{
        font-weight: 600;
        border-radius: 50px;    
        transition: 0.5s;
        margin-bottom: 1px;
        background: #D22B2B;
        color: white;
    }

    .rdt_TableCol, .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 13%;
        max-width: 13%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 22% !important;
        max-width: 22% !important;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="7"], .rdt_TableCol[data-column-id="7"]{
        min-width: 10%;
        max-width: 10%;
        padding: 0px !important;
    }

    .export-dropdown-menu{
        border: 1px solid #A9A9A98C !important;
        min-width: 90px !important;
    }

    .export-dropdown-menu > li{
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
            background: rgba(245, 245, 245, 1);
            cursor: pointer;
        }
    }
    
    .filter-dropdown-menu{
        inset: 0px auto auto -145px !important;
        width: 225px;
    }

    .event-time{
        color: #A9A9A9;
        font-size: 14px;
        font-weight: 500;
    }

    .event-title{
        color: #333333;
        font-size: 20px;
        font-weight: 600;
    }
`

export const Tired = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 10%;
        max-width: 10%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 15% !important;
        max-width: 15% !important;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
`

export const Fee = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 20%;
        max-width: 20%;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 20% !important;
        max-width: 20% !important;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 20%;
        max-width: 20%;
        padding: 0px !important;
    }
`