import styled from "styled-components"

export const Card = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .title{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 19.5px;
    }

    .total{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
    }

    .active-line{
        font-size: 14px;
        font-weight: 600;
        color: #A9A9A9;
    }
    .primary{
        color: #037847;
    }
`
export const Card1 = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;

    .table-header, .filter-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .title{
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #333333;
    }
    .subtitle{
        font-size: 14px;
        font-weight: 400;
        // line-height: 28px;
        color: #666666;
    }
    .filter-container{
        gap: 10px;
    }
    
    .input-container{
        background: rgba(245, 245, 245, 1);
        border-radius: 50px;
        padding: 6px 10px;
        width: 180px;
        display: flex;
        align-items: center;
    }

    .input-container > input{
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        ::placeholder{
            color: #666666;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .dropdown-button{
        border: 1px solid rgba(169, 169, 169, 0.55);
        border-radius: 8px;
        color: #666666;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 11px;
        background: white;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .cross-button{
        background: #D22B2B1A;
        border: none;
        height: 25px;
        width: 24px;
        display: flex;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        color: #D22B2B;
        font-size: 13px;
        font-weight: 600;
    }

    .add-button{
        background: rgba(210, 43, 43, 1);
        border-radius: 8px;
        padding: 7px 11px;
        border: none;
        color: white;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .rdt_TableCell{
        padding-left: 10px !important;
        padding-right: 0px !important;
        }
    .rdt_TableCol{
        padding-right: 0px !important;
        padding-left: 10px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 30% !important;
        max-width: 30% !important;
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 15%;
        max-width: 15%;
        // padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 10%;
        max-width: 10%;
        // padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 15%;
        max-width: 15%;
        // padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 13%;
        max-width: 13%;
        // padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 15%;
        max-width: 15%;
        // padding: 0px !important;
    }


    .export-dropdown-menu{
        border: 1px solid #A9A9A98C !important;
        min-width: 90px !important;
    }

    .export-dropdown-menu > li{
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
            background: rgba(245, 245, 245, 1);
            cursor: pointer;
        }
    }
    
    .filter-dropdown-menu{
        inset: 0px auto auto -145px !important;
        width: 225px;
    }

    .filter-title{
        font-size: 13px;
        font-weight: 600;
        color: #333333;
    }

    .filter-menu-title{
        color: #666666;
        font-weight: 500;
        font-size: 13px;
    }

    .event-time{
        color: #A9A9A9;
        font-size: 14px;
        font-weight: 500;
    }

    .event-title{
        color: #333333;
        font-size: 20px;
        font-weight: 600;
    }
`
export const Card3 = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    padding: 15px;

    .event-time{
        color: #A9A9A9;
        font-size: 14px;
        font-weight: 500;
    }
    .card-title{
        color: #333333;
        font-size: 16px;
        font-weight: 500;
    }

    .event-title{
        color: #333333;
        font-size: 18px;
        font-weight: 600;
    }
    
    .balance-div{
        background: #A9A9A91A;
        padding: 5px 12px;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 500;
        color: #666666;
    }

    .input{
        width: 100%;
        border: 1px solid #666666;
        border-radius: 6px;
        padding: 12px 16px;
        color: #A9A9A9;
    }

    .amount-list{
        font-size: 16px;
        margin-top: 10px;
        font-weight: 400;
        display: flex; 
        justify-content: space-between;
        align-items: center;
        color: #666666;
    }

    .rdt_TableCol, .rdt_TableCell{
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 40%;
        max-width: 40%; 
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 15%;
        max-width: 15%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 30%;
        max-width: 30%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="4"]> div{
        width: 100%;
    }

    .option-changer{
        font-size: 14px;
        font-weight: 600;
    }
`
export const ViewButton = styled.button`
    background: rgba(210, 43, 43, 1);
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    padding: 4px 8px;
    border-radius: 8px;
`
export const Active = styled.div`
    border-radius: 16px;
    padding: 6px 9px 6px 15px;
    color: ${props => props.color};
    background: ${props => props.background};
    font-size: 12px;
    width: 105px;
    font-weight: 600;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 7px;   
    
    span{
        display: flex;
        align-items: center;
        gap: 5px;
    }
`
export const Card2 = styled.div`
    background: white;
    border-radius: 12px;
    padding: 32px;
    border: none;

    .input-container{
        background: rgba(245, 245, 245, 1);
        border-radius: 50px;
        padding: 6px 10px;
        width: 180px;
        display: flex;
        align-items: center;
    }

    .input-container > input{
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        }
        
    .react-datepicker-wrapper{
        width: 100%;
    }

    .view-labels{
        color: #666666;
        font-size: 14px;
        font-weight: 500;
    }

    .event-title{
        color: #333333;
        font-size: 20px;
        font-weight: 700;
    }
`
export const Heading = styled.p`
    color: #333333;
    font-size: 18px;
    font-weight: 600;
`
export const Subhead = styled.p`
    color: #666666;
    font-size: 14px;
    font-weight: 400;
`
export const Label = styled.label`
    color: #666666;
    font-size: 14px;
    font-weight: 500;
`
export const Input = styled.input`
    width: 100%;
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 8px 12px;
    outline: none;
    color: #383737;
    ::placeholder{
        color: #666666;
    }
    font-weight: 400;
    font-size: 14px;
`
export const Input1 = styled.input`
    width: 100%;
    border: none;
    border-radius: 8px;
    outline: none;
    color: #383737;
    ::placeholder{color: #A9A9A9;}
    font-weight: 400;
    font-size: 14px;
`
export const Error = styled.p`
    color: red;
    font-size: 12px;
    margin-bottom: 0px;
`
export const InputContainer = styled.div`
    width: 100%;
    background: #a9a9a91f;
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 5px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
`
export const InputContainer1 = styled.div`
    width: 100%;
    background: #a9a9a91f;
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
`
export const Select = styled.select`
    width: 100%;
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 9px 12px;
    background: white;
    outline: none;
    color: #383737;
    ::placeholder{color: #A9A9A9;}
    font-weight: 400;
    font-size: 14px;
`
export const SelectStatus = styled.select`
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 8px 4px;
    background: white;
    width: 100%;
    color: #383737;
    ::placeholder{color: #A9A9A9;}
    font-weight: 400;
    font-size: 14px;
    outline: none;
`
export const Textarea = styled.textarea`
    width: 100%;
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 8px 12px;
    outline: none;
    max-height: 150px;
    min-height: 150px;    
    color: #383737;
    ::placeholder{color: #A9A9A9;}
    font-weight: 400;
    font-size: 14px;
`
export const ContainerButton = styled.button`
    border: none;
    border-radius: 6px;
    background: #D22B2B1A;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    gap: 10px;
    color: #D22B2B;
` 
export const AddButton = styled.button`
    border: 1px solid #6666668C;
    color: #787272;
    border-radius: 53px;
    padding: 8px 14px;
    margin-top: 10px;
    background: white;
    font-size: 13px;
    font-weight: 400;

    &:disabled{
        border: 1px solid #8b85857a;
        color: #78727285;
        border-radius: 53px;
        padding: 8px 14px;
        margin-top: 10px;
        background: #ababab70;
    }
`
export const CreateButton = styled.button`
    background: #D22B2B;
    color: white;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    border-radius: 50px;
    &:disabled{
        background: #d22b2bc4;  
    }
`
export const EditButton = styled.button`
    background: #F5F5F5;    
    padding: 6px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
`
export const ConsentHeading = styled.p`
    color: #333333;
    font-size: 18px;
    font-weight: 600;
`
export const DeleteButton = styled.button`
    background: #D22B2B1A;    
    padding: 6px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    color: #D22B2B;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
`
export const DeleteButton1 = styled.button`
    background: #D22B2B;    
    padding: 13px;
    display: flex;
    width: 45%;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    color: white;
    font-weight: 600;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    outline: none;
`
export const CancelButton  = styled.button`
    background: white;    
    width: 45%;
    padding: 13px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    color: #D22B2B;
    border: 1px solid #D22B2B;
    outline: none;
    border-radius: 8px;
`
export const EditButton1 = styled.button`
    background: #F5F5F5;    
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
`
export const PreviewButton = styled.button`
    border:1px solid #D22B2B;
    background: white;
    color: #D22B2B;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    outline: none;
    padding: 10px 24px;
    border-radius: 50px;
`
export const CreateEventButton = styled.button`
    background: rgba(210, 43, 43, 1);
    border-radius: 8px;
    padding: 7px 11px;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
`
export const ImageContainer = styled.div`
border-radius: 50px
`
export const NoButton = styled.button`
    background: #D22B2B26;
    color: #D22B2B;
    padding: 8px 24px;
    border: none;
    width: 50%;
    border-radius: 4px;
`
export const YesButton = styled.button`
    background: #1C794726;
    color: #1C7947;
    padding: 8px 24px;
    border: none;
    width: 50%;
    border-radius: 4px;
`
export const OptionCard = styled.div`
    background: #A9A9A933;
    padding: 10px;
    margin-top: 15px;
    border: none;
    border-radius: 12px;
`
export const OptionLabel = styled.label`
    font-size: 14px;
    font-weight: 500;
    color: #666666;
`
export const OptionInputContainer = styled.div`
    width: 100%;
    background: white;
    border: 1px solid #A9A9A98C;
    border-radius: 8px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;   
`

export const ViewOption = styled.div`
    .rdt_TableCol, .rdt_TableCell{
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="1"], .rdt_TableCol[data-column-id="1"]{
        min-width: 25%;
        max-width: 25%; 
    }
    .rdt_TableCell[data-column-id="2"], .rdt_TableCol[data-column-id="2"]{
        min-width: 12%;
        max-width: 12%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="3"], .rdt_TableCol[data-column-id="3"]{
        min-width: 12%;
        max-width: 12%;
        padding: 0px !important;
    }
    .rdt_TableCell[data-column-id="4"], .rdt_TableCol[data-column-id="4"]{
        min-width: 13%;
        max-width: 13%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="5"], .rdt_TableCol[data-column-id="5"]{
        min-width: 13%;
        max-width: 13%;
        padding: 0px !important;
    }

    .rdt_TableCell[data-column-id="6"], .rdt_TableCol[data-column-id="6"]{
        min-width: 13%;
        max-width: 13%;
        padding: 0px !important;
    }
`