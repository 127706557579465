import React, { useEffect, useState } from 'react'
import { Active, Card1 } from './style';
import { CiSearch } from 'react-icons/ci';
import { FaCircle, FaPlus } from 'react-icons/fa6';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { api_url } from '../../Utils/api-urls';
import { useDispatch } from 'react-redux';
import { setForm } from '../../Component/slices/eventSlice';
import { FaChevronLeft } from 'react-icons/fa';
import PageLoader from '../../Hooks/pageLoader';
import api from '../../Service/api';
import { image } from '../../Utils/images';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

export default function Template(){
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [templateData, setTemplateData] = useState('');   

    const handleClickEdit=(row)=>{
        dispatch(setForm(row));
        navigate(`/edit-template/${row?._id}`)
    }

    const columns = [
        {
            name: 'Title',
            selector: (row)=> <div>
                {row?.title}
            </div>
        },
        {
            name: 'Category',
            selector: (row)=> <div>
                {row?.category}
            </div>
        },
        {
            name: "No. of Bets",
            selector: (row)=> <div>
                {row?.total_pool ? row?.total_pool : '0'} Bets
            </div>
        },
        {             
            name: "Close Date",
            selector: (row)=> <div>
                {new Date(row?.close_time).toDateString()}
            </div>,
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {
                    row?.status === "FREEZE" ? <Active color="#033278" background= "#ECF0FD"><FaCircle size={7} color='#033278'/>Freeze</Active> : 
                    row?.status === "ACTIVE" ? <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Active</Active> :
                    row?.status === "INCOMING" ? <Active color="#E4B528" background= "#E4B5281A"><FaCircle size={7} color='#E4B528'/>Upcoming</Active> :
                    <Active color="#780303" background= "#FDECEC"><FaCircle size={7} color='#780303'/>Close</Active>
                }
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className=' d-flex gap-3'>
                <img src={image.event.ViewImg} onClick={()=>navigate(`/template-details/${row?._id}`) } />
                <img src={image.event.EditImg} onClick={()=>handleClickEdit(row)} />
                <img src={image.event.DeleteImg}/>
            </div>
        }
    ]

    const handleGetTemplate = async ()=>{
        setLoader(true);
        try{
            let res = await api.get(api_url.template);
            setTemplateData(res?.data?.data)
            setLoader(false);
        } catch(error){
            console.log(error)
            setLoader(false);
        }
    }
    useEffect(()=>{
        handleGetTemplate()
    },[])
    return(
        <div className='container-fluid px-0 py-3'>
            <div className='row m-0'>
                <div className='col-12'>
                    
                    <Card1 className='px-0'>
                        <div className='table-header mb-3 px-2'>
                            <div className='d-flex align-items-center gap-2'>
                                <p className='mb-0 mt-1' onClick={()=>navigate(-1)}>
                                    <FaChevronLeft color="#D22B2B" size={17} />
                                </p>
                                <div>
                                    <p className='title mb-0'>Event Templates</p>
                                    <small className='subtitle'>Edit event template or create new</small>
                                </div>
                            </div>
                            <div className='filter-container'>
                                <div className='input-container'>
                                    <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                    <input 
                                        value={searchInput}
                                        onChange={(e)=>{
                                            setSearchInput(e.target.value)
                                        }}
                                        placeholder='search..'
                                    />
                                </div>
                                <button className='add-button' onClick={()=>{navigate("/create-event")}}>
                                    <FaPlus color='white' strokeWidth={2}/>
                                    Create New Event
                                </button>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={templateData}
                            paginationPerPage={10}
                            pagination
                            customStyles={customStyles}
                            progressPending={loader}
                            progressComponent={<PageLoader/>}
                        />
                    </Card1>
                </div>
            </div>
        </div>
    );
}