import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Card3, CreateEventButton, ImageContainer, Subhead, CreateButton } from './style';
import { FaChevronLeft, FaPlus } from 'react-icons/fa6';
import './event.css';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IoClose } from "react-icons/io5";
import { api_url } from '../../Utils/api-urls';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import api from '../../Service/api';
import { image } from '../../Utils/images';

const YesButton = styled.button`
    background: ${props => props.background};
    font-weight: 600;
    padding: 8px 24px;
    border: none;
    width: 90%;
    border-radius: 4px;
`

const customStyles = {
    headRow: {
      style: {
        fontSize: '14px',
        fontWeight: '400',
        color: "#A9A9A9",
        border: "none",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        color: '#333333',
        fontSize: '16px',
        fontWeight: '500',
        width: 'fit-content',
      },
    }
}

export default function Preview(){

    const navigate = useNavigate()

    const form = useSelector((state)=>state.event.form);
    const[loader, setLoader] = useState(false);

    const columns = [
        {
            name: "Outcome",
            selector: (row)=><div className='d-flex gap-2 align-items-center'>
                <ImageContainer>
                    <img src={row.image} height="30px" width="30px"/>
                </ImageContainer>
                <p className='mb-0'>{row?.title}</p>
            </div>
        },
        {
            name: "Bets",
            selector: (row)=><div>
                <p className='mb-0'>0</p>
            </div>
        },
        {
            name: "Chances",
            selector: (row)=><div style={{ width: 33, height: 33 }}>
                <CircularProgressbar 
                backgroundPadding={0}
                    text={`${row.initialOdds}%`} 
                    value={Number(row.initialOdds)}
                    styles={{
                        text: {
                            fill: `${Number(row.initialOdds) > 50 ? "#1C7947" : "#D22B2B"}`,
                            fontSize: '35px',
                            fontWeight: "500"
                        },
                        trail: {
                            stroke: `${Number(row.initialOdds) > 50 ? "#1C79471F" : "#D22B2B1F"}`,
                        },
                        path: {
                            stroke: `${Number(row.initialOdds) > 50 ? "#1C7947" : "#D22B2B"}`,
                        }
                    }}
                /> 
            </div>
        },
        {
            name: "Action",
            selector: (row)=><div className='d-flex gap-1'>
                <YesButton background={row?.option2}>{row?.option1}</YesButton>
            </div>  
        },
    ];

    const handleSaveAsTemplate = async ()=>{
        setLoader(true);
        const formdata = {
            title: form.title,
            description: form.description,
            category: form.category,
            image: form.image,
            start_time: form.startTime,
            close_time: form.closeTime,
            resolution_time: form.resolutionTime,
            end_time: form.endTime,                                         
            init_odd_duration: form.init_odd_duration,
            max_bet_amount: Number(form.maxBetAmount),
            subCategory: form.payoutLimit,
            eventOptions: form.options.map((data)=>{
                return { 
                    title: data?.title,
                    image: data?.image,
                    initialOdds: Number(data?.initialOdds),
                    text: data?.option1,
                    color: data?.option2
                }
            })
        }

        try {
            let res = await api.post(api_url.template, formdata);
            setLoader(false)
            toast.success(res?.data?.message ? res?.data?.message : "Template created successfully")
        } catch(err) {
            setLoader(false)
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : 'Network issue, please try again after sometime')
        }
    }

    const handleCreate= async()=>{
        const formdata = {
            title: form.title,
            description: form.description,
            category: form.category,
            image: form.image,
            start_time: form.startTime,
            close_time: form.closeTime,
            resolution_time: form.resolutionTime,
            end_time: form.endTime,                                         
            init_odd_duration: form.init_odd_duration,
            max_bet_amount: Number(form.maxBetAmount),
            subCategory: form.payoutLimit,
            eventOptions: form.options.map((data)=>{
                return { 
                    title: data?.title,
                    image: data?.image,
                    initialOdds: Number(data?.initialOdds),
                    text: data?.option1,
                    color: data?.option2
                }
            })
        }
        setLoader(true);
        try {
            let res = await api.post(api_url.event, formdata);
            setLoader(false)
            toast.success(res?.data?.message ? res?.data?.message : "Template created successfully")
        } catch(err) {
            setLoader(false)
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : 'Network issue, please try again after sometime')
        }

    }

    return(
        <div className='p-3'>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-2'>
                    <p className='mb-0' onClick={()=>{ navigate(-1) }}>
                        <FaChevronLeft color="#D22B2B" size={17} />
                    </p>
                    <p className='mb-0' style={{fontSize: '17px', fontWeight: '600', color: '#333333'}}>Event Preview</p>
                </div>
                <CreateEventButton disabled={loader} onClick={()=>handleSaveAsTemplate()}>
                    {
                        loader ? 
                        <Oval height={22} strokeWidth={8} width={22} secondaryColor='grey' color="white"/> : 
                        <>
                            <FaPlus color='white' strokeWidth={2}/>
                            Save as template
                        </>
                    }
                </CreateEventButton>
            </div>
            <div className='row m-0 mt-3'>
                <div className='col-md-8 ps-0'>
                    <Card3>
                        <div className='d-flex mb-3 align-items-center'>
                            <img src={form.image} alt="" width='84px' height="70px"/>
                            <div className='d-flex ps-2 flex-column justify-content-between w-100'>
                                <p className='event-title mb-1'>{form.title}</p>
                                <div className='d-flex justify-content-between align-items-center gap-2'>
                                    <div className='d-flex align-items-center gap-2'>
                                        <img src={image.event.Time}/>
                                        <p className=' mb-0 event-time'>{new Date(form.startTime).toLocaleString()}</p>
                                    </div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <img src={image.event.Comment}/> <span className='event-time'>0</span><img src={image.event.Star}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={form.options}
                            customStyles={customStyles}
                        />
                        <div className='mt-4'>
                            <p className='mb-0' style={{fontSize: '16px', fontWeight: '600', color: '#333333'}}>Description</p>
                            <Subhead>{form.description}</Subhead>
                        </div>
                    </Card3>
                </div>
                <Card3 className='col-md-4' style={{height: 'fit-content'}}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center gap-1' >
                            <ImageContainer>
                                <img src={form.options[0].image} height="40px" width="40px"/>
                            </ImageContainer>
                            <p className='mb-0 event-title'>{form.options[0].title}</p>
                        </div>
                        <p className='mb-0 option-changer'><span style={{color: '#1C7947'}}>Yes/</span><span style={{color: '#D22B2B'}}>No</span></p>
                    </div>  
                    <div className='mt-4'>
                        <p className='card-title mb-0'>Outcome</p>
                        <div className='d-flex gap-3'>
                            <YesButton background={form.options[0].option2} className='w-100'>{form.options[0].option1}</YesButton>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='d-flex justify-content-between mb-3 align-items-center'>
                            <p className='card-title mb-0'>Amount</p>
                            <div className='balance-div'>User Balance</div>
                        </div>
                        <input
                            className='input'
                            placeholder='Enter Betting Amount'
                        />
                        <div className='amount-list'>
                            <p className='mb-0'>Avg Price</p>
                            <p className='mb-0'>--</p>
                        </div>
                        <div className='amount-list'>
                            <p className='mb-0'>Winnings from <span style={{color: '#1C7947'}}>Yes/</span><span style={{color: '#D22B2B'}}>No</span> Bet</p>
                            <p className='mb-0'>--</p>
                        </div>
                        <div className='amount-list'>
                            <p className='mb-0'>Profit</p>
                            <p className='mb-0'>--</p>
                        </div>
                    </div>
                </Card3>
            </div>
            <div className='row m-0 mt-4 d-flex justify-content-around align-items-center'>
                <div className='col-md-6 col-10'>
                    <CreateButton onClick={()=>handleCreate()} disabled={loader} >{loader ? <Oval height={22} strokeWidth={8} width={22} secondaryColor='grey' color="white" /> : "Publish Event"}</CreateButton>
                </div>
            </div>
            <Modal className='edit-action-modal' show={false}>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='mb-1 modal-title'>Edit Action label</p>
                        <p className='modal-subtitle'>You edit & customize the labels for each option."</p>
                    </div>
                    <div className='modal-close'><IoClose size={20} color='#D22B2B' /></div>
                </div>
            </Modal>
        </div>
    );
}