import React, {useEffect, useRef, useState} from 'react'
import { AddButton, ContainerButton, CreateButton, Input1, Label, OptionInputContainer } from '../Event/style';
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import { FaPlus } from 'react-icons/fa6';
import UploadImage from '../../images/uploadImg.png'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Modal } from 'react-bootstrap';

let style ={
    color: "#A9A9A9",
    fontWeight: '400',
    fontSize: '14px'
};

export default function Ads(){
    const [loader, setLoader] = useState(false);
    const [bannerList, setBannerList] = useState([]);

    const fileInputRef1 = useRef([]);
    const handleFileChange1 = (e, index) => {
        const list = [...imagesList];
        list[index].name = e?.target?.files[0]?.name;
        list[index].image = e?.target?.files[0];
        setImageList(list);
    };
    const handleButtonClick1 = (index) => {
        fileInputRef1.current[index]?.click();
    };
    
    const [imagesList, setImageList] = useState([
        { 
            name: '',
            image: "" 
        }
    ]);
    const handleAdd =()=>{
        setImageList([
            ...imagesList,
            {
                name: '',
                image: ""
            }
        ])
    }
    
    const handleRemove = (index)=>{
        const list = [...imagesList];
        list.splice(index,1);
        setImageList(list);
    }
    
    const [addImage, setAddImage] = useState(false);
    const handleShowAdd = ()=>{
        setAddImage(true);
    }
    const handleCloseAdd = ()=>{
        setAddImage(false);
    }

    const handleSubmit = ()=>{
        const formdata = new FormData();

        imagesList?.map((data)=>{
            return formdata.append('images', data?.image)
        })

        setLoader(true);
        axios.post(api_url.uploadBanner,formdata, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                "Content-Type": "multipart/form-data"
            }
        })
        .then((res)=>{
            handleGetBanner()
            handleCloseAdd()
            setLoader(false);
            setImageList([
                { 
                    name: '',
                    image: "" 
                }
            ]);
        })
        .catch((err)=>{
            setLoader(false);
        })
    }
    const handleGetBanner = () => {
        axios.get(api_url.getBanner, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setBannerList(res?.data?.data);
        })
        .catch((err)=>{
        })
    }

    useEffect(()=>{
        handleGetBanner();
    },[])
    return(
        <>
            <div className='header d-flex align-items-center justify-content-between'>
                <div>
                    <p className='title'>Ads</p>
                    <p className='sub-title'>Update Ads Banner for users</p>
                </div>
                <button onClick={handleShowAdd} className='add-button'><FaPlus/> Add Banner</button>
            </div>

            <div>
                {   
                    bannerList?.map((data)=>{
                        return <div> <img src={data}/> </div>
                    })
                }
            </div>

            <Modal show={addImage}>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <div>
                        <p className='mb-0 fw-bold fs-5'>Add Banner</p>
                        <small className='text-secondary'>(format: jpg/jpeg)</small>
                    </div>
                    <p onClick={handleCloseAdd} className='cross-button mb-0'>x</p>
                </div>
                {
                    imagesList?.map((data, index)=>{
                        return <div className='mt-3'>
                            <div className='d-flex align-items-center justify-content-between mb-1 px-1'>
                                <Label>Banner Image {index+1}</Label>
                                <MdOutlineDeleteOutline onClick={()=>handleRemove(index)} size={20}/>
                            </div>
                            <div className='d-flex gap-2'>
                                <OptionInputContainer className='p-1'>
                                    <input
                                        placeholder='OptionImage.JPG'
                                        className='border-0 bg-transparent'
                                        style={{...style}}
                                        disabled
                                        name='image'
                                        id='image'
                                        value={data?.name}
                                    />
                                    <Input1
                                        className='d-none'
                                        type='file'
                                        name='image'
                                        id={`image-${index}`}
                                        ref={(el) => fileInputRef1.current[index] = el} 
                                        onChange={(e)=>handleFileChange1(e, index)}
                                    />
                                    <ContainerButton style={{fontSize: '15px'}} type='button' onClick={()=>handleButtonClick1(index)} className="custom-button">Browse Image <img src={UploadImage} alt=""/> </ContainerButton>
                                </OptionInputContainer>
                            </div>
                        </div>
                    })
                }
                <AddButton type='button' className='mt-2' onClick={handleAdd}>
                    <FaPlus color='#787272'/> Add More Banners                                 
                </AddButton>  

                <CreateButton onClick={()=>handleSubmit()} className='mt-2'>Add Banners</CreateButton>
            </Modal>
        </>
    );
}