import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";
import { api_url } from "../../Utils/api-urls";
import moment from "moment";

export const getReqList = createAsyncThunk('req/getReqList', async (status)=>{
    try{
        let resp = await api.get(api_url.req_list(status));

        console.log(resp, 'resp resp ')
        const data = resp?.data?.Details;
        return data;
    } catch(error){
        return error;
    }
})


export const getAllAffilates = createAsyncThunk('req/getAllAffilates', async (page)=>{
    try{
        let resp = await api.get(api_url.getAllAffilates(page));
        const data = resp?.data?.data;

        return data;
        // const data = resp?.data?.data?.history?.map((data)=>{
        //     return {
        //         ...data,
        //         createdAt: moment(data?.createdAt).format('DD-MM-YYYY')
        //     }
        // });
        // console.log(data);
        // return {
        //     ...resp?.data?.data,
        //     history: data
        // };
    } catch(error){
        return error;
    }
})

const affiliateSlice = createSlice({
    name: "affiliateSlice",
    initialState: {
        items: [],
        affilateList: [],
        form: {
            email: "",
            username: "",
            password: "",
            city: "",
            mobile: "",
            province: "",
            dateOfBirth: ""
        },
        status: "idle",
        error: null
    },
    reducers: {
        changeForm: (state, action)=>{
            const { name, value } = action.payload;
            state.form[name] = value;
        },
        resetForm: (state)=>{
            state.form = {
                email: "",
                username: "",
                password: "",
                city: "",
                mobile: "",
                province: "",
                dateOfBirth: ""
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getReqList.pending, (state)=>{
            state.status = 'pending';
        })
        .addCase(getReqList.fulfilled, (state, action) => {
            state.status = 'success';
            state.items = action.payload;
        })
        .addCase(getReqList.rejected, (state, action)=>{
            state.status = 'reject';

        })

        builder
        .addCase(getAllAffilates.pending, (state)=>{
            state.status = 'pending';
        })
        .addCase(getAllAffilates.fulfilled, (state, action) => {
            state.status = 'success';
            state.affilateList = action.payload;
        })
        .addCase(getAllAffilates.rejected, (state, action)=>{
            state.status = 'reject';

        })
    }
})

export default affiliateSlice.reducer;
export const { changeForm, resetForm } = affiliateSlice.actions;