import React from 'react'
import CardImg1 from '../../images/finance_card1.png'
import CardImg2 from '../../images/finance_card2.png'
import CardImg3 from '../../images/finance_card3.png'
import CardImg4 from '../../images/finance_card4.png'
import { Chart, LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, ArcElement } from 'chart.js';
import { Card } from './style'
import { Button, Div1, Div2, Select, Title } from '../Home/style'
import { Line, Doughnut } from 'react-chartjs-2'
import { FaCircle } from 'react-icons/fa'
import Transaction from './transaction'
import Withdrawal from './withdrawal'
     
Chart.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, ArcElement);

export default function Finance(){

    const data = [
        {
            img: <img src={CardImg1} width="40px"/>,
            heading: "Total Platform Earning",
            peso: "₱ 56567",
            status: "up",
            percent: "7.2"
        },
        {
            img: <img src={CardImg2} width="40px"/>,
            heading: "Total Payout",
            peso: "₱ 47578",
            status: "down",
            percent: "5.2"
        },
        {
            img: <img src={CardImg3} width="40px"/>,
            heading: "Total User Balance",
            peso: "₱ 47578",
            status: "down",
            percent: "5.2"
        },
        {
            img: <img src={CardImg4} width="40px"/>,
            heading: "Total User Bonus",
            peso: "₱ 47578",
            status: "down",
            percent: "5.2"
        }
    ]

    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const lineData = {
        labels: labels,
        datasets: [
        {
            label: "Profit",
            backgroundColor: "#6FD195",
            borderColor: "#6FD195",
            data: [22,65,23,81,23,76,23,13,75,87, 43,76],
            tension: 0.6,
        },
        {
            label: "Payout",
            backgroundColor: "#7086FD",
            borderColor: "#7086FD",
            data: [43,32,32,66,34,87,23,87,34,43, 34,43], 
            tension: 0.6,
        }
        ],
    };
    const lineOption = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    font: {
                        size: 14,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                    color: "red",
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                titleFont: { size: 16 },
                bodyFont: { size: 14 },
                bodyColor: "#fff",
                padding: 10,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#666',
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.5)',                                                                                
                    lineWidth: 1,
                    drawBorder: false,
                },
                ticks: {
                    color: '#666',
                    font: {
                        size: 12,
                    },
                    stepSize: 20,
                },
            },
        },
        elements: {
            line: {
                borderWidth: 3,
            },
            point: {
                radius: 3,
                hoverRadius: 5
            },
        },
        animation: {
            duration: 1000,
            easing: 'easeOutBounce',
        },
    };
      
    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    font: {
                        size: 14,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                    color: "red",
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                titleFont: { size: 16 },
                bodyFont: { size: 14 },
                bodyColor: "#fff",
                padding: 10,
            },
        },
        legend: {
          display: false,
          position: "right"
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }

    };
    const donutData = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["a", "b", "c"],
        datasets: [
          {
            data: [300, 160, 40],
            backgroundColor: ["#7086FD", "#6FD195", "#FFAE4C"],
            hoverBackgroundColor: ["#7086FD", "#6FD195", "#FFAE4C"]
          }
        ]
    } 

    return( 
        <div className='p-3'>
            <div className='row m-0'>
                {
                    data?.map((data)=>{
                        return <div className='col-md-3'>
                            <Card>
                                {data?.img}
                                <p className='title mt-2 mb-0'>{data?.heading}</p>
                                <p className='total mb-2'>{data?.peso}</p>
                                <p className='mb-0 active-line'><span className={data?.status === "up" ? 'primary': 'danger'}>{data?.percent}%</span> from last month </p>
                               </Card>
                        </div>
                    })
                }
            </div>

            <div className="row m-0 mt-4 mb-4">
                <div className='col-md-7 ps-0'>
                    <Div1 className='card'>
                        <div className='d-flex pt-3 pb-2 justify-content-between align-items-center'>
                            <Title className='mb-0 ps-2'>Revenue</Title>
                            <div className='d-flex gap-2 align-items-center'>
                                <Button>
                                    <span className='heading'>Revenue: </span>
                                    <span className='value'>$34,565</span>
                                </Button>
                                <Select>
                                    <option>last week</option>
                                    <option>last month</option>
                                    <option>last year</option>
                                </Select>
                            </div>
                        </div>
                        <Line data={lineData} options={lineOption} />
                    </Div1>
                </div>
                <Div2 className='col-md-5 bg-white d-flex flex-column justify-content-around'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Title className='mb-0 ps-2'>Operational Overview</Title>
                        <Select>
                            <option value="last week">last week</option>
                            <option value="last month">last month</option>
                            <option value="last year">last year</option>
                        </Select>
                    </div>
                    <div style={{height: "270px", display: "flex", justifyContent: "center"}}>
                        <Doughnut data={donutData} options={options}/>
                    </div>
                    {/* <div className=''>

                    </div> */}
                    <div className='d-flex flex-wrap mt-2 justify-content-around align-items-center'>
                        <div className='d-flex align-items-center gap-1'> <FaCircle size={12} color="#7086FD"/> <small>Total Bet Money</small> </div>
                        <div className='d-flex align-items-center gap-1'> <FaCircle size={12} color="#6FD195"/> <small>Platform Earnings</small> </div>
                        <div className='d-flex align-items-center gap-1'> <FaCircle size={12} color="#FFAE4C"/> <small>User Wallet’s Money</small> </div>
                    </div>
                </Div2>
            </div>

            <div className='row m-0'>
                <Card>
                    <nav>
                        <div className="nav nav-tab-contaner nav-tabs" id="nav-tab" role="tablist">
                            <button  
                                className="nav-link active" 
                                id="nav-withdrawal-tab" 
                                data-bs-toggle="tab" 
                                data-bs-target="#nav-withdrawal" 
                                type="button" role="tab" 
                                aria-controls="nav-withdrawal" 
                                aria-selected="true"
                            >
                                Withdrawal Requests
                            </button>   
                            <button 
                                className="nav-link"
                                id="nav-transaction-tab"   
                                data-bs-toggle="tab" 
                                data-bs-target="#nav-transaction" 
                                type="button" role="tab" 
                                aria-controls="nav-transaction" 
                                aria-selected="false"
                            >
                                Transaction History
                            </button>
                        </div>
                    </nav>                
                    <div className='tab-content' id='nav-tabContent'>
                        <div 
                            className="tab-pane fade show active"  
                            id="nav-withdrawal" 
                            role="tabpanel" 
                            aria-labelledby="nav-withdrawal-tab"
                        > <Withdrawal/> </div>
                        <div 
                            className="tab-pane fade show" 
                            id="nav-transaction" 
                            role="tabpanel" 
                            aria-labelledby="nav-transaction-tab"
                        > <Transaction/> </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}