import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { CiSearch } from "react-icons/ci";
import { Card, Card1, Active, Heading, Subhead, CancelButton, DeleteButton1 } from './style';
import { IoFilter } from "react-icons/io5";
import { FiDownloadCloud } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getEventList, setForm } from '../../Component/slices/eventSlice';
import { api_url } from '../../Utils/api-urls';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import PageLoader from '../../Hooks/pageLoader';
import { handleDownloadDataInCsv, handleDownloadDataInExel } from '../../Service/exportdata';
import api from '../../Service/api';
import { image } from '../../Utils/images';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '13.5px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

const filterData = {
    categoryData: [
        {
            value: "all",
            label: "All"
        },
        {
            value: "sports",
            label: "Sports"
        },
        {
            value: "politics",
            label: "Politics"
        },
        {
            value: "entertainment",
            label: "Entertainment"
        },
        {
            value: "weather",
            label: "Weather"
        }
    ],
    statusData: [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Closes",
            value: "closes"
        },
        {
            label: "Freeze",
            value: "freeze"
        }
    ]
}
export default function Event(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const [loader, setLoader] = useState(false);

    const [deleteConsent, setDeleteConsent] = useState({
        state: false,
        id: ''
    });
    const handleDelete = (id)=>{
        setDeleteConsent({
            state: true,
            id
        })
    };
    const handleDeleteCancel = ()=>{
        setDeleteConsent({
            state: false,
            id: ""
        })
    };

    const eventData = useSelector((state)=> state?.event?.items);

    const handleGetEvent = async () => {
        setLoader(true);
        try{
            let resp = await dispatch(getEventList());
            if(resp) setLoader(false);
        } catch(error){
            setLoader(false);
        }
    }

    useEffect(()=>{
        handleGetEvent();
    },[])
    
    const columns = [
        {
            name: 'Title',
            selector: (row)=> <div>
                {row?.title}
            </div>
        },
        {
            name: 'Category',
            selector: (row)=> <div>
                {row?.category}
            </div>
        },
        {
            name: "Amount",
            selector: (row)=> <div>
                ₱{row?.total_pool ? row?.total_pool : '0'}
            </div>
        },
        {
            name: "Close Date",
            selector: (row)=> <div>
                {new Date(row?.close_time).toDateString()}
            </div>,
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {
                    row?.status === "FREEZE" ? <Active color="#033278" background= "#ECF0FD"><FaCircle size={7} color='#033278'/>Freeze</Active> : 
                    row?.status === "ACTIVE" ? <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Active</Active> :
                    row?.status === "INCOMING" ? <Active color="#E4B528" background= "#E4B5281A"><FaCircle size={7} color='#E4B528'/>Upcoming</Active> :
                    <Active color="#780303" background= "#FDECEC"><FaCircle size={7} color='#780303'/>Close</Active>
                }
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className=' d-flex gap-3'>
                <img src={image.event.ViewImg} 
                    onClick={()=> navigate(`/event-details/${row?._id}`)}
                />
                <img src={image.event.EditImg} onClick={()=>handleClickEdit(row)}/>
                <img src={image.event.DeleteImg} onClick={()=> handleDelete(row?._id)}/>
            </div>
        }
    ]

    const handleClickEdit=(data)=>{
        dispatch(setForm(data));
        navigate(`/edit-event/${data?._id}`)
    }

    const handleDownloadinEXL = ()=>{
        const data = eventData.map((data) => {
            return {
                title: data?.title,
                description: data?.description,
                category: data?.category,
                sub_category: data?.subCategory,
                amount: data?.total_pool,
                image: data?.image,
                startTime: moment(data?.start_time).format("DD-MM-YYYY"),
                closeTime: moment(data?.close_time).format("DD-MM-YYYY"),
                resolutionTime: moment(data?.resolution_time).format("DD-MM-YYYY"),
                endTime: moment(data?.end_time).format("DD-MM-YYYY"),
                maxBetAmount: data?.max_bet_amount,
                payoutLimit: data?.payout_limit,
                options: `${data?.options?.length} Options`,
            }
        })
        handleDownloadDataInExel(data);
    }

    const handleDownloadinCSV = ()=>{
        const data = eventData.map((data) => {
            return {
                title: data?.title,
                description: data?.description,
                category: data?.category,
                sub_category: data?.subCategory,
                amount: data?.total_pool,
                image: data?.image,
                startTime: moment(data?.start_time).format("DD-MM-YYYY"),
                closeTime: moment(data?.close_time).format("DD-MM-YYYY"),
                resolutionTime: moment(data?.resolution_time).format("DD-MM-YYYY"),
                endTime: moment(data?.end_time).format("DD-MM-YYYY"),
                maxBetAmount: data?.max_bet_amount,
                payoutLimit: data?.payout_limit,
                options: `${data?.options?.length} Options`,
            }
        })

        handleDownloadDataInCsv(data);
    }

    const handleDeleteConfirm = async (id)=>{
        setLoader(true);
        try{
            let res = await api.delete(api_url.getSingleEvent(id));
            toast.success(res?.data?.message ? res?.data?.message : "Your event is delete successfully");
            dispatch(getEventList())
            handleDeleteCancel();
            setLoader(false);
        } catch(err){
            toast.success(err?.response?.data?.message ? err?.response?.data?.message : "Your event is delete successfully");
            setLoader(false);
        }
    }
    
    const data = [
        {
            image: <img src={image.event.TotalEvent} width="60px"/>,
            title: "Events",
            total: useSelector((state)=> state?.event?.total_event) || 0,
            active: "0",
        },
        {
            image: <img src={image.event.TotalBet} width="60px"/>,
            title: "Bets",
            total:  useSelector((state)=> state?.event?.total_bet) || 0,
            active: "0",
        },
    ];

    const searchfilter = eventData && eventData?.filter((data)=>{
        return data?.title.toLowerCase().includes(searchInput.toLowerCase()) || data?.category.toLowerCase().includes(searchInput.toLowerCase()) ||
            data?.close_time.toLowerCase().includes(searchInput.toLowerCase())
    })
    
    return(
        <div className='container-fluid px-0 py-3'>
            <div className='row m-0 d-flex flex-wrap justify-content-center justify-content-md-between'>
                { 
                    data?.map((data, index)=>{
                        return <div key={index} className="col-md-6 mb-3 col-10">
                            <Card>  
                                <div>
                                    <p className='mb-0 title'>Total {data?.title}</p>
                                    <p className='total mb-2'>{data?.total}</p>
                                    <p className='mb-0 active-line'><span className='primary'>{data?.active}</span> Active {data?.title}</p>
                                </div>
                                <div>{data?.image}</div>
                            </Card>
                        </div>
                    })
                }
            </div>
            <div className='row m-0'>
                <div className='col-12'>
                    <Card1 className='px-0'>
                        <div className='table-header mb-3 px-2'>
                            <div>
                                <p className='title mb-0'>Events</p>
                                <small className='subtitle'>Manage and track all events</small>
                            </div>
                            <div className='filter-container'>
                                <div className='input-container'>
                                    <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                    <input 
                                        value={searchInput}
                                        onChange={(e)=>{
                                            setSearchInput(e.target.value)
                                        }}
                                        placeholder='search..'
                                    />
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <IoFilter size={16} color='#333333'/> Filter
                                    </button>
                                    <ul className="dropdown-menu filter-dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <p className='mb-0 filter-title'>Category</p>
                                            <p className='mb-0 cross-button'>X</p>
                                        </div>
                                        <div>
                                            {
                                                filterData?.categoryData?.map((data, index)=>{
                                                    return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                        <input type='checkbox' />
                                                        <p className='mb-0 filter-menu-title'>{data?.label}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <p className='mb-0 filter-title mt-3'>Status</p>
                                        <div>
                                            {
                                                filterData?.statusData?.map((data, index)=>{
                                                    return <div key={index} className='d-flex gap-2 ps-2 align-items-center'>
                                                        <input type='radio'  name={data?.value} id={data?.value} />
                                                        <p className='mb-0 filter-menu-title'>{data?.label}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mt-3'>
                                            <button className='add-button mt-2'> Apply Filter </button>
                                        </div>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li onClick={handleDownloadinCSV} className="dropdown-item">CSV <LuDownload/> </li>
                                        <li onClick={handleDownloadinEXL} className="dropdown-item">EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                                <button className='add-button' onClick={()=>{navigate("/create-event")}}>
                                    <FaPlus color='white' strokeWidth={2}/>
                                    Create New Event
                                </button>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={searchfilter}
                            paginationPerPage={10}
                            pagination
                            customStyles={customStyles}
                            progressPending={loader}
                            progressComponent={<PageLoader />}
                        />
                    </Card1>
                </div>
            </div>

            <Modal show={deleteConsent?.state}>
                <div className='text-center'>
                    <img src={image.event.Delete} width="30px"/>
                    <Heading className='mt-3 mb-0'>Are you sure you want to delete this event?</Heading>
                    <Subhead>This action cannot be undone, and the event will be permanently removed from your list.</Subhead>
                    <div className='d-flex align-items-center gap-2 justify-content-center'>     
                        <CancelButton onClick={handleDeleteCancel}>Cancel</CancelButton>
                        <DeleteButton1 onClick={()=>handleDeleteConfirm(deleteConsent?.id)}>Delete</DeleteButton1>
                    </div> 
                </div>
            </Modal>
        </div>
    );
}