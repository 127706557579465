import React, { useEffect, useState } from 'react'
import { Card1 } from './style';
import { CiSearch } from 'react-icons/ci';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import { FaCircle, FaPlus } from 'react-icons/fa';
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import { Button, Input, InputContainer, Label, SubHeading, Heading } from '../Auth/styled';
import { Oval } from 'react-loader-spinner';
import { Active, CancelButton, ContainerButton, DeleteButton1, Subhead } from '../Event/style';
import DatePicker from 'react-datepicker';
import StartTime from '../../images/startTime.png';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment/moment';
import SuspendImg from '../../images/suspend_user.png'
import * as XLSX from 'xlsx';   
import Delete from '../../images/delete.png';
import ViewImg from '../../images/eye-view.png'
import { FaRegCopy } from "react-icons/fa";
import { SiTicktick } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
import PageLoader from '../../Hooks/pageLoader';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        background:'#fcfcfd',
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

let style ={
    color: "#A9A9A9",
    fontWeight: '400',
    fontSize: '14px'
};

export default function Coupen(){
    const [searchInput, setSearchInput] = useState('');
    const [coupenList, setCoupenList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const handleShowAdd = ()=>{
        setShowAdd(true);
    }
    const handleCloseAdd = ()=>{
        setShowAdd(false);
    }

    const [info, setInfo] = useState(false);
    const [infoDetail, setInfoDetail] = useState();
    const handleShowInfo=(data)=>{
        setInfo(true);
        setInfoDetail(data)
    }
    const handleCloseInfo=()=>{
        setInfo(false);
        setInfoDetail()
    }

    const [deleteConsent, setDeleteConsent] = useState({
        state: false,
        id: ''
    })
    const handleDelete = (id)=>{
        setDeleteConsent({
            state: true,
            id
        })
    }
    const handleDeleteCancel = ()=>{
        setDeleteConsent({
            state: false,
            id: ""
        })
    }

    const formik = useFormik({
        initialValues: {
            amount: "",
            isExpire: false,
            expireDate: ""
        },
        validationSchema: Yup.object().shape({
            amount: Yup.string().required("amount is required")
        }),
        onSubmit: (values)=>{
            axios.post(api_url.createCoupen,{
                amount: values.amount,
                expireAble: values.isExpire,
                ...( values.isExpire && { expireAt: values.expireDate})
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{
                handleCloseAdd();
                handleGetCoupen();
                toast.success(res?.data?.message ? res?.data?.message : "Coupon code is created successfully")
            })
            .catch((err)=>{
                toast.error(err?.response?.data?.message ? err?.response?.data?.message :"some error occured")
            })
        }   
    })

    const handleInputChange = (date)=>{
        const value = new Date(date).toISOString();
        formik.setValues({
            ...formik.values,
            expireDate: value
        })
    }

    const handleGetCoupen=()=>{
        axios.get(api_url.getCoupen,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setCoupenList(res?.data?.data);
        })
        .catch((err)=>{
        })
    }

    const handleDownloadinEXL = ()=>{
        const data = coupenList.map((data)=>{
            return {
                code: data?.code, 
                amount: `₱${data?.amount}`,
                is_expired: data?.expireAble ? moment(data?.expireAt).format("DD-MM-YYYY") : "Non-expirable",
                status: data?.status,
                be_used: data?.used ? "Used" : "Not used",
                reedem_at: data?.redeem_coupon?.redeemAt ? moment(data?.redeem_coupon?.redeemAt).format("DD-MM-YYYY") : "null",
                reedem_by: data?.redeem_coupon?.user?.username ? data?.redeem_coupon?.user?.username : "null"
            }
        })
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "coupon.xlsx");
    }

    const handleDownloadinCSV = ()=>{
        const data = coupenList.map((data)=>{
            return {
                code: data?.code, 
                amount: `₱${data?.amount}`,
                is_expired: data?.expireAble ? moment(data?.expireAt).format("DD-MM-YYYY") : "Non-expirable",
                status: data?.status,
                be_used: data?.used ? "Used" : "Not used",
                reedem_at: data?.redeem_coupon?.redeemAt ? moment(data?.redeem_coupon?.redeemAt).format("DD-MM-YYYY") : "null",
                reedem_by: data?.redeem_coupon?.user?.username ? data?.redeem_coupon?.user?.username : "null"
            }
        })

        const worksheet = XLSX.utils.json_to_sheet(data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "coupon.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleSuspend = (id)=>{
        axios.patch(api_url.disableCoupen(id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            handleGetCoupen();
            toast.success(res?.data?.message ? res?.data?.message : "Coupon declined successfully")
        })
        .catch((err)=>{
            toast.error(err?.response?.data?.message ? err?.response?.data?.message :"some error occured")
        })
    }

    const columns = [
        {
            name: "Code",
            selector: (row)=> <div>
                {row?.code}
            </div>
        },
        {
            name: "Amount",
            selector: (row)=> <div>
                ₱{row?.amount}
            </div>
        },
        {
            name: "Expire At",
            selector: (row)=> <div>
                {row?.expireAt ? moment(row?.expireAt).format("DD-MM-YYYY") : "non expirable"}
            </div>
        },
        {
            name: "Created At",
            selector: (row)=> <div>
                {moment(row?.createdAt).format("DD-MM-YYYY")}
            </div>
        },
        {
            name: "is Used",
            selector: (row)=> <div>
                {row?.used ? "Yes" : "No"}
            </div>
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {
                    row?.status === "DISABLE" ? <Active color="#033278" background= "#ECF0FD"><FaCircle size={7} color='#033278'/>DISABLE</Active> : 
                    row?.status === "ACTIVE" ? <Active color="#037847" background= "#ECFDF3"><FaCircle size={7} color='#037847'/>Active</Active> :
                    <Active color="#780303" background= "#FDECEC"><FaCircle size={7} color='#780303'/>EXPIRE</Active>
                }
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className='d-flex gap-2'>
                <img src={ViewImg} onClick={()=>handleShowInfo(row)}/>
                {
                    row?.status === "ACTIVE" ? <img src={SuspendImg} style={{cursor: 'pointer'}} onClick={()=>handleDelete(row?._id) }/> :
                    <img src={SuspendImg} disabled/>
                }
            </div>
        },
    ]

    useEffect(()=>{
        handleGetCoupen()
    },[])

    const handleCopy = (code)=>{
        setIsCopied(true);
        navigator?.clipboard?.writeText(code);
    }

    return(
        <p className='p-3'>
            <div className='row m-0'>
                <div className='col-12'>
                    <Card1 className='px-0'>
                        <div className='table-header mb-3 px-3'>
                            <div>
                                <p className='title mb-0'>Coupon-Code Management</p>
                                <small className='subtitle'>Manage and track all coupons, you built</small>
                            </div>
                            <div className='filter-container'>
                                <div className='input-container'>
                                    <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
                                    <input 
                                        value={searchInput}
                                        onChange={(e)=>{ setSearchInput(e.target.value) }}
                                        placeholder='Search..'
                                    />
                                </div>
                                <div className="dropdown">
                                    <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiDownloadCloud size={16} color='#333333'/> Export
                                    </button>
                                    <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li 
                                        onClick={handleDownloadinCSV}
                                        className="dropdown-item"> CSV <LuDownload/> </li>
                                        <li 
                                        onClick={handleDownloadinEXL}
                                        className="dropdown-item"> EXCEL <LuDownload/></li>
                                    </ul>
                                </div>
                                <button className='add-button' onClick={handleShowAdd} >
                                    <FaPlus color='white' strokeWidth={2}/>
                                    Create New Coupon
                                </button>
                            </div>
                        </div>
                        { coupenList?.length > 0 ? <DataTable
                            columns={columns}
                            data={coupenList}
                            paginationPerPage={10}
                            pagination
                            customStyles={customStyles}
                        /> : <PageLoader/> }
                    </Card1>
                </div>
                
            </div>

            <Modal show={showAdd}>
                <div>
                    <div className='d-flex justify-content-between'>
                        <div style={{width: "95%"}} className='text-center'>
                            <Heading> Create new coupon </Heading>
                            <SubHeading>Fill the details and create new coupon</SubHeading>
                        </div>
                        <div onClick={handleCloseAdd} className='cross-button'>X</div>
                    </div>
                    <form onSubmit={formik.handleSubmit} className=' mt-3'>

                        <div className='mb-3'>
                            <Label htmlFor='amount'>Amount</Label>
                            <InputContainer>
                                <Input
                                    value={formik.values.amount}
                                    onChange={formik.handleChange}
                                    id='amount'
                                    name='amount'
                                    placeholder='Enter amount'
                                />
                            </InputContainer>
                        </div>
                        <div className='mb-3 d-flex align-items-center gap-1'>
                            <input
                                type='checkbox'
                                name='isExpire'
                                id='isExpire'
                                value={formik.values.isExpire}
                                onChange={formik.handleChange}
                            />
                            <Label htmlFor='isExpire'> Can Exprire</Label>
                        </div>
                        { formik.values.isExpire && <div className='mb-3'>
                            <Label htmlFor='expireDate'>Expire At</Label>
                            <div className="custom-date-picker">
                                <DatePicker
                                    selected={formik.values.expireDate ? new Date(formik.values.expireDate) : ''}
                                    onChange={(date) => handleInputChange(date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    placeholderText="Choose Time"
                                    customInput={
                                        <InputContainer>
                                            <input
                                                className='border-0 bg-transparent'
                                                disabled
                                                style={style}
                                                placeholder="Choose Time"
                                                value={formik.values.expireDate ? new Date(formik.values.expireDate).toLocaleString() : ''}
                                            />
                                            <ContainerButton type='button' className='p-1'><img alt="" src={StartTime}/></ContainerButton>           
                                        </InputContainer>
                                    }
                                />
                            </div>
                        </div> }
                        <Button disabled={loader} type='submit'>{loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>}Create Coupon</Button>
                    </form>
                </div>
            </Modal>

            <Modal show={deleteConsent?.state}>
                <div className='text-center'>
                    <img src={Delete} width="30px"/>
                    <Heading className='mt-3 mb-0'>Are you sure to disable this Coupon Code</Heading>
                    <Subhead>This action cannot be undone, and the coupon will be permanently disabled from your list.</Subhead>
                    <div className='d-flex align-items-center gap-2 justify-content-center'>     
                        <CancelButton onClick={handleDeleteCancel}>Cancel</CancelButton>
                        <DeleteButton1 onClick={()=>handleSuspend(deleteConsent?.id)}>Disable</DeleteButton1>
                    </div> 
                </div>
            </Modal>

            <Modal show={info}>
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <p className='fw-bold fs-4 mb-0'> View coupon </p>
                            <SubHeading>View this coupon code information</SubHeading>
                        </div>
                        <div onClick={handleCloseInfo} className='cross-button'>X</div>
                    </div>
                    { infoDetail && <div className='mt-3'>
                        <div>
                            <Label>Coupon Code:</Label>
                            <div className='d-flex gap-2'>
                                <p>{infoDetail?.code}</p>
                                {
                                    isCopied ? <SiTicktick color='green' onMouseLeave={()=> setIsCopied(false)}/> : 
                                    <FaRegCopy onClick={()=>handleCopy(infoDetail?.code)}/>
                                }
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{width: "50%"}}>
                                <Label>Amount:</Label>
                                <p>₱{infoDetail?.amount}</p>      
                            </div>
                            <div style={{width: "50%"}}>
                                <Label>Expired On:</Label>
                                <p>{infoDetail?.expireAble ? moment(infoDetail?.expireAt).format("DD-MM-YYYY") : "Non-expirable"}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{width: "50%"}}>
                                <Label>Status:</Label>
                                <p>{infoDetail?.status}</p>
                            </div>
                            <div style={{width: "50%"}}>
                                <Label>be Used:</Label>
                                <p>{infoDetail?.used ? "Used" : "Not used"}</p>
                            </div>
                        </div>
                        { infoDetail?.used && <div className='d-flex justify-content-between align-items-center'>
                            <div style={{width: "50%"}}>
                                <Label>Reedem Date:</Label>
                                <p className='mb-0'>{moment(infoDetail?.redeem_coupon?.redeemAt).format("DD-MM-YYYY")}</p>
                            </div>
                            <div style={{width: "50%"}}>
                                <Label>Reedemed By:</Label>
                                <div className='d-flex gap-2 align-items-center'>
                                    <p className='mb-0'>{infoDetail?.redeem_coupon?.user?.username}</p>
                                    <a target='_blank' href={`/user-details/${infoDetail?.redeem_coupon?.user?._id}`} > <FaExternalLinkAlt size={13} color='rgba(210, 43, 43, 1)'/> </a>
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            </Modal>
        </p>
    );
}