import React, { useEffect, useState } from 'react'
import { Fee } from './style';
import DataTable from 'react-data-table-component';
import { api_url } from '../../Utils/api-urls';
import axios from 'axios';
import moment from 'moment/moment';
import { Input, Label, ViewButton } from '../Event/style';
import { Modal } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import PageLoader from '../../Hooks/pageLoader';

export default function FeeMgmt({customStyles}){

    const [loader, setLoader] = useState(false);
    const [newFee, setNewFee] = useState('');
    const [canUpdate, setCanUpdate] = useState(false);
    const handleShowUpdate = (data) =>{
        setCanUpdate(true);
        setNewFee(data?.fee)
    }
    const handleCloseUpdate = () =>{
        setCanUpdate(false);
    }

    const handleUpdateFee =()=>{
        setLoader(true)
        axios.patch(api_url.getFee,{
            fee: newFee
        },{
            headers: {  
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setLoader(false);
            handleCloseUpdate();          
            handleGetFee()
        })
        .catch((err)=>{
            setLoader(false);            
        })
    }

    const [feeDetail, setFeeDetail] = useState();
    const handleGetFee = ()=> {
        axios.get(api_url.getFee,{
            headers: {                     
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            const objj = [];
            objj.push(res?.data?.data);
            setFeeDetail(objj);
        })
        .catch((err)=>{
        })
    }

    useEffect(()=>{
        handleGetFee();
    },[])

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => <div>
                {index+1}.
            </div>
        },
        {
            name: "Fee (%)",
            selector: (row)=> <div>
                {row?.fee}%
            </div>
        },
        {
            name: "Create By",
            selector: (row)=> <div>
                {row?.createdAt ? moment(row?.createdAt).format("DD-MM-YYYY") : "05/11/2024"}
            </div>
        },
        {
            name: "Updated By",
            selector: (row)=> <div>
                {row?.updatedAt ? moment(row?.updatedAt).format("DD-MM-YYYY") : "05/11/2024"}
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton onClick={()=>handleShowUpdate(row)}>Update</ViewButton>
            </div>
        }
    ];
    
    return(
        <>
            <div className='table-header mb-3 px-3'>
                <div>
                    <p className='title mb-0'>Fee Management</p>
                    <small className='subtitle'>Manage and track all the Fee structure</small>
                </div>
            </div>
            <Fee>
                { feeDetail?.length > 0 ? <DataTable
                    customStyles={customStyles}
                    data={feeDetail}
                    columns={columns}
                /> : <PageLoader/> }
            </Fee>

            <Modal show={canUpdate}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='modal-head'> 
                        <p className="heading mb-0">Update Tier</p>
                        <p className='sub-heading'>update the tiered-shift cap</p>
                    </div>
                    <p onClick={handleCloseUpdate} className='cross-button'>x</p>
                </div>
                <div>
                    <Label>Fee (%)</Label>
                    <Input
                        value={newFee}
                        onChange={(e)=>setNewFee(e?.target?.value)}
                        placeholder='Enter Fee'
                    />
                </div>
                <button 
                    onClick={handleUpdateFee} 
                    disabled={loader} 
                    className='mt-2 d-flex justify-content-center gap-1 align-items-center button'>
                    { loader && <Oval 
                            height={20} 
                            width={30} 
                            secondaryColor='rgb(245, 245, 245)' 
                            strokeWidth={5} 
                            color="white"
                        /> } Update
                </button>
            </Modal>
        </>
    )
}