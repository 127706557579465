import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api_url } from '../../Utils/api-urls';
import PageLoader from '../../Hooks/pageLoader';
export default function NormalBet(){
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const {id} = params;
    const authId = location.state;

    const[loader, setLoader] = useState(false);
    const[details, setDetails] = useState();

    const getNormalBetDetails = () =>{
        setLoader(true);
        axios.get(api_url.singleUserNormalBetInfo(id, authId?.authId),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setLoader(false); 
            setDetails(res?.data?.data);
        })
        .catch((err)=>{
            setLoader(false);
        })
    }

    console.log(details, 'thios is details')

    useEffect(()=>{
        getNormalBetDetails();
    },[])

    if(loader){
        return <div className='mt-5'>
            <PageLoader/>
        </div>
    }
    return <>
        this is normal betting page, got it !!
    </>
}