import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { api_url } from "../../Utils/api-urls";

export const getUsersDetails = createAsyncThunk('users/getUsersDetails', async ()=> {
    try{
        let response = await axios.get(api_url.users,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })

        let data = await response?.data?.data;

        return data;
    } catch(error){
        return error;
    }
})

const userSlice = createSlice({
    name: 'users',
    initialState: {
        items: [],
        status: 'idle',
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getUsersDetails?.pending, (state)=>{
            state.status = "pending";
        })
        .addCase(getUsersDetails?.fulfilled, (state, action)=>{
            state.status = "success";
            state.items = action.payload;  
        })
        .addCase(getUsersDetails?.rejected, (state, action)=>{
            state.status = "rejected";
            state.error = action.payload.message;
        })
    }
})

export const {setData, getData} = userSlice;
export default userSlice.reducer;