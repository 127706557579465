// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-action-modal > .modal-dialog > .modal-content{
    padding: 24px;
}

.modal-title{
    font-size: 20px;
    font-weight: 700;
    color: #333333;
}

.modal-subtitle{
    font-size: 13px;
    font-weight: 400;
    color: #A9A9A9;
}
.modal-close{
    background: #D22B2B1A;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

}`, "",{"version":3,"sources":["webpack://./src/Pages/Event/event.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB","sourcesContent":[".edit-action-modal > .modal-dialog > .modal-content{\n    padding: 24px;\n}\n\n.modal-title{\n    font-size: 20px;\n    font-weight: 700;\n    color: #333333;\n}\n\n.modal-subtitle{\n    font-size: 13px;\n    font-weight: 400;\n    color: #A9A9A9;\n}\n.modal-close{\n    background: #D22B2B1A;\n    height: 30px;\n    width: 30px;\n    border-radius: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
