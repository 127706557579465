
const environment = {
    development: {
        API_URL: process.env.REACT_APP_DEV_URL,
    },
    staging: {
        API_URL: process.env.REACT_APP_STAGE_URL
    },
    production: {
        API_URL: process.env.REACT_APP_PROD_URL
    }
}

const endpoint = environment[process.env.REACT_APP_API_ENVIRONMENT];
const betting= process.env.REACT_APP_BETTING_ENGINE;
const payment= process.env.REACT_APP_PAYMENT;

export const api_url = {
    login: `${endpoint?.API_URL}/signin/`,
    forgot: `${endpoint?.API_URL}/forgot-password/`,
   
    //event management
    event: `/event/`,
    getSingleEvent: (id)=>`/event/${id}/`,
    updateStatus: (id) => `/event/status/${id}/`,
    imageChanger: `${endpoint?.API_URL}/upload/image/`,
    template: `/template/`,
    singleTemplate: (id)=> `/template/${id}/`,
    optionBetList: (id) => `/bet/get-option-users/${id}/`,
    resultDeclare: `/event_result_declare/`,
    
    // fee and payout
    getCalcRate: `${process.env.REACT_APP_BETTING_ENGINE}/get_payout_calc_odd/`,
    updateCalcRate: `${process.env.REACT_APP_BETTING_ENGINE}/update_payout_calc_odd/`,
    getTieredShiftCap: `${process.env.REACT_APP_BETTING_ENGINE}/get_tiered_shift_cap/`,
    updateTieredShiftCap: `${process.env.REACT_APP_BETTING_ENGINE}/update_tiered_shift_cap/`,
    getFee: `${endpoint.API_URL}/fee/`,
    
    // user management
    users: `${endpoint?.API_URL}/users/user/`,  
    singleUsers: (id)=> `${endpoint?.API_URL}/users/user/${id}/`,
    suspendUser: (id)=> `${endpoint?.API_URL}/users/suspend/${id}/`,    
    unSuspendUser: (id)=> `${endpoint?.API_URL}/users/unsuspend/${id}/`,
    getPreRegister: `${endpoint?.API_URL}/users/pre-register/`,
    singleUserTxn: (id) => `${payment}txn_history/${id}`,
    singleUserWallet: (id) => `${payment}wallet_history/${id}`,
    singleUserParley: (status, auth_id) => `${betting}/get_parley_bets_for_admin/${status}/${auth_id}/`,
    singleUserParleyInfo: (bet_id, auth_id) => `${betting}/get_parley_bet_byId_for_admin/${bet_id}/${auth_id}/`,
    singleUserNormalBet: (bet_id, auth_id) => `${betting}/get_bets_for_admin/${bet_id}/${auth_id}/`,
    singleUserNormalBetInfo: (bet_id, auth_id) => `${betting}/get_bet_byId_for_admin/${bet_id}/${auth_id}/`,
    getUserRefered: (id) => `/referral/getReferredUserByUserId/${id}/`,
    getUserAffiliated: (id) => `/affiliate/get-referred-user-of-Affiliate/${id}/`,
    
    // odds management
    setOds: `/odds/set-odd/`,         
    getOptionHistory: (id)=> `/odds/odd/option/${id}/`,
    
    // cms
    uploadBanner: `${endpoint.API_URL}/banner/save-banner/`,
    getBanner: `${endpoint.API_URL}/banner/get-banner/`,
    deleteBanner: (id) => `${endpoint?.API_URL}/banner/delete-banner/${id}/`,
    category: `${endpoint.API_URL}/category/`,
    specificCategory: (id)=> `${endpoint.API_URL}/category/${id}/`,

    //pools&liquidity
    getPool: `${endpoint.API_URL}/pool/get/`,
    addPool: `${endpoint.API_URL}/pool/add-amount/`,
    pool_history: `${payment}pool_history/`,

    // coupon management
    createCoupen: `${endpoint.API_URL}/coupon/create/`,
    getCoupen: `${endpoint.API_URL}/coupon/get-all/`,
    disableCoupen: (id) => `${endpoint.API_URL}/coupon/disable/${id}/`,

    // settings
    changePassword: `${endpoint.API_URL}/change-password/`,

    // payments
    getPayChannel: "/get_payment_channels_for_admin/",
    updatePayChannel: "/update_payment_channels/",
    withdrawalList: (status) => `/get_user_disbursement/${status}/`,
    withdrawalStatus: (id) => `/admin_disbursement/${id}/`,

    // affiliate
    create_req: "/affiliate/create-affiliate/",
    getAllAffilates: (page) => `/affiliate/get-all-affiliate-user/?page=${page}&per_page=10`,
    req_list: (status) => `/affiliate/getAllRequest/?status=${status}`,
    update_list: (status, req_id, desc) => `/affiliate/updateAffiliateRequest/${req_id}/?status=${status}${desc && `&description=${desc}`}`,

}