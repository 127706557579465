import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, InputContainer, Label } from '../Auth/styled';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { CancelButton, DeleteButton1 } from '../Event/style';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';

export default function ChangePassword(){
    const[canSeeCPass, setCanSeeCPass]= useState(true);
    const[canSeePass, setCanSeePass]= useState(true);
    const[loader, setLoader] = useState(false);

    const formik = useFormik({
        initialValues: {
            password: "",
            cpassword: ""
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Password is required")
            .min(8,'must be of 8 letters').matches(/[a-zA-Z]@[0-9]/,"Password must have alphanumeric and symbol (@)"),
            cpassword: Yup.string().required("Confirm password is required")
        }),
        onSubmit: (values)=>{
            setLoader(true);
            axios.patch(api_url.changePassword,{
                password: values.password
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{
                toast.success(res?.data?.message ? res?.data?.message : 'Password change successfully.');
                setLoader(false);
            })
            .catch((err)=>{
                toast.error(err?.reponse?.data?.message ? err?.reponse?.data?.message : "Some network issue");
                setLoader(false);
            })
        }
    })
    
    return(
        <>
            <p className='fs-5 fw-bold mb-0'>Change Password</p>
            <small className='text-secondary'>You can change your password</small>

            <form className='mt-3' onSubmit={formik.handleSubmit}>
                <div className='mb-3'>
                    <Label htmlFor='password'>Password</Label>
                    <div>
                        <InputContainer>
                            <Input
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                id='password'
                                name='password'
                                placeholder='Enter password'
                                type={canSeePass ? 'password' : 'text'}
                            />
                            <span 
                                onClick={()=>{
                                    setCanSeePass(!canSeePass)
                                }}>
                                { canSeePass ? <FiEye color='#A9A9A9'/> : <FiEyeOff color='#A9A9A9'/> }
                            </span>
                        </InputContainer>
                    </div>
                    { formik.touched.password && <p className='validation-error'>{ formik.errors.password && formik.errors.password } </p>}
                </div>

                <div className='mb-3'>
                    <Label htmlFor='cpassword'>Confirm Password</Label>
                    <div>
                        <InputContainer>
                            <Input
                                value={formik.values.cpassword}
                                onChange={formik.handleChange}
                                id='cpassword'
                                name='cpassword'
                                placeholder='Enter password again'
                                type={canSeeCPass ? 'password' : 'text'}
                            />
                            <span 
                                onClick={()=>{
                                    setCanSeeCPass(!canSeeCPass)
                                }}>
                                { canSeeCPass ? <FiEye color='#A9A9A9'/> : <FiEyeOff color='#A9A9A9'/> }
                            </span>
                        </InputContainer>
                    </div>
                    { formik.touched.cpassword && <p className='validation-error'>{ formik.errors.cpassword && formik.errors.cpassword } </p>}
                </div>

                <div className='mb-3 d-flex align-items-center gap-1' style={{maxWidth: "50%"}}>
                    <CancelButton 
                        type='button' 
                        onClick={()=>{
                            formik.setValues({
                                password: "",
                                cpassword: ""
                            })
                        }} >
                        Cancel
                    </CancelButton>
                    <DeleteButton1 disabled={loader} type='submit'>{loader ? <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/> : "Submit"}</DeleteButton1>
                </div>  
            </form>
        </>
    );
}