// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content{
    padding: 10px 20px;
    width: 90% !important;
}
.cross-button{
    background: #D22B2B1A;
    border: none;
    height: 25px;
    width: 24px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: #D22B2B;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserManagement/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".modal-content{\n    padding: 10px 20px;\n    width: 90% !important;\n}\n.cross-button{\n    background: #D22B2B1A;\n    border: none;\n    height: 25px;\n    width: 24px;\n    display: flex;\n    border-radius: 50px;\n    justify-content: center;\n    align-items: center;\n    color: #D22B2B;\n    font-size: 13px;\n    font-weight: 600;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
