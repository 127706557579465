import React from 'react'
import { Card } from './style';
import AdsImage from '../../images/adsmgmt.png'
import Ads from './ads';
import Category from './category';
// import PreRegister from './PreRegister';

export default function ContentMgmt(){

    return(
        <p className='p-3'>
            <Card className='row m-0'>
                <p className='heading'>CMS</p>
                <div className='col-md-3' style={{borderRight: "2px solid #F5F5F5"}}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button 
                                className="nav-link active" 
                                id="nav-bet-tab" 
                                data-bs-toggle="tab" 
                                data-bs-target="#nav-bet" 
                                type="button" role="tab"
                                aria-controls="nav-bet"
                                aria-selected="true"
                            >
                                Ads Mangement
                            </button>
                            <button
                                className="nav-link"
                                id="nav-transaction-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-transaction"
                                type="button" role="tab" 
                                aria-controls="nav-transaction" 
                                aria-selected="false"
                            >
                                Categories
                            </button>
                        </div>
                    </nav>
                </div>
                <div className='col-md-9'>
                    <div className="tab-content" id="nav-tabContent">
                        <div 
                            className="tab-pane fade show active"  
                            id="nav-bet" 
                            role="tabpanel" 
                            aria-labelledby="nav-bet-tab"
                        > <Ads/> </div>
                        <div 
                            className="tab-pane fade show" 
                            id="nav-transaction" 
                            role="tabpanel" 
                            aria-labelledby="nav-transaction-tab"
                        > <Category/> </div>
                    </div>
                </div>
            </Card>
        </p>
    );
}