import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api_url } from "../../Utils/api-urls";

export const getCategories = createAsyncThunk("category/getCategoryList", async ()=> {
    try{
        let response = await axios.get(api_url.category,{
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`
            }
        })

        let data = await response?.data?.data;

        return data;
    } catch(error) { 
        return error;
    }
})

const cmsSlice = createSlice({
    name: "category",
    initialState: {
        items: [],
        status: "idle",
        error: null
    },
    reducer: {},
    extraReducers: (builder)=> {
        builder
        .addCase(getCategories.pending,(state)=>{
            state.status = "pending";
        })
        .addCase(getCategories.rejected, (state, action)=>{
            state.status = "reject";
            state.error = action.error.message;
        })
        .addCase(getCategories.fulfilled, (state, action)=>{
            state.status = "approve";
            state.items = action.payload;
        })
    }
})

export const {setData, getData} = cmsSlice;
export default cmsSlice.reducer;