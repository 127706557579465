import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import { DesclineButton, WithdrawalDiv } from './style';
import { customStyles } from '../UserManagement/SingleUser';
import payment from '../../Service/payment';
import moment from 'moment'
import { api_url } from '../../Utils/api-urls';
import { Modal } from 'react-bootstrap';
import { CancelButton, DeleteButton1, Label, Subhead, Textarea } from '../Event/style';
import { toast } from 'react-toastify';

export default function Withdrawal(){
    
    const [withdrawalData, setWithdrawalData] = useState();
    const [status, setStatus] = useState('all');
    const [form, setForm] = useState({
        id: "",
        status: "",
        failed_reason: "default"
    })
    const [openModal, setOpenModal] = useState({
        approve: false,
        reject: false
    })
    const handleApproveClick =(id)=>{
        setOpenModal({
            approve: true,
            reject: false
        })
        setForm({
            id: id,
            status: "approve",
            failed_reason: "default"
        })
    }
    const handleRejectClick =(id)=>{
        setOpenModal({
            approve: false,
            reject: true
        })
        setForm({
            id: id,
            status: "reject",
            failed_reason: ""
        })
    }
    const handleClose = ()=>{
        setOpenModal({
            approve: false,
            reject: false
        })
    }
    const handleAnnounceResult = async()=>{
        try{
            let response = await payment.post(api_url.withdrawalStatus(form?.id), {
                status: form?.status,
                failed_reason: form?.failed_reason,
                _id: form?.id
            });
            toast.success(response?.data?.data?.msg ? response?.data?.data?.msg : "Status change successfully")
            handleClose();
            handleGetWithdrawlList();
        } catch(error){
            handleClose();
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured")
            handleGetWithdrawlList();
        }
    }

    const handleGetWithdrawlList = async () => {
        try{
            let response = await payment.get(api_url.withdrawalList(status));
            setWithdrawalData(response?.data?.data);
        } catch(error){
        }
    }

    useEffect(()=>{
        handleGetWithdrawlList()
    },[])

    const columns = [
        {         
            name: "Account Name",
            selector: (row)=> <div>
                {row?.accountName}
            </div>
        },
        {
            name: "Account Number",
            selector: (row)=> <div>
                {row?.accountNumber}
            </div>
        },
        {
            name: "Amount",
            selector: (row)=> <div>
                ₱{row?.amount}
            </div>
        },
        {
            name: "Created on", 
            selector: (row)=> <div>
                {moment(row?.createdAt).format("DD-MM-YYYY")}
            </div>
        },
        {
            name: "Status",
            selector: (row)=> <div>
                {row?.status}
            </div>
        },
        {
            name: "Reason",
            selector: (row)=> <div>
                {row?.failed_reason ? row?.failed_reason : "N/A"}
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div className='d-flex gap-2'>
                <DesclineButton onClick={()=>handleApproveClick(row?._id)} background="#1C7947" >Approve</DesclineButton>
                <DesclineButton onClick={()=>handleRejectClick(row?._id)} background="#D22B2B" >Decline</DesclineButton>
            </div>
        }
    ];

    return(
        <>
            <div className='d-flex mt-4 mb-2 justify-content-between align-items-center'>
                <div>
                    <p className='mb-0 fw-bold fs-5'>Withdrawal Requests</p>
                    <small className=' text-secondary'>Adjust a user's withdrawal requests</small>
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <div className="dropdown ">
                        <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                        <FiDownloadCloud size={16} color='#333333'/> Export
                        </button>
                        <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <li 
                            // onClick={handleDownloadinCSV} 
                            className="dropdown-item">CSV <LuDownload/> </li>
                            <li 
                            // onClick={handleDownloadinEXL} 
                            className="dropdown-item">EXCEL <LuDownload/></li>
                        </ul>
                    </div>
                </div>
            </div>
            <WithdrawalDiv>
                <DataTable
                    columns={columns}
                    data={withdrawalData}
                    customStyles={customStyles}
                />
            </WithdrawalDiv>

            <Modal show={openModal?.approve || openModal?.reject }>
                <div className='d-flex justify-content-between'>
                    <div className='modal-head'>
                        <p className="heading mb-0">Withdrawal Request</p>
                        <p className='sub-heading'>manually accept/reject the withdrawal request</p>
                    </div>
                    <p onClick={handleClose} className='cross-button'>x</p>
                </div>
                <div>
                    <Subhead className='text-center'>Once this withdrawal request approved, the amount is withdrawal from account.</Subhead>
                    {openModal?.reject && <div>
                        <Label>Reason</Label>
                        <Textarea
                            placeholder='enter reason, why you reject the request'
                            value={form?.failed_reason}
                            onChange={(e)=>{
                                setForm({
                                    ...form,
                                    failed_reason: e.target.value
                                })
                            }}
                        />
                    </div>}
                    <div className='d-flex align-items-center gap-2 justify-content-center'>     
                        <CancelButton onClick={handleClose}>Cancel</CancelButton>
                        <DeleteButton1 onClick={()=>handleAnnounceResult()}>{openModal?.approve ? "Approve" : "Reject"}</DeleteButton1>
                    </div> 
                </div>
            </Modal>
        </>
    );
}