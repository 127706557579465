import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import DataTable from 'react-data-table-component';
import { ViewButton } from '../Event/style';
import { Tired } from './style';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../Odds/style.css'
import { Oval } from 'react-loader-spinner';
import PageLoader from '../../Hooks/pageLoader';
export default function Tiered({customStyles}){
                              
    const [edit, setEdit] = useState(false);
    const handleShowUpdate = (data)=>{
        setEdit(true);
        formik.setValues({
            tier: data?.tiered,
            min_pool: data?.min_pool,
            max_pool: data?.max_pool,
            perc: data?.perc,
            per_perc: data?.per_perc
        });
    }
    const handleCloseUpdate = ()=>{
        setEdit(false);
    }

    const [loader, setLoader] = useState(false);

    const [tieredList, setTieredList] = useState()
    const handleGetTiered = ()=>{
        axios.get(api_url.getTieredShiftCap,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })
        .then((res)=>{
            setTieredList(res?.data?.data);
        })
        .catch((err)=>{
        })
    }

    const formik = useFormik({
        initialValues: {
            tier: "",
            min_pool: "",
            max_pool: "",
            perc: "",
            per_perc: ""
        },
        validationSchema: Yup.object().shape({
            tier: Yup.string().required("Tier is required"),
            min_pool: Yup.string().required("Min Pool is required"),
            max_pool: Yup.string().required("Max Pool is required"),
            perc: Yup.string().required("Percentage is required"),
            per_perc: Yup.string().required("Per percentage is required")
        }),
        onSubmit: (values) => {
            setLoader(true);
            axios.patch(api_url.updateTieredShiftCap, {
                ...values,
                tier: values.tier
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: "application/json"
                }
            })
            .then((res)=>{ 
                setLoader(false);
                handleCloseUpdate()
                handleGetTiered()
            })
            .catch((err)=>{ 
                setLoader(false);
            })
        }
    })

    const columns1 = [
        {
            name: "Tiered",
            selector: (row)=> <div>
                {row?.tiered}
            </div>
        },
        {
            name: "Min Pool",
            selector: (row)=> <div>
                {row?.min_pool}
            </div>
        },
        {
            name: "Max Pool",
            selector: (row)=> <div>
                {row?.max_pool}
            </div>
        },
        {
            name: "Percentage(%)",
            selector: (row)=> <div>
                {row?.perc} %
            </div>
        },
        {
            name: "Per Percentage(%)",
            selector: (row)=> <div>
                {row?.per_perc} %
            </div>
        },
        {
            name: "Action",
            selector: (row)=> <div>
                <ViewButton onClick={()=>handleShowUpdate(row)}>
                    Update
                </ViewButton>
            </div>
        }
    ]
    
    useEffect(()=>{
        handleGetTiered();
    },[])
    return(
        <>
             <div className='table-header mb-3 px-3'>
                <div>
                    <p className='title mb-0'>Tiered Shift Cap</p>
                    <small className='subtitle'>Manage and track the tiered shift cap</small>
                </div>
            </div>
            <Tired>
                { tieredList?.length > 0 ? <DataTable
                    data={tieredList}
                    columns={columns1}
                    customStyles={customStyles}
                /> : <PageLoader/>}
            </Tired>

            <Modal show={edit}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='modal-head'> 
                        <p className="heading mb-0">Update Tier {formik.values.tier}</p>
                        <p className='sub-heading'>update the tiered-shift cap</p>
                    </div>
                    <p onClick={handleCloseUpdate} className='cross-button'>x</p>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='inputcontainer'>
                        <label htmlFor='tier'>Tier</label>
                        <input 
                            disabled
                            placeholder='Enter tier'
                            value={formik.values.tier}
                            onChange={formik.handleChange}
                            name='tier'
                            id='tier'
                        />
                    </div>
                    <div className='inputcontainer'>
                        <label htmlFor='min_pool'>Min Pool</label>
                        <input
                            placeholder='Enter minimum pool value'
                            value={formik.values.min_pool}
                            onChange={formik.handleChange}
                            name='min_pool'
                            id='min_pool'
                        />
                    </div>
                    <div className='inputcontainer'>
                        <label htmlFor='max_pool'>Max Pool</label>
                        <input
                            placeholder='Enter maximum pool value'
                            value={formik.values.max_pool}
                            onChange={formik.handleChange}
                            name='max_pool'
                            id='max_pool'
                        />
                    </div>
                    <div className='inputcontainer'>
                        <label htmlFor='perc'>Percentage (%)</label>
                        <input
                            placeholder='Enter percentage'
                            value={formik.values.perc}
                            onChange={formik.handleChange}
                            name='perc'
                            id='perc'
                        />
                    </div>
                    <div className='inputcontainer'>
                        <label htmlFor='per_perc'>Per percentage (%)</label>
                        <input
                            placeholder='Enter per percentage'
                            value={formik.values.per_perc}
                            onChange={formik.handleChange}
                            name='per_perc'
                            id='per_perc'
                        />
                    </div>
                    <button type='submit' disabled={loader} className='d-flex justify-content-center gap-1 align-items-center button'>{loader && <Oval height={20} width={30} secondaryColor='rgb(245, 245, 245)' strokeWidth={5} color="white"/>} Update</button>
                </form>
            </Modal>
        </>
    );
}