import TotalEvent from '../images/totalEvent.png'
import TotalBet from '../images/totalBet.png'
import ViewImg from '../images/eye-view.png'
import EditImg from '../images/pencil-edit.png'
import DeleteImg from '../images/bin-delete.png'
import Delete from '../images/delete.png'
import UploadImage from '../images/uploadImg.png'
import EndTime from '../images/endTime.png'
import StartTime from '../images/startTime.png'
import CloseTime from '../images/cancelTime.png'
import ResolutionTime from '../images/resolutionTime.png'
import Time from '../images/time.png'
import Star from '../images/star.png'
import Comment from '../images/comment.png'

import TotalUser from '../images/totalUser.png'
import ActiveUser from '../images/activeUser.png'
import InactiveUser from '../images/inactiveUser.png'
import BannedUser from '../images/bannedUser.png'
import SuspendImg from '../images/suspend_user.png'
import UnsuspendImg from '../images/un_suspend.png'
import Sample from '../images/headerImage.png'
import TotalBetImg from '../images/total_bets.png';
import TotalWonImg from '../images/total_won.png';
import TotalLoseImg from '../images/total_loose.png';
import Unsuspend from '../images/unsuspend_icon.png'

export const image = {
    event: {
        TotalEvent,
        TotalBet,
        ViewImg,
        EditImg,
        DeleteImg,
        Delete,
        UploadImage,
        EndTime,
        StartTime,
        CloseTime,
        ResolutionTime,
        Time,
        Star,
        Comment
    },
    user: {
        TotalUser,
        ActiveUser,
        InactiveUser,
        BannedUser,
        SuspendImg,
        UnsuspendImg,
        Sample,
        TotalBetImg,
        TotalWonImg,
        TotalLoseImg,
        Unsuspend,
    }
}