import * as XLSX from 'xlsx';

export const handleDownloadDataInCsv = (data)=>{
    const worksheet = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "event.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const handleDownloadDataInExel = (data) =>{
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "event.xlsx");
}