import styled from "styled-components";

export const Card = styled.div`
    background: white;
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 15px;

    .add-button{
        background: #D22B2B;
        border: none;
        color: white;
        border-radius: 10px;
        padding: 5px 8px;
        font-size: 12px;
    }

    .title{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 19.5px;
    }

    .active-line{
        font-size: 14px;
        font-weight: 600;
        color: #A9A9A9;
    }
`