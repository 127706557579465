import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { api_url } from '../../Utils/api-urls';
import DataTable from 'react-data-table-component';
import { CiSearch } from 'react-icons/ci';
import { FiDownloadCloud } from 'react-icons/fi';
import { LuDownload } from 'react-icons/lu';
import * as XLSX from 'xlsx'
import PageLoader from '../../Hooks/pageLoader';

const customStyles = {
    headRow: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: "#333333d4",
        border: "1px solid #EAECF0",
        background:'#fcfcfd',
        minHeight: '0px !important'
      }
    },
    rows: {
      style: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: "500",
        minHeight: '0px !important',
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    headCells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
      },
    },
    cells: {
      style: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: 'fit-content',
      },
    }
}

export default function PreRegister(){
  const [preRegisterList, setPreRegisterList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const getPreRegistered=()=>{
      axios.get(api_url.getPreRegister,{
          headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              Accept: 'application/json'
          }
      })
      .then((res)=>{
          setPreRegisterList(res?.data?.data)
      })
      .catch((err)=>{
      })
  }
    
  const handleDownloadinEXL = ()=>{
    const data = preRegisterList.map((data, index)=>{
      return {
        s_no: index+1,
        firstName: data?.firstName,
        mobile: data?.mobile
      }
    })

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "preRegister.xlsx");
  }

  const handleDownloadinCSV = ()=>{
    const data = preRegisterList.map((data, index)=>{
      return {
        s_no: index+1,
        firstName: data?.firstName,
        mobile: data?.mobile
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "preRegister.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }     

  const searchfilter = preRegisterList?.length > 0 ? preRegisterList?.filter((data)=>{
    return data?.firstName?.toLowerCase().includes(searchInput?.toLowerCase()) || data?.mobile?.toLowerCase().includes(searchInput?.toLowerCase())
  }) : [];

  useEffect(()=>{
    getPreRegistered()
  },[])

  const columns = [
    {
      name: "Name",
      selector: (row)=> <div>
        {row?.firstName}
      </div>
    },
    {
      name: "Mobile Number",
      selector: (row)=> <div>
        {row?.mobile}
      </div>
    }
  ];
    
    return(
      <>
        <div className='table-header mb-3 px-3'>
          <div>
            <p className='title mb-0'>Pre-registered Users</p>
            <small className='subtitle'>Manage and track all users who pre-register on akala-ko88</small>
          </div>
          <div className='filter-container'>
            <div className='input-container'>
              <CiSearch strokeWidth={2} color='rgba(102, 102, 102, 1)'/>
              <input 
                  value={searchInput}
                  onChange={(e)=>{ setSearchInput(e.target.value) }}
                  placeholder='Search..'
              />
            </div>
            <div className="dropdown">
              <button className="dropdown-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                <FiDownloadCloud size={16} color='#333333'/> Export
              </button>
              <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <li onClick={handleDownloadinCSV} className="dropdown-item">CSV <LuDownload/> </li>
                <li onClick={handleDownloadinEXL} className="dropdown-item">EXCEL <LuDownload/></li>
              </ul>
            </div>
          </div>
        </div>
        {searchfilter?.length > 0 ? <DataTable
          columns={columns}
          data={searchfilter}
          customStyles={customStyles}
          pagination
        /> : <PageLoader/>}
      </>
    );
}